import styled from 'styled-components';

export const HeaderRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 20px;  
  button{
    border-radius: 12px;
    margin-left: auto;
    align-self: center;
  }
`;

export const ContainerTables = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  > div {
    padding: 15px;
    border: 1px solid #efefef;
    border-radius: 20px;
    margin-top: 0px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    width: 45%;
    transition: transform .2s;
  }
  > div:hover {
    transform: scale(1.1)
  }
`;

export const ContainerAllTable = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  > div {
    padding: 15px;
    border: 1px solid #efefef;
    border-radius: 20px;
    margin-top: 15px;
    margin-right: 30px;
    margin-bottom: 30px;
    margin-left: 0px;
    width: 100%;
    transition: transform .2s;
  }
`;

