import { AxiosError } from 'axios';
import queryString from 'query-string';
import axios from '../../providers/api';

export interface IBSCategoryResponse {
  id: string,
  name: string,
  createAt: Date | null,
  department: {
    id: string,
    name: string,
    createAt: Date | null,
  },
  activities: Array<{
    id: string,
    name: string,
    createAt: Date | null,
    sla: {
      id: string,
      toAccept: number,
      toStart: number,
      toEnd: number
    }
  }>;
}

interface IAxiosGetAllCategories {
  status?: number,
  data: IBSCategoryResponse[] | string,
}

export const getAllCategories = async (): Promise<IAxiosGetAllCategories> => {
  try {
    const axiosResponse = await axios().get('/category/');
    const bsCategoryData: IBSCategoryResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsCategoryData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
      data: error.response?.data,
    };
  }
};

export const getCategoriesByPermission = async (ids: number[]): Promise<IAxiosResponse<
| IBSCategoryResponse[]
>> => {
  try {
    const params = queryString.stringify({ permissions: true, ids });
    const axiosResponse = await axios().get(`/category/?${params}`);
    const bsCategoryData: IBSCategoryResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsCategoryData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

type IBody = {
  [key: string]: string | Date;
};

interface IAxiosPatch {
  status?: number,
}

export const editCategoryPatch = async (
  categoryId: string,
  body: IBody,
): Promise<IAxiosPatch> => {
  try {
    const axiosResponse = await axios().patch(`/category/${categoryId}`, body);
    return {
      status: axiosResponse.status,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const deleteCategoryPatch = async (
  categoryId: string,
): Promise<IAxiosPatch> => {
  try {
    const axiosResponse = await axios().delete(`/category/${categoryId}`);
    return {
      status: axiosResponse.status,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

// TODO: Make "catch(BsErr)" global to avoid constantly copy/paste
