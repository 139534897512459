import { IBSServiceResponse } from '../../components/ServicesTable/components/api';
import axios from '../../providers/api';
import queryString from 'query-string';
import { AxiosError } from 'axios';
import { ILogFilters } from '../BSLog/components/api';
import { IUsersResponse } from '../UserManager/api';

export interface IBSDepartmentResponse {
  id: string,
  name: string,
  createAt: Date,
  company: {
    id: string,
    name: string,
  },
  color: string,
  ordenation: number,
}

interface IAxiosSendLogRequest {
  status?: number,
  data: IBSServiceResponse[] | string,
}

export const sendDashboardRequest = async (logValues: ILogFilters)
: Promise<IAxiosSendLogRequest> => {
  try {
    const params = queryString.stringify({ ...logValues, order: 'DESC' });
    const axiosResponse = await axios().get(`bsservice/?${params}`);
    const bsServiceData: IBSServiceResponse[] | string = axiosResponse.data;

    return {
      status: axiosResponse.status,
      data: bsServiceData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
      data: error.response?.data,
    };
  }
};

const getDepartment = async (): Promise<IAxiosResponse<IBSDepartmentResponse[]>> => {
  const axiosResponse = await axios().get('/department');
  const bsCategoryData: IBSDepartmentResponse[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsCategoryData,
  };
};

export const getDepartmentByUser = async (): Promise<IAxiosResponse<IBSDepartmentResponse[]>> => {
  const axiosResponse = await axios().get('/department/byUser');
  const bsCategoryData: IBSDepartmentResponse[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsCategoryData,
  };
};

interface IAxiosGetServices {
  serverTime?: string;
  status?: number,
  data: string | IBSServiceResponse[],
}

export const getManagingPendingServices = async (): Promise<IAxiosGetServices> => {
  const params = queryString.stringify({ order: 'DESC' });
  const axiosResponse = await axios().get(`/bsservice/managingpending/?${params}`);
  const bsServiceData: IBSServiceResponse[] | string = axiosResponse.data;
  const serverTime: string = axiosResponse.headers.date;
  return {
    serverTime,
    status: axiosResponse.status,
    data: bsServiceData,
  };
};

export const getService = async (activityId:string) : Promise<IBSServiceResponse> => {
  const axiosResponse = await axios().get(`/bsservice/find/${activityId}`);
  const bsServiceData: IBSServiceResponse = axiosResponse.data;
  return bsServiceData;
};

export default getDepartment;
