const responseStatus = (res?: number): 'INFORMATION' | 'SUCCESS' | 'REDIRECT' | 'CLIENTERROR' | 'SERVERERROR' | 'UNKNOWN' | 'SERVICEINPROGRESS' | undefined => {
  if (res) {
    if (res <= 199) return 'INFORMATION';
    if (res <= 299) return 'SUCCESS';
    if (res <= 399) return 'REDIRECT';
    if (res <= 499) return 'CLIENTERROR';
    if (res <= 599) return 'SERVERERROR';
    if (res <= 699) return 'SERVICEINPROGRESS';
  }
  return 'UNKNOWN';
};

export default responseStatus;
