import { Divider, Tabs } from "antd";
import React from "react";
import { HeaderRow } from "../DashBoardAgent/styles";
import UserManager from "../UserManager";
import DepartmentsManager from "../DepartmentsManager";
import AdminBS from "../AdminBS";

const { TabPane } = Tabs;

const ManagerPanel: React.FC = () => {

    return (
        <>
            <HeaderRow>
                <h1>Gerenciamento</h1>
            </HeaderRow>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Departamentos e Serviços" key="1">
                    <DepartmentsManager />
                    <Divider />
                    <AdminBS />
                </TabPane>
                <TabPane tab="Usuários" key="2">
                    <UserManager />
                </TabPane>
            </Tabs>
        </>
    )
};

export default ManagerPanel;
