/* eslint-disable max-len */
import React, {
  createContext, useContext, useState,
} from 'react';
import { IBSServiceResponse } from '../components/ServicesTable/components/api';
import { IBSDepartmentResponse } from '../pages/DashBoardAgent/api';
import { ICompanyResponse } from '../pages/BSLog/components/api';
import { IRoles } from '../providers/auth';

type PropsContext = {
  allDepartments: IBSDepartmentResponse[],
  setallDepartments: React.Dispatch<React.SetStateAction<IBSDepartmentResponse[]>>,
  allCompanies: ICompanyResponse[],
  setallCompanies: React.Dispatch<React.SetStateAction<ICompanyResponse[]>>,
  onGoing: IBSServiceResponse[],
  setonGoing: React.Dispatch<React.SetStateAction<IBSServiceResponse[]>>,
  managingPending: IBSServiceResponse[],
  setmanagingPending: React.Dispatch<React.SetStateAction<IBSServiceResponse[]>>,
  loginRoles: IRoles[],
  setloginRoles: React.Dispatch<React.SetStateAction<IRoles[]>>
};

const DEFAULT_VALUE = {
  allDepartments: [],
  setallDepartments: () => { },
  allCompanies: [],
  setallCompanies: () => { },
  onGoing: [],
  setonGoing: () => { },
  managingPending: [],
  setmanagingPending: () => { },
  loginRoles: [],
  setloginRoles: () => { },
};

const GeneralContext = createContext<PropsContext>(DEFAULT_VALUE);

const GeneralProvider: React.FC = ({ children }) => {
  const [allDepartments, setallDepartments] = useState<IBSDepartmentResponse[]>([]);
  const [allCompanies, setallCompanies] = useState<ICompanyResponse[]>([]);
  const [onGoing, setonGoing] = useState<IBSServiceResponse[]>([]);
  const [managingPending, setmanagingPending] = useState<IBSServiceResponse[]>([]);
  const [loginRoles, setloginRoles] = useState<IRoles[]>([]);

  return (
    <GeneralContext.Provider
      value={{
        allDepartments,
        setallDepartments,
        allCompanies,
        setallCompanies,
        onGoing,
        setonGoing,
        managingPending,
        setmanagingPending,
        loginRoles,
        setloginRoles,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};

/**
 * Global state to backup and give an initial state to all departments, companies and onGoing/managingPending services.
 * @global allDepartments / setallDepartments,
 * @global allCompanies / setallCompanies,
 * @global onGoing / setonGoing,
 * @global managingPending / setmanagingPending,
 */
export function useGeneralData(): PropsContext {
  const context = useContext(GeneralContext);
  if (!context) throw new Error('useGeneralData must be used within a GeneralProvider');
  const {
    allDepartments,
    setallDepartments,
    allCompanies,
    setallCompanies,
    onGoing,
    setonGoing,
    managingPending,
    setmanagingPending,
    loginRoles,
    setloginRoles,
  } = context;

  return {
    allDepartments,
    setallDepartments,
    allCompanies,
    setallCompanies,
    onGoing,
    setonGoing,
    managingPending,
    setmanagingPending,
    loginRoles,
    setloginRoles,
  };
}

export default GeneralContext;
export { GeneralProvider };
