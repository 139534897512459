/* eslint-disable max-len */
import React, {
  useEffect, useState,
} from 'react';
import {
  Form, Card, Row, Col, Button, DatePicker, Select, Input,
} from 'antd';
import moment, { Moment, now } from 'moment';
import { CSVLink } from 'react-csv';
import {
  DownloadOutlined, PlusOutlined, ReloadOutlined, SearchOutlined,
} from '@ant-design/icons';
import { AxiosError } from 'axios';
import FilterModal from './components/filterModal';
import Container from './styles';
import { IBSDepartmentResponse } from '../DashBoardAgent/api';
import {
  sendLogRequest, ILogFilters, getAllActivities, ICompanyResponse,
  getAllCompanies, getActivities, getCategories, IBSCategoryResponse,
  getDepartments, getAllCategories, filterActivity,
} from './components/api';
import { APINotificationSwitch, NotificationError } from '../../components/NotificationBS';

import { IBSServiceResponse } from '../../components/ServicesTable/components/api';
import {
  checkStatusUnauthorized, getAuthDepartments, getAuthRoles, IDepartment,
} from '../../providers/auth';
import { IBSActivityResponse } from '../RequestForm/api';
import responseStatus from '../../providers/responseStatus';
import { brasiliaAgentPermission, brasiliaManagerPermission, permissionChecker } from '../../providers/rolePermissions';
import ReportServicesTable from '../../components/ServicesTable/components/ReportServicesTable';
/**
* TODO: Conformidade na meta
*/

const { RangePicker } = DatePicker;

type Range<T> = [T, T];

const BSLog: React.FC = () => {
  const [form] = Form.useForm();
  const [allActivities, setAllActivities] = useState<IBSActivityResponse[]>([]);
  const [allCategories, setAllCategories] = useState<IBSCategoryResponse[]>([]);
  const [allCompanies, setallCompanies] = useState<ICompanyResponse[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [serviceData, setServiceData] = useState<IBSServiceResponse[]>([]);
  const [fetchingResponse, setFetchingResponse] = useState<boolean>(false);

  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const serviceRatingOptions = [
    { id: 1, value: 10, label: 'Satisfeito' },
    { id: 2, value: 5, label: 'Indiferente' },
    { id: 3, value: 1, label: 'Insatisfeito' },
  ];

  // const FilterModalRef = useRef<FilterModalHandles>(null);
  //   const FilterModal = (
  //     render(){
  //       this.state = {
  //         show: false
  //       };
  //   this.showModal = this.showModal.bind(this);
  //   this.hideModal = this.hideModal.bind(this);
  // }

  // showModal = () => {
  //   this.setState({ show: true });
  // };

  // hideModal = () => {
  //   this.setState({ show: false });

  //   )

  const filterActivities = async (category: string) => {
    try {
      const response = await getActivities(category);
      setAllActivities(response.data as IBSActivityResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };
  const filterCategories = async (department: string) => {
    try {
      const response = await getCategories(department);
      setAllCategories(response.data as IBSCategoryResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };
  const filterDepartments = async (company: string) => {
    try {
      const result = await getDepartments(company);
      setDepartments(result.data as IBSDepartmentResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillActivities = async (str: string[]) => {
    // setloadingActivities(true);
    try {
      const result = await getAllActivities();
      const activityResponse = filterActivity(str, result.data as IBSActivityResponse[]);
      setAllActivities(await activityResponse);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillCategories = async () => {
    // setloadingActivities(true);
    try {
      const result = await getAllCategories();
      setAllCategories(result.data as IBSCategoryResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillCompanies = async () => {
    try {
      const result = await getAllCompanies();
      setallCompanies(result.data as ICompanyResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  useEffect(() => {
    try {
      const departaments = getAuthDepartments();
      setDepartments(departaments);
      fillCompanies();
      fillCategories();
    } catch (err) {
      APINotificationSwitch('SERVERERROR', 'Erro ao resgatar departamentos, entre novamente no sistema');
      console.error(err);
    }
  }, []);

  const onRequest = async (values: ILogFilters) => {
    setFetchingResponse(true);
    const requestTimestampRangePicker = values.requestTimestamp as Range<Moment>;

    let fieldValues = values;
    if (requestTimestampRangePicker) {
      const diffCheck = requestTimestampRangePicker[1].diff(requestTimestampRangePicker[0]);
      if (diffCheck > 31 * 24 * 60 * 60 * 1000) {
        NotificationError('Atenção', 'Utilize períodos menores que 31 dias, por favor.');
        setFetchingResponse(false);
        return;
      }

      fieldValues = {
        ...values,
        requestTimestamp: [
          requestTimestampRangePicker[0].set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          }).format(),
          requestTimestampRangePicker[1].set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          }).format(),
        ],
      };
    } else {
      fieldValues = {
        ...values,
        requestTimestamp: [
          moment().utcOffset('-03:00').add(-1, 'day').set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          })
            .format(),
          moment().utcOffset('-03:00').set({
            hour: 0, minute: 0, second: 0, millisecond: 0,
          }).format(),
        ],
      };
    }

    try {
      const result = await sendLogRequest(fieldValues);
      if (result.data && typeof result.data !== 'string') {
        setServiceData(result.data);
      }
    } catch (RequestError) {
      NotificationError('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
    }
    setFetchingResponse(false);
  };

  const onReset = () => {
    form.resetFields();
    setServiceData([]);
  };

  const data = [{}];
  const logs = serviceData;
  const headers = [
    { label: 'Hora da Solicitação', key: 'requestTimestamp' },
    { label: 'Tempo Aceite', key: 'acceptTime' },
    { label: 'Hora do Aceite', key: 'acceptTimestamp' },
    { label: 'Tempo Início', key: 'startTime' },
    { label: 'Hora do Inicio', key: 'startTimestamp' },
    { label: 'Tempo Fim', key: 'endTime' },
    { label: 'Hora do Fim', key: 'endTimestamp' },
    { label: 'Tempo Avaliação', key: 'ratingTime' },
    { label: 'Hora da Avaliação', key: 'archiveTimestamp' },
    { label: 'Tempo Atendimento', key: 'attendanceTime' },
    { label: 'Tempo Total', key: 'totalTime' },
    { label: 'Agente Alocado', key: 'alocatedAgent' },
    { label: 'Observação do Agente', key: 'agentFeedback' },
    { label: 'Departamento', key: 'department' },
    { label: 'Categoria', key: 'category' },
    { label: 'Descrição', key: 'description' },
    { label: 'Situação', key: 'status' },
    { label: 'Quarto', key: 'room' },
    { label: 'Atividade', key: 'activity' },
    { label: 'Nº Atendimento', key: 'attendance' },
    { label: 'Paciente', key: 'patientName' },
    { label: 'Ramal do Leito', key: 'bedPhone' },
    { label: 'Unidade de Internação', key: 'inpatientUnit' },
    { label: 'Serviço Cancelado', key: 'hasCancelled' },
    { label: 'Hora do Cancelamento', key: 'cancelTimestamp' },
    { label: 'Motivo de Cancelamento', key: 'cancelReason' },
    { label: 'Solicitante', key: 'requestingAgent' },
    { label: 'Ramal do Solicitante', key: 'requestingAgentPhone' },
    { label: 'SLA', key: 'slaStatus' },
    { label: 'Avaliação', key: 'ratingText' },
    { label: 'Feedback da Avaliação', key: 'feedback' },
  ];

  function getTotalTime(startDate: number, endDate: number) {
    if (endDate === 0) {
      return null;
    }
    const totalTimeInMiliseconds = Math.abs(endDate - startDate);
    let timeInSeconds = Math.floor(totalTimeInMiliseconds / 1000);
    let timeInHours = (Math.floor(timeInSeconds / 3600) as unknown as string);
    timeInHours += '';
    if (timeInHours.length < 2) {
      timeInHours = `0${timeInHours}`;
    }
    timeInSeconds -= (parseInt(timeInHours, 10) * 3600);
    let timeInMinutes = (Math.floor(timeInSeconds / 60) as unknown as string);
    timeInMinutes += '';
    timeInSeconds -= (parseInt(timeInMinutes, 10) * 60);
    if (timeInMinutes.length < 2) {
      timeInMinutes = `0${timeInMinutes}`;
    }
    if (timeInMinutes === '00' && timeInSeconds > 30) {
      timeInMinutes = '01';
    }
    const timeFormated = (`${timeInHours}:${timeInMinutes}`);

    return timeFormated;
  }

  function getFormattedDateTime(timestamp: number) {
    if (timestamp === 0) {
      return null;
    }
    const formattedDateTime = moment(new Date(timestamp)).format('DD/MM/YYYY HH:mm:ss').toString().slice(0, 19)
      .replace('T', ' ');

    return formattedDateTime;
  }

  for (let i = 0; i < logs.length; i += 1) {
    let acceptTime;
    let startTime;
    let endTime;
    let ratingTime;
    let attendanceTime;
    let totalTime;
    let ratingText;
    let endDate;
    if (serviceData[i].slaStatus === 'Cancelado' || serviceData[i].slaStatus === 'Arquivado') {
      // Calculo tempo total.
      const startDate = new Date(serviceData[i].requestTimestamp as Date).getTime();
      if (serviceData[i].slaStatus === 'Arquivado') {
        endDate = new Date(serviceData[i].archiveTimestamp as Date)?.getTime();
      } else {
        endDate = startDate;
      }
      totalTime = getTotalTime(startDate, endDate);
      // eslint-disable-next-line max-len
      acceptTime = getTotalTime((new Date(serviceData[i].approveTimestamp as Date).getTime()), (new Date(serviceData[i].acceptTimestamp as Date).getTime()));
      // eslint-disable-next-line max-len
      startTime = getTotalTime((new Date(serviceData[i].acceptTimestamp as Date).getTime()), (new Date(serviceData[i].startTimestamp as Date).getTime()));
      // eslint-disable-next-line max-len
      endTime = getTotalTime((new Date(serviceData[i].startTimestamp as Date).getTime()), (new Date(serviceData[i].endTimestamp as Date).getTime()));
      // eslint-disable-next-line max-len
      attendanceTime = getTotalTime((new Date(serviceData[i].requestTimestamp as Date).getTime()), (new Date(serviceData[i].endTimestamp as Date).getTime()));
      // eslint-disable-next-line max-len
      ratingTime = getTotalTime((new Date(serviceData[i].endTimestamp as Date).getTime()), (new Date(serviceData[i].archiveTimestamp as Date).getTime()));
      // Avaliações de Serviço
      const { rating } = serviceData[i];
      if (rating === 1) {
        ratingText = 'Insatisfeito';
      } else if (rating === 10) {
        ratingText = 'Satisfeito';
      } else if (rating === 5) {
        ratingText = 'Indiferente';
      } else {
        ratingText = '';
      }
    }
    // Cancelamento de serviços
    const hasCancelled = serviceData[i].cancelTimestamp === null ? 'Não' : 'Sim';
    const { cancelReason } = serviceData[i];
    const sla = serviceData[i].slaStatus;
    const cancelTimestamp = getFormattedDateTime((new Date(serviceData[i].cancelTimestamp as Date).getTime()));
    // eslint-disable-next-line max-len
    const activity = serviceData[i].activity.name;
    // Informações do hospital
    const alocatedAgent = serviceData[i].alocatedAgent.name;
    const { agentFeedback } = serviceData[i];
    const hospital = serviceData[i].company.name;
    const department = serviceData[i].department.name;
    const category = serviceData[i].category.name;
    const { description } = serviceData[i];
    // Carregamento de informações de atendimento.
    const room = serviceData[i].attendance.ds_leito;
    const attendance = serviceData[i].attendance.atendimento;
    const patientName = serviceData[i].attendance.nm_paciente;
    const bedPhone = serviceData[i].attendance.nr_ramal;
    const inpatientUnit = serviceData[i].attendance.unidade_internacao;
    const requestTimestamp = getFormattedDateTime((new Date(serviceData[i].requestTimestamp as Date).getTime()));
    const acceptTimestamp = getFormattedDateTime((new Date(serviceData[i].acceptTimestamp as Date).getTime()));
    const startTimestamp = getFormattedDateTime((new Date(serviceData[i].startTimestamp as Date).getTime()));
    const endTimestamp = getFormattedDateTime((new Date(serviceData[i].endTimestamp as Date).getTime()));
    const archiveTimestamp = getFormattedDateTime((new Date(serviceData[i].archiveTimestamp as Date).getTime()));
    const { feedback } = serviceData[i];
    let slaStatus;
    if (serviceData[i].slaAcceptTimeExceded !== 'exceded' && serviceData[i].slaStartTimeExceded !== 'exceded' && serviceData[i].slaEndTimeExceded !== 'exceded' && serviceData[i].slaArchiveTimeExceded !== 'exceded') {
      slaStatus = 'Dentro';
    } else {
      slaStatus = 'Acima';
    }
    // Informações de agente
    const requestingAgent = serviceData[i].requestingAgent.name;
    const requestingAgentPhone = serviceData[i].requestingAgent.phone;

    data[i] = {
      requestTimestamp,
      acceptTime,
      startTime,
      endTime,
      ratingTime,
      attendanceTime,
      totalTime,
      alocatedAgent,
      agentFeedback,
      hospital: 'HAC',
      department,
      category,
      description,
      status: sla,
      room,
      activity,
      attendance,
      patientName,
      bedPhone,
      inpatientUnit,
      hasCancelled,
      cancelReason,
      requestingAgent,
      requestingAgentPhone,
      slaStatus,
      ratingText,
      acceptTimestamp,
      startTimestamp,
      endTimestamp,
      archiveTimestamp,
      cancelTimestamp,
      feedback,
    };
  }

  return (
    <>
      <Container>
        <h1>Registro de Atendimentos</h1>
        <Card>
          <Row style={{ display: 'flex' }}>
            <Form
              form={form}
              layout="vertical"
              name="log_request"
              onFinish={onRequest}
            >
              <Row>
                <Col style={{ paddingRight: '60px' }}>
                  {/* <LogDate pickerText="Aprovação do serviço" formName="approveTimestamp" /> */}
                  {
                    permissionChecker(
                      getAuthRoles(),
                      [brasiliaAgentPermission, brasiliaManagerPermission],
                    )
                    && (
                      <Form.Item
                        name="companyId"
                        label="Hospital:"
                      >
                        <Select
                          style={{ marginTop: '8px', width: '200px' }}
                          onChange={filterDepartments}
                        >
                          {
                            allCompanies.map(
                              (company: ICompanyResponse) => (
                                <Select.Option key={company.id} value={company.id}>
                                  {company.name}
                                </Select.Option>
                              ),
                            )
                          }
                        </Select>
                      </Form.Item>
                    )
                  }
                </Col>
                <Col style={{ paddingRight: '60px' }}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    // wrapperCol={{ span: 12 }}
                    label="Data de Requisição:"
                    name="requestTimestamp"
                    rules={[
                      {
                        type: 'array',
                      },
                    ]}
                  >
                    <RangePicker
                      name="Picker"
                      placeholder={['Data inicial', 'Data final']}
                      format="DD/MM/YYYY"
                      style={{ marginTop: '8px', width: '215px' }}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ paddingRight: '60px' }}>
                  <Form.Item
                    labelCol={{ span: 16 }}
                    // wrapperCol={{ span: 12 }}
                    label="Paciente:"
                    name="nm_paciente"
                  >
                    <Input
                      allowClear
                      style={{ marginTop: '8px', width: '200px' }}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ paddingRight: '60px' }}>
                  <Form.Item name="department" label="Departamentos:">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ marginTop: '8px', width: 200 }}
                      placeholder="Selecione os departamentos"
                      optionFilterProp="children"
                      onChange={filterCategories}
                    >
                      {
                        departments?.map(
                          (departament: IDepartment) => (
                            <Select.Option key={departament.id} value={departament.id}>
                              {departament.name}
                            </Select.Option>
                          ),
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col style={{ paddingRight: '60px' }}>
                  <Form.Item
                    name="categoryId"
                    label="Categoria"
                    labelCol={{ span: 16 }}
                    wrapperCol={{ span: 12 }}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ marginTop: '8px', width: '200px' }}
                      placeholder="Selecione as categorias"
                      onChange={filterActivities}
                    >
                      {
                        allCategories?.map(
                          (category) => (
                            <Select.Option key={category.id} value={category.id}>
                              {category.name}
                            </Select.Option>
                          ),
                        )
                      }

                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ paddingRight: '60px' }}>
                  <Form.Item name="activity" label="Atividades:">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ marginTop: '8px', width: '200px' }}
                      placeholder="Selecione as atividades"
                    >
                      {
                        allActivities?.map(
                          (activity: IBSActivityResponse) => (
                            <Select.Option key={activity.id} value={activity.id}>
                              {activity.name}
                            </Select.Option>
                          ),
                        )
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col style={{ paddingRight: '60px' }}>
                  <Form.Item
                    name="rating"
                    label="Avaliação:"
                    labelAlign="left"
                    labelCol={{ span: 16 }}
                  >
                    <Select
                      mode="multiple"
                      style={{ marginTop: '8px', width: '200px' }}
                      options={serviceRatingOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} style={{ paddingRight: '80px' }}>
                <Col>
                  <Button
                    style={{
                      backgroundColor: '#f58220',
                      color: '#fff',
                    }}
                    onClick={form.submit}
                    loading={fetchingResponse}
                    icon={<SearchOutlined />}
                  >
                    Buscar
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{
                      backgroundColor: '#4caf50',
                      color: '#FFF',
                    }}
                    onClick={() => setIsFilterModalOpen(true)}
                    icon={<PlusOutlined />}
                  >
                    Filtros
                  </Button>

                </Col>
                <Col>
                  <Button
                    // shape="round"
                    style={{
                      border: '1px solid',
                      color: '#f58220',
                    }}
                    onClick={onReset}
                    icon={<ReloadOutlined />}
                  >
                    Limpar
                  </Button>
                </Col>

              </Row>

              {/* </Popconfirm>
            </Form.Item> */}
              <FilterModal
                show={isFilterModalOpen}
                handleClose={() => setIsFilterModalOpen(false)}
              />
            </Form>
            <Row style={{ width: '100%' }}>
              <Col>
                <ReportServicesTable
                  containerStyle={{ /* width: '-webkit-fill-available' */ width: '47rem' }}
                  departmentData={serviceData}
                  tableTitle=" "
                  isLog
                  loading={fetchingResponse}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  style={{
                    backgroundColor: '#fff',
                    color: '#4caf50',
                    marginTop: '1px',
                    border: '1px solid',
                  }}
                  icon={<DownloadOutlined />}
                >
                  <CSVLink
                    data={data}
                    filename={`logs_servicos${moment(now()).format('DD-MM-yyyy')}.csv`}
                    headers={headers}
                    separator=";"
                  >
                    Exportar
                  </CSVLink>
                </Button>
              </Col>
            </Row>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default BSLog;
