import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { AxiosError } from 'axios';
import { APINotificationSwitch } from '../../components/NotificationBS';
import ServicesTable from '../../components/ServicesTable';
import { IBSServiceResponse } from '../../components/ServicesTable/components/api';
import responseStatus from '../../providers/responseStatus';
import { getOnGoingServices } from './api';
import Container from './styles';
import { HeaderRow } from '../DashBoardAgent/styles';
import { checkStatusUnauthorized, clientSingleton, getDepartamentsId } from '../../providers/auth';

const SupportSectors: React.FC = () => {
  const [tableData, setTableData] = useState<IBSServiceResponse[]>();
  const [department] = useState<string>('');
  const [serverTimeOffset, setserverTimeOffset] = useState<number | undefined>(0);

  const dataHandler = async () => {
    try {
      const departamentIds = getDepartamentsId();
      const { data, serverTime } = await getOnGoingServices();
      let offset;
      if (serverTime) {
        const serverTimeMillisGMT = new Date(serverTime).getTime();
        const localMillisUTC = new Date().getTime();
        offset = serverTimeMillisGMT - localMillisUTC;
      }
      setserverTimeOffset(offset);
      const tempData = data as IBSServiceResponse[];
      const dataBS = tempData.filter((item: IBSServiceResponse) => {
        if (item.department && departamentIds) {
          return departamentIds.includes(item.department?.id as string);
        }
        return false;
      });
      setTableData(dataBS);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  useEffect(() => {
    dataHandler();
  }, []);

  return (
    <Container>
      <HeaderRow>
        <h1>Dashboard - Agente </h1>
        {/* <Button onClick={handleOpenSolicitationModal}>
          <span style={{ paddingRight: '5px' }}>Solicitações de Cliente</span>
          <Badge size="small" count={1}><BellOutlined /></Badge>
        </Button> */}
      </HeaderRow>
      {
        tableData
          ? (
            <ServicesTable
              tableTitle={department}
              serverTime={serverTimeOffset}
              departmentData={tableData}
            />
          )
          : (
            <Spin size="large">
              <ServicesTable
                tableTitle={department}
                departmentData={[]}
              />
            </Spin>
          )
      }

    </Container>
  );
};

export default SupportSectors;
