import queryString from 'query-string';
import axios from '../../../providers/api';

export enum SLAStatus {
  EmAprovacao = 'Em Aprovação',
  AguardandoAceite = 'Aguardando Aceite',
  AguardandoInicio = 'Aguardando Inicio',
  Rejeitado = 'Rejeitado',
  EmAndamento = 'Em Andamento',
  Finalizado = 'Finalizado',
  Suspenso = 'Suspenso',
  Arquivado = 'Arquivado',
  Cancelado = 'Cancelado',
}
export enum ISLATimeExceded {
  'Exceded' = 'exceded',
  'Warning' = 'warning',
  'Normal' = 'normal',
}

export interface ISla {
  id: string,
  toAccept: number,
  toAcceptWarning: number,
  toStart: number,
  toStartWarning: number,
  toEnd: number,
  toEndWarning: number,
  toArchive: number,
  toArchiveWarning: number
}

export interface ICategoryResume {
  id: string;
  name: string;
}

export interface IActivityResume {
  id: string;
  name: string;
}

export interface INotificationResume {
  name: string,
  userId: string,
  receivedAt: Date,
  readAt: Date,
}

export interface INotificationResponse {
  event: string,
  createAt: Date,
  user: {
    name: string,
    id: string,
  };
  job: {
    name: string,
  };
}

export interface INotificationView {
  id: string,
  receive: Date,
  read: Date,
  user: string,
  job: string,
}

export interface IBSServiceResponse {
  id?: string;
  bed: string | null
  description?: string | null;
  requestTimestamp?: Date;
  approveTimestamp?: Date | null;
  rejectTimestamp?: Date | null;
  acceptTimestamp?: Date | null;
  startTimestamp?: Date | null;
  endTimestamp?: Date | null;
  archiveTimestamp?: Date | null;
  cancelTimestamp?: Date | null;
  slaAcceptTimeExceded?: ISLATimeExceded;
  slaStartTimeExceded?: ISLATimeExceded;
  slaEndTimeExceded?: ISLATimeExceded;
  slaArchiveTimeExceded?: ISLATimeExceded;
  cancelReason?: string | null;
  rating?: number;
  slaStatus?: string;
  slaStartSuspensionTimestamp?: Date | null;
  slaTotalSuspensionTime?: number;
  createAt: Date;
  feedback?: string;
  agentFeedback?: string;
  sla: ISla;
  activity: IActivityResume;
  company: {
    id: string,
    name: string,
  },
  department: {
    id: string,
    name: string,
  },
  attendance: {
    atendimento: number,
    nm_paciente: string,
    dt_nascimento: string,
    unidade_internacao: string,
    ds_leito: string,
    nr_ramal: string,
    acompanhante: string,
  },
  category: ICategoryResume,
  logs: {
    id: string,
    description: string,
    elapsedTime: number,
    createAt: Date,
    user: {
      id: string,
      name: string,
      phone: number
    }
  }[],
  requestingAgent: {
    name: string,
    phone: string,
    job: string,
  },
  alocatedAgent: {
    name: string,
    phone: string,
    job: string,
  },
}

export enum PatchType {
  Approve = 'approve',
  Reject = 'reject',
  Start = 'start',
  Paralyze = 'paralyze',
  Retake = 'retake',
  End = 'end',
  Archive = 'archive',
  Cancel = 'cancel',
  Rate = 'rate',
}

type IBody = {
  [key: string]: string | number;
};

export const handleServicePatch = async (
  BServiceId: string, patchType: PatchType, body?: IBody,
): Promise<IAxiosResponse<Record<string, unknown>>> => {
  const axiosResponse = await axios().patch(`bsservice/${BServiceId}/${patchType}`, body);
  return {
    status: axiosResponse.status,
    data: axiosResponse.data as string | Record<string, unknown>,
  };
};

interface INewEventBody {
  bsServiceId: string,
  description: string,
}

interface INewEventResponse {
  id: string,
  description: string,
  elapsedTime: number,
  user: {
    id: string,
    name: string,
    phone: string
  }
}

export const addNewEventPost = async (
  newEventBody: INewEventBody,
): Promise<IAxiosResponse<INewEventResponse>> => {
  const axiosResponse = await axios().post('log/', newEventBody);
  const newEventCreated: INewEventResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: newEventCreated,
  };
};

export const getBsServiceById = async (
  serviceId: string,
): Promise<IAxiosResponse<IBSServiceResponse>> => {
  const axiosResponse = await axios().get(`/bsservice/${serviceId}`);
  const oneBsService: IBSServiceResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: oneBsService,
  };
};

export const getNotificationEvents = async (
  bsservice?: string | null,
): Promise<IAxiosResponse<INotificationView[]>> => {
  const axiosResponse = await axios().get(`/bsserviceEvent/${bsservice}`);
  const notificationInfo = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: notificationInfo,
  };
};
