/* eslint-disable max-len */
import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef, useEffect,
} from 'react';
import {
  Modal, Input, Descriptions, Row, Col, Tag, Card, Divider, Table,
} from 'antd';
import moment from 'moment';
import Title from 'antd/lib/typography/Title';
import { useForm } from 'antd/es/form/Form';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import {
  getNotificationEvents,
  IBSServiceResponse, INotificationResponse, INotificationResume, INotificationView,
} from './api';
import { checkStatusUnauthorized } from '../../../providers/auth';
import { APINotificationSwitch } from '../../NotificationBS';
import responseStatus from '../../../providers/responseStatus';
import DoubleCheck from './styles';
import DoubleCheckGreen from '../../../assets/img/DoubleCheckGreen.png';
import DoubleCheckBlue from '../../../assets/img/DoubleCheckBlue.png';

export interface NotificationModalHandles {
  openModal: () => void;
  dataModal: (serviceRow: IBSServiceResponse, serverTimeFromParent: number | undefined) => void;
}

const DescriptionItensContainer = styled.div`
.ant-descriptions-item-content{
    font-size: 15px;
    font-weight: bold;
  };
`;

const NotificationModal: React.ForwardRefRenderFunction<NotificationModalHandles> = (_props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [serviceData, setServiceData] = useState<IBSServiceResponse>();
  const [serviceId, setServiceId] = useState<string>();
  const [notificationData, setNotificationData] = useState<INotificationView[]>();
  const [serverTime, setserverTime] = useState();

  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  const dataModal = useCallback((serviceRow, serverTimeFromParent) => {
    setServiceData(serviceRow);
    setServiceId(serviceRow.id);
    setserverTime(serverTimeFromParent);
  }, []);

  useImperativeHandle(ref, () => ({
    openModal,
    dataModal,
  }));

  const handleCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const result = await getNotificationEvents(serviceId);
        if (result.data && typeof result.data !== 'string') {
          setNotificationData(result.data);
        }
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [serviceId]);

  const formattedDateTime = (date: Date | undefined) => {
    let data;
    if (date) {
      const serviceDate = new Date(date);
      const serviceDay = serviceDate.getDate();
      const serviceMonth = serviceDate.getMonth();
      const day = serviceDay < 10 ? `0${serviceDay}` : serviceDay;
      const month = serviceMonth < 10 ? `0${serviceMonth}` : serviceMonth;
      data = `${day}/${month}/${serviceDate.getFullYear()}`;
    }
    return data;
  };

  const RenderHour = (hour: Date | undefined | null) => {
    if (hour !== null && hour !== undefined) {
      const time = moment(hour).format('LT');
      return time;
    }
  };

  if (!visible) {
    return null;
  }

  if (!serviceData) {
    return <></>;
  }

  const titleOfColumns = (title: string) => {
    if (title === 'Entregue') {
      return (
        <div style={{ fontSize: '14px' }}>
          <DoubleCheck src={DoubleCheckBlue} />
          {' Entregue'}
        </div>
      );
    } if (title === 'Lida') {
      return (
        <div style={{ fontSize: '14px' }}>
          <DoubleCheck src={DoubleCheckGreen} />
          {' Lida'}
        </div>
      );
    } return (
      <p style={{ fontSize: '14px' }}>{title}</p>
    );
  };

  const notificationCollumns = [
    {
      title: titleOfColumns('Nome'),
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: titleOfColumns('Função'),
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: titleOfColumns('Entregue'),
      key: 'receive',
      render: (row: INotificationView) => {
        const { receive } = row;

        if (receive) {
          return RenderHour(receive);
        }
        return null;
      },
    },
    {
      title: titleOfColumns('Lida'),
      key: 'read',
      render: (row: INotificationView) => {
        const { read } = row;

        if (read) {
          return RenderHour(read);
        }
        return null;
      },
    },
  ];

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={handleCloseModal}
      width="70vw"
      centered
    >
      <Row justify="space-between">
        <Title level={4} style={{ fontSize: '18px' }}>
          STATUS DO RECEBIMENTO NOS RAMAIS MÓVEIS
        </Title>
      </Row>
      <Divider
        style={{
          margin: 0,
        }}
      />
      <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
        <DescriptionItensContainer>
          <Descriptions column={6} layout="vertical">
            <Descriptions.Item>
              <div style={{ margin: 0 }}>
                <p style={{ fontSize: '12px', fontWeight: 'normal', margin: 0 }}>Atendimento</p>
                <br style={{ margin: 0 }} />
                <p style={{ margin: 0 }}>{serviceData.attendance?.atendimento}</p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item>
              <div>
                <p style={{ fontSize: '12px', fontWeight: 'normal', margin: 0 }}>Horário</p>
                <br style={{ margin: 0 }} />
                <p style={{ margin: 0, fontSize: '14px' }}>{RenderHour(serviceData.requestTimestamp)}</p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item>
              <div>
                <p style={{ fontSize: '12px', fontWeight: 'normal', margin: 0 }}>Data</p>
                <br style={{ margin: 0 }} />
                <p style={{ margin: 0, fontSize: '14px' }}>{formattedDateTime(serviceData.requestTimestamp)}</p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item>
              <div>
                <p style={{ fontSize: '12px', fontWeight: 'normal', margin: 0 }}>Departamento</p>
                <br style={{ margin: 0 }} />
                <p style={{ margin: 0, fontSize: '14px' }}>{serviceData.department?.name}</p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item>
              <div>
                <p style={{ fontSize: '12px', fontWeight: 'normal', margin: 0 }}>Categoria</p>
                <br style={{ margin: 0 }} />
                <p style={{ margin: 0, fontSize: '14px' }}>{serviceData.category?.name}</p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item>
              <div>
                <p style={{ fontSize: '12px', fontWeight: 'normal', margin: 0 }}>Atividade</p>
                <br style={{ margin: 0 }} />
                <p style={{ margin: 0, fontSize: '14px' }}>{serviceData.activity?.name}</p>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </DescriptionItensContainer>
      </Row>
      <Row>
        <Table
          columns={notificationCollumns}
          style={{ width: '100%' }}
          dataSource={notificationData}
          bordered
        />
      </Row>
    </Modal>
  );
};

export default forwardRef(NotificationModal);
