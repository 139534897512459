import { io, Socket } from 'socket.io-client';
import axios from './api';

export const TOKEN_KEY = 'token';
export const COMPANY_KEY = 'company';
export const DEPARTAMENT_KEY = 'departamentIds';
export const USER_KEY = 'user';
export const ROLES = 'roles';
export const DEPARTMENTS = 'departments';

export interface IRoles {
  id: string,
  description: string,
  displayName: string,
  createAt: Date | null,
  updateAt: Date | null,
  deleteAt: Date | null,
}

export interface IDepartment {
  id: string,
  name: string,
}

export const isAuthenticated = (): boolean => localStorage.getItem(TOKEN_KEY) !== null;
export const setAuthToken = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const removeAuthToken = (): void => {
  // localStorage.removeItem(TOKEN_KEY);
  // localStorage.removeItem(COMPANY_KEY);
  // localStorage.removeItem(DEPARTMENTS);
  // localStorage.removeItem(DEPARTAMENT_KEY);
  // localStorage.removeItem(ROLES);
  localStorage.clear();
};

export const onLogout = async (userToken: string | null): Promise<void> => {
  try {
    axios().post('/logout', {
      userId: userToken,
    });

    removeAuthToken();
  } catch (error) {
    console.log(error.response);
  }
};

export const setAuthRoles = (roles: Array<IRoles>): void => {
  if (roles) {
    localStorage.setItem(ROLES, JSON.stringify(roles));
  } else {
    localStorage.setItem(ROLES, '');
  }
};

export const getAuthRoles = (): Array<IRoles> => {
  const data = localStorage.getItem(ROLES) || '[]';
  return JSON.parse(data);
};

export const setAuthDepartments = (departments: Array<IDepartment>): void => {
  if (departments) {
    localStorage.setItem(DEPARTMENTS, JSON.stringify(departments));
  } else {
    localStorage.setItem(DEPARTMENTS, '');
  }
};

export const getAuthDepartments = (): Array<IDepartment> => {
  const data = localStorage.getItem(DEPARTMENTS) || '[]';
  return JSON.parse(data);
};

export const setAuthCompanyId = (id: string | undefined): void => {
  if (id) {
    localStorage.setItem(COMPANY_KEY, id);
  } else {
    localStorage.setItem(COMPANY_KEY, '');
  }
};
export const setDepartments = (ids: string[] | undefined): void => {
  if (ids) {
    localStorage.setItem(DEPARTAMENT_KEY, JSON.stringify(ids));
  } else {
    localStorage.setItem(DEPARTAMENT_KEY, '');
  }
};

export const getAuthCompanyId = (): string | null => localStorage.getItem(COMPANY_KEY);
export const getDepartamentsId = (): string[] | null => getAuthDepartments().map((dep) => dep.id);

export const checkStatusUnauthorized = (code: number | undefined): void => {
  if (code === 401) {
    const event = new Event('unauthorized');
    window.dispatchEvent(event);
  }
};

export const setAuthUserId = (id: string | undefined): void => {
  if (id) {
    sessionStorage.setItem(USER_KEY, id);
  } else {
    sessionStorage.setItem(USER_KEY, '');
  }
};

export const getAuthUserId = (): string | null => sessionStorage.getItem(USER_KEY);

export const clientSingleton = (() => {
  let instance = undefined as Socket | undefined;
  let promise = undefined as Promise<Socket> | undefined;

  const createInstance = () => axios().get('/socket').then((response) => io('http://localhost:3002', {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: `Bearer ${response.data.token}`,
          userId: getAuthUserId(),
        },
      },
    },
  }));

  return {
    getInstance: async () => {
      if (!instance) {
        if (!promise) {
          promise = createInstance();
        }
        instance = await promise;
      }
      return instance;
    },
  };
})();
