import styled from 'styled-components';

const GoAheadLogo = styled.img`
    height: 25px;
    width: 50px;    
   -webkit-filter: brightness(0.9)contrast(3.1)hue-rotate(0deg)saturate(1.0);
    filter: brightness(0.9)contrast(3.1)hue-rotate(0deg)saturate(1.0);  
`;

export default GoAheadLogo;
