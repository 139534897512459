import { FrownOutlined } from '@ant-design/icons';
import { Modal, Result } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onLogout } from '../../providers/auth';

const UnauthorizedModal: React.FC = () => {
  const [showModal, setshowModal] = useState<boolean>(true);
  const navigate = useNavigate();
  // const [modal, contextHolder] = Modal.useModal();

  const handleOk = () => {
    setshowModal(false);
    navigate('/');
    onLogout(null);
  };

  console.log('Modal Unauthorized');
  return (
    <Modal title="Erro de autenticação" visible={showModal} onOk={handleOk} maskClosable={false} closable={false} cancelButtonProps={{ hidden: true }}>
      <Result
        icon={<FrownOutlined />}
        title="Não pudemos identificar te identificar, favor autenticar novamente."
      />
    </Modal>
  );
};

export default UnauthorizedModal;
