import styled from 'styled-components';

export const GoAheadLogo = styled.img`
    width: 80%;
    min-width: 64px;
    height: auto;
    display: inline-block;
    padding-bottom: 2em;
    margin: 10px 10px 10px 12px;
`;

export const ContentBS = styled.div`
    margin: 24px 16px;
    display: table;
    padding: 24px; 
    background: white; 
    min-height: 360px;
    min-width: 138vh;
`;
