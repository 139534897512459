import React from 'react';
import { Layout } from 'antd';
import Sidemenu from '../../components/Sidemenu';
import NavigationRoutes from '../index';
import FooterBS from '../../components/Footer';
import Container from './styles';
import { GeneralProvider } from '../../context/General';

const Dashboard: React.FC = () => (
  <Layout>
    <Sidemenu />
    <Container>
      <GeneralProvider>
        <NavigationRoutes />
      </GeneralProvider>
      <FooterBS />
    </Container>
  </Layout>
);

export default Dashboard;
