import React from 'react';
import { Layout } from 'antd';
import GoAheadLogo from './styles';
import logo from '../../assets/img/logo_goaheadit.png';

const { Footer } = Layout;

const FooterBS: React.FC = () => (
  <Footer style={{ textAlign: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <p>
        Brasilia Service © 2021
        <br />
        Powered by
        <GoAheadLogo src={logo} />
      </p>
    </div>
  </Footer>
);

export default FooterBS;
