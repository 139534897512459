import React, { useCallback, useEffect, useState } from 'react';
import {
  DeleteOutlined, EditOutlined, PlusOutlined, QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button, Card, Form, Modal, Popconfirm, Table, Tooltip,
} from 'antd';
import { AxiosError } from 'axios';
import {
  deleteActivity,
  getActivities,
  getActivityById,
  IActivityResponse,
  ICategoryById,
  INewActivityRequest,
  IUpdateActivityRequest,
  newActivity,
  updateActivityPatch,
} from './api';
import responseStatus from '../../../../providers/responseStatus';
import { APINotificationSwitch } from '../../../../components/NotificationBS';
import { checkStatusUnauthorized } from '../../../../providers/auth';
import FormCreateAndUpdate from './formCreateAndUpdate';
import { getService } from '../../../DashBoardAgent/api';

export interface IActivity {
  id: string,
  name: string,
  toAccept: number,
  toAcceptWarning: number,
  toStart: number,
  toStartWarning: number,
  toEnd: number,
  toEndWarning: number,
  toArchive: number,
  toArchiveWarning: number,
  createAt: Date,
}

const Activities: React.FC<{
  categoryId: string | undefined
}> = ({ categoryId }) => {
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [editActivityVisible, setEditActivityVisible] = useState<boolean>(false);
  const [newActivityVisible, setNewActivityVisible] = useState<boolean>(false);
  const [activityIdOnRow, setactivityIdOnRow] = useState<string>();
  const [categoryIdFromParent, setcategoryIdFromParent] = useState<string>();
  const [data, setData] = useState<IActivity[] | undefined>();
  const [fetchingUpdate, setfetchingUpdate] = useState<boolean>(false);
  const [fetchingCreate, setfetchingCreate] = useState<boolean>(false);
  const [fetchingActivitiesData, setfetchingActivitiesData] = useState<boolean>(false);

  const fillActivities = useCallback(
    () => {
      if (categoryId) {
        getActivities(categoryId).then((dataActivities) => {
          const tempData = dataActivities.data as ICategoryById;
          setData(tempData.activities);
          setfetchingActivitiesData(false);
        }).catch((BsErr) => {
          const error = BsErr as AxiosError;
          checkStatusUnauthorized(error.response?.status);
          const responseText = responseStatus(error.response?.status);
          APINotificationSwitch(responseText, error.response?.data);
        });
      }
    },
    [setData, categoryId],
  );

  useEffect(() => {
    setfetchingActivitiesData(true);
    setcategoryIdFromParent(categoryId);
    fillActivities();
  }, [fillActivities, categoryId]);

  const fillOneActivityInfo = async (activityId: string) => {
    try {
      if (activityId) {
        const oneActivityInfo = await getActivityById(activityId);
        const oneActivityInfoData = oneActivityInfo.data as IActivityResponse;
        formUpdate.setFieldsValue({
          name: oneActivityInfoData.name,
          toAccept: oneActivityInfoData.sla.toAccept,
          toAcceptWarning: oneActivityInfoData.sla.toAcceptWarning,
          toStart: oneActivityInfoData.sla.toStart,
          toStartWarning: oneActivityInfoData.sla.toStartWarning,
          toEnd: oneActivityInfoData.sla.toEnd,
          toEndWarning: oneActivityInfoData.sla.toEndWarning,
          toArchive: oneActivityInfoData.sla.toArchive,
          toArchiveWarning: oneActivityInfoData.sla.toArchiveWarning,
        });
        setactivityIdOnRow(activityId);
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const closeActivityModal = () => {
    setEditActivityVisible(false);
    setNewActivityVisible(false);
  };

  const editActivityHandler = (activityId: string) => {
    formUpdate.resetFields();
    fillOneActivityInfo(activityId);
    setEditActivityVisible(true);
  };

  const handleUpdateActivity = async (
    values: IUpdateActivityRequest,
  ) => {
    setfetchingUpdate(true);
    try {
      if (activityIdOnRow) {
        const updateBody = {
          ...values,
        };
        const dataUpdateCategory = await updateActivityPatch(
          activityIdOnRow, updateBody,
        );
        const responseText = responseStatus(dataUpdateCategory.status);
        setfetchingUpdate(false);
        APINotificationSwitch(responseText, 'Atividade atualizada com sucesso!');
        setEditActivityVisible(false);
        fillActivities();
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setfetchingUpdate(false);
  };

  const handleDeleteActivity = async (activityId: string) => {
    const service = await getService(activityId);
    if (!service) {
      const updateActivityResponse = await deleteActivity(activityId);
      const responseText = responseStatus(updateActivityResponse.status);
      APINotificationSwitch(responseText, 'Atividade deletada com sucesso!');
      fillActivities();
    } else {
      const error = 699;
      const responseText = responseStatus(error);
      APINotificationSwitch(responseText);
    }
  };

  const handleCreateActivity = async (values: INewActivityRequest) => {
    setfetchingCreate(true);
    try {
      const createBody = {
        ...values,
        categoryId: categoryIdFromParent as string,
      };
      const createUserResponse = await newActivity(createBody);
      const responseText = responseStatus(createUserResponse.status);
      setfetchingCreate(false);
      APINotificationSwitch(responseText, 'Atividade criada com sucesso!');
      setNewActivityVisible(false);
      setData([...data as IActivity[], createUserResponse.data as unknown as IActivity]);
      fillActivities();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setfetchingCreate(false);
  };

  const handleNewActivityVisible = () => {
    form.resetFields();
    setNewActivityVisible(true);
  };

  const renderTitle = (title: string, tooltip: string) => (
    <Tooltip title={tooltip} placement="topLeft">
      <div style={{ cursor: 'pointer' }}>
        {title}
        {' '}
        <QuestionCircleOutlined />
      </div>
    </Tooltip>
  );

  const columns = [
    {
      title: 'Atividades',
      dataIndex: 'name',
      align: 'center' as const,
      key: 'name',
    },
    {
      title: 'SLA Aceite',
      children: [
        {
          title: renderTitle('SLA', 'Tempo máximo para que o serviço seja aprovado pelo agente.'),
          dataIndex: ['toAccept'],
          align: 'center' as const,
          key: 'toAccept',
          render: (text: string) => (
            <>
              {`${text}min`}
            </>
          ),
        },
        {
          title: renderTitle('Alerta', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.'),
          dataIndex: ['toAcceptWarning'],
          align: 'center' as const,
          key: 'toAcceptWarning',
          render: (text: string) => (
            <>
              {`${text}%`}
            </>
          ),
        },
      ],
    },
    {
      title: 'SLA Início',
      children: [
        {
          title: renderTitle('SLA', 'Tempo máximo para que o serviço seja iniciado.'),
          dataIndex: ['toStart'],
          align: 'center' as const,
          key: 'toStart',
          render: (text: string) => (
            <>
              {`${text}min`}
            </>
          ),
        },
        {
          title: renderTitle('Alerta', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.'),
          dataIndex: ['toStartWarning'],
          align: 'center' as const,
          key: 'toStartWarning',
          render: (text: string) => (
            <>
              {`${text}%`}
            </>
          ),
        },
      ],
    },
    {
      title: 'SLA Fim',
      children: [
        {
          title: renderTitle('SLA', 'Tempo máximo para que o serviço seja finalizado.'),
          dataIndex: ['toEnd'],
          align: 'center' as const,
          key: 'toEnd',
          render: (text: string) => (
            <>
              {`${text}min`}
            </>
          ),
        },
        {
          title: renderTitle('Alerta', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.'),
          dataIndex: ['toEndWarning'],
          align: 'center' as const,
          key: 'toEndWarning',
          render: (text: string) => (
            <>
              {`${text}%`}
            </>
          ),
        },
      ],
    },
    {
      title: 'SLA Avaliação',
      children: [
        {
          title: renderTitle('SLA', 'Tempo máximo para que o serviço seja arquivado.'),
          dataIndex: ['toArchive'],
          align: 'center' as const,
          key: 'toArchive',
          render: (text: string) => (
            <>
              {`${text}min`}
            </>
          ),
        },
        {
          title: renderTitle('Alerta', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.'),
          dataIndex: ['toArchiveWarning'],
          align: 'center' as const,
          key: 'toArchiveWarning',
          render: (text: string) => (
            <>
              {`${text}%`}
            </>
          ),
        },
      ],
    },
    {
      dataIndex: 'edit',

      render: (_: unknown, row: IActivity) => (
        <div style={{
          display: 'flex',
        }}
        >
          <Button
            // type="primary"
            style={{
              backgroundColor: '#1890ff',
              color: '#FFF',
              marginRight: 10,
              marginBottom: '3px',
            }}
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => editActivityHandler(row.id)}
          />
          <Popconfirm
            title="Deseja confirmar a exclusão da atividade?"
            onConfirm={() => handleDeleteActivity(row.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              // type="primary"
              style={{
                backgroundColor: '#f44336',
                color: '#FFF',
              }}
              icon={<DeleteOutlined />}
              shape="circle"
            />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div style={{ width: '63%' }}>
      <Modal
        title="Editar Atividade"
        visible={editActivityVisible}
        onOk={formUpdate.submit}
        confirmLoading={fetchingUpdate}
        onCancel={closeActivityModal}
        style={{ width: '70%' }}
      >
        <FormCreateAndUpdate
          parentForm={formUpdate}
          onFinishFunction={handleUpdateActivity}
          isRequired={false}
        />
      </Modal>

      <Modal
        title="Nova Atividade"
        visible={newActivityVisible}
        onOk={form.submit}
        confirmLoading={fetchingCreate}
        onCancel={closeActivityModal}
        wrapClassName="new-activity-modal"
      >
        <FormCreateAndUpdate
          parentForm={form}
          onFinishFunction={handleCreateActivity}
          isRequired
        />
      </Modal>
      {
        data
          ? (
            <>
              <Card style={{ justifyContent: 'center' }}>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                  loading={fetchingActivitiesData}
                  size="small"
                  rowKey="id"
                  bordered
                />
              </Card>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{
                  backgroundColor: 'var(--primary)', borderColor: 'var(--primary)', borderRadius: '25px', marginTop: '20px',
                }}
                onClick={handleNewActivityVisible}
              >
                Nova atividade
              </Button>
            </>
          )
          : <h3>Selecione uma categoria para visualizar suas atividades</h3>
      }
    </div>
  );
};

export default Activities;
