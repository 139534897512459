import React from 'react';
import { Menu, Layout } from 'antd';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  AppstoreAddOutlined,
  BarsOutlined,
  LogoutOutlined, TableOutlined, TeamOutlined, UserOutlined,
} from '@ant-design/icons';
import { GoAheadLogo } from './styles';
import logo from '../../assets/img/GO-HEALTH-NEGATIVO.png';
import axios, { getToken } from '../../providers/api';
import { removeAuthToken, getAuthRoles } from '../../providers/auth';
import {
  agentPermissions,
  brasiliaAgentPermission,
  brasiliaManagerPermission,
  managerPermissions,
  userPermissions,
  permissionChecker,
} from '../../providers/rolePermissions';

// const { SubMenu } = Menu;
const { Sider } = Layout;

const Sidemenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setcollapsed] = React.useState<boolean>(true);

  const onLogout = async (userToken: string | null) => {
    try {
      axios().post('/logout', {
        userId: userToken,
      });

      removeAuthToken();
      navigate('/');
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={() => setcollapsed(!collapsed)}
      width={256}
      style={{
        height: 'auto', minHeight: '100vh',
      }}
    >
      <GoAheadLogo src={logo} />
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]}>
        {
          permissionChecker(
            getAuthRoles(),
            [brasiliaManagerPermission, brasiliaAgentPermission, userPermissions],
          ) && (
            <Menu.Item key="/v1/home" icon={<TableOutlined />}>
              <Link to="home">
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
          )
        }
        {
          permissionChecker(
            getAuthRoles(),
            [managerPermissions, brasiliaManagerPermission],
          ) && (
            <Menu.Item key="/v1/agents" icon={<TeamOutlined />}>
              <Link to="Agents">
                <span>Dashboard de Agentes</span>
              </Link>
            </Menu.Item>
          )
        }
        {
          permissionChecker(
            getAuthRoles(),
            brasiliaAgentPermission,
          ) && (
            <Menu.Item key="/v1/formagent" icon={<AppstoreAddOutlined />}>
              <Link to="formagent">
                <span>Solicitação de Serviço</span>
              </Link>
            </Menu.Item>
          )
        }
        {
          permissionChecker(
            getAuthRoles(),
            [brasiliaManagerPermission,
              brasiliaAgentPermission,
              managerPermissions,
            ],
          ) && (
            <Menu.Item key="/v1/bslog" icon={<BarsOutlined />}>
              <Link to="bslog">
                <span>Registro de Atendimentos</span>
              </Link>
            </Menu.Item>
          )
        }
        {
          permissionChecker(
            getAuthRoles(),
            [brasiliaManagerPermission],
          ) && (
            <Menu.Item key="/v1/manager" icon={<UserOutlined />}>
              <Link to="manager">
                <span>Gerenciamento</span>
              </Link>
            </Menu.Item>
          )
        }
        <Menu.Item
          key="logout"
          onClick={() => onLogout(getToken())}
          icon={<LogoutOutlined />}
        >
          <span>Sair</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidemenu;
