import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Card, Input, Modal, Popconfirm, Select, Table,
} from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import Form from 'antd/lib/form';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import { getAllCategories, IBSCategoryResponse } from '../../api';
import {
  deleteCategory,
  getAllDepartments, ICategoryRequest, newCategoryRequest, updateCategory,
} from './api';
import { IBSDepartmentResponse } from '../../../DashBoardAgent/api';
import { useGeneralData } from '../../../../context/General';
import { APINotificationSwitch } from '../../../../components/NotificationBS';
import responseStatus from '../../../../providers/responseStatus';
import { checkStatusUnauthorized } from '../../../../providers/auth';

export const CategoriesTableStyle = styled.div`
  .clickRowStyle{
    background-color: #fff;
  }
  .ant-table-tbody>.clickRowStyle>td{
    background-color: #ffebcc;
  }
  .ant-table-tbody>.clickRowStyle:hover>td{
    background-color: #ffebcc;
  }
`;

const CategoriesTable: React.FC<{
  rowClick: (categoryId: string) => void
}> = (props) => {
  const [form] = Form.useForm();
  const [editCategoryVisible, setEditCategoryVisible] = useState<boolean>(false);
  const [categoryIdOnRow, setcategoryIdOnRow] = useState<string>();
  const [newCategoryVisible, setNewCategoryVisible] = useState<boolean>(false);
  const [rowId, setRowId] = useState<string>();
  const [tableData, setTableData] = useState<IBSCategoryResponse[]>();
  const { allDepartments, setallDepartments } = useGeneralData();
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [fetchingUpdate, setfetchingUpdate] = useState<boolean>(false);
  const [fetchingCreate, setFetchingCreate] = useState<boolean>(false);

  const fillAllCategories = useCallback(
    () => {
      getAllCategories().then((dataCategories) => {
        setTableData(dataCategories.data as IBSCategoryResponse[]);
        setFetchingData(false);
      }).catch((BsErr) => {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      });
    },
    [setTableData],
  );

  useEffect(() => {
    setFetchingData(true);
    fillAllCategories();
  }, [fillAllCategories]);

  const fillAllDepartments = useCallback(() => {
    getAllDepartments().then((dataDepartments) => {
      setallDepartments(dataDepartments.data as IBSDepartmentResponse[]);
    }).catch((BsErr) => {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    });
  }, [setallDepartments]);

  useEffect(() => {
    fillAllDepartments();
  }, [fillAllDepartments]);

  const editCategoryHandler = (categoryId: string) => {
    setcategoryIdOnRow(categoryId);
    setEditCategoryVisible(true);
  };

  const handleDeleteCategory = async (categoryId: string) => {
    try {
      if (categoryId) {
        const updateUserResponse = await deleteCategory(categoryId);
        const responseText = responseStatus(updateUserResponse.status);
        APINotificationSwitch(responseText, 'Categoria deletada com sucesso!');
        fillAllCategories();
        window.location.reload(); // TODO: dar refresh nas informações e não na página
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const handleUpdateCategory = async (name: string) => {
    setfetchingUpdate(true);
    try {
      if (categoryIdOnRow) {
        const dataUpdateCategory = await updateCategory(categoryIdOnRow, name);
        const responseText = responseStatus(dataUpdateCategory.status);
        setfetchingUpdate(false);
        APINotificationSwitch(responseText, 'Categoria atualizada com sucesso!');
        setEditCategoryVisible(false);
        fillAllCategories();
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setfetchingUpdate(false);
  };

  const columns = [
    {
      title: 'Categoria',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Departamento',
      dataIndex: ['department', 'name'],
      key: 'department.name',
      render: (text: string) => text.split(' ').shift(),
    },
    {
      title: 'Empresa',
      dataIndex: ['department', 'name'],
      render: (text: string) => {
        const rowCompany = text.split(' ').pop();
        return rowCompany;
      },
    },
    {
      title: '',
      dataIndex: 'edit',

      render: (_: unknown, row: IBSCategoryResponse) => (
        <div style={{
          display: 'flex',
        }}
        >
          <Button
            // type="primary"
            style={{
              backgroundColor: '#1890ff',
              color: '#FFF',
              marginRight: 10,
            }}
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => editCategoryHandler(row.id)}
          />
          <Popconfirm
            title="Deseja excluir a categoria? Todas as atividades relacionadas serão excluidas."
            onConfirm={() => handleDeleteCategory(row.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              style={{
                backgroundColor: '#f44336',
                color: '#FFF',
              }}
              // type="primary"
              icon={<DeleteOutlined />}
              shape="circle"
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const newCategoryModal = () => {
    form.resetFields();
    setNewCategoryVisible(true);
  };

  const closeCategoryModal = () => {
    setEditCategoryVisible(false);
    setNewCategoryVisible(false);
  };

  const requestActivities = (category: IBSCategoryResponse) => {
    props.rowClick(category.id);
    return {
      onClick: () => setRowId(category.id),
    };
  };

  const setRowClassName = (record: string | IBSCategoryResponse) => (
    typeof (record) !== 'string'
      && record.id === rowId ? 'clickRowStyle' : ''
  );

  const newCategoryHandler = async (values: ICategoryRequest) => {
    setFetchingCreate(true);
    try {
      const dataNewCategory = await newCategoryRequest(values);
      const tempData = dataNewCategory.data as ICategoryRequest;
      const responseText = responseStatus(dataNewCategory.status);
      APINotificationSwitch(responseText, 'Categoria criada com sucesso!');
      setNewCategoryVisible(false);
      fillAllCategories();
      props.rowClick(tempData.id);
      setRowId(tempData.id);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setFetchingCreate(false);
  };

  return (
    <div>
      <Modal
        title="Editar categoria"
        visible={editCategoryVisible}
        onOk={form.submit}
        confirmLoading={fetchingUpdate}
        onCancel={closeCategoryModal}
      >
        <Form
          form={form}
          layout="vertical"
          name="log_request"
          onFinish={handleUpdateCategory}
        >
          <Form.Item name="name" label="Novo nome: ">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Nova Categoria"
        visible={newCategoryVisible}
        onOk={form.submit}
        confirmLoading={fetchingCreate}
        onCancel={closeCategoryModal}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={newCategoryHandler}
        >
          <Form.Item name="name" label="Nome:">
            <Input />
          </Form.Item>
          <Form.Item name="departmentId" label="Departamento:">
            <Select style={{ width: '100%' }}>
              {
                typeof (allDepartments) !== 'string'
                  ? allDepartments?.map(
                    (department) => (
                      <Select.Option key={department.id} value={department.id}>
                        {department.name}
                      </Select.Option>
                    ),
                  )
                  : <></>
              }
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Card>
        <CategoriesTableStyle>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            rowClassName={setRowClassName}
            loading={fetchingData}
            rowKey="id"
            onRow={(record: IBSCategoryResponse) => ({
              onClick: () => {
                requestActivities(record);
                setRowId(record.id);
              },
            })}
          />
        </CategoriesTableStyle>
      </Card>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        style={{
          backgroundColor: 'var(--primary)', borderColor: 'var(--primary)', borderRadius: '25px', marginTop: '20px',
        }}
        onClick={newCategoryModal}
      >
        Nova categoria
      </Button>
    </div>
  );
};

export default CategoriesTable;
