/* eslint-disable max-len */
import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { Table, Tag } from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { AxiosError } from 'axios';
import Container from './styles';
import PatientModal, { PatientModalHandles } from './components/patientModal';
import { slaColors } from '../../styles/GlobalStyles';
import { IBSServiceResponse, SLAStatus } from './components/api';
import TimerDisplay from './components/TimerDisplay';
import getDepartment, { IBSDepartmentResponse } from '../../pages/DashBoardAgent/api';
import { checkStatusUnauthorized } from '../../providers/auth';
import { APINotificationSwitch } from '../NotificationBS';
import responseStatus from '../../providers/responseStatus';
import NotificationModal, { NotificationModalHandles } from './components/notificationModal';

interface IServicesTable {
  tableTitle: string,
  departmentData: IBSServiceResponse[] | undefined,
  containerStyle?: React.CSSProperties,
  loading?: boolean,
  serverTime?: number;
  color?: string;
}

/**
 * TODO:Leito
*/

/**
(a: IBSServiceResponse, b: IBSServiceResponse) => {
        if (a.slaStatus === 'Em Aprovação') return -1;
        if (a.slaStatus === 'Finalizado'
        && b.slaStatus !== 'Em Aprovação') return -1;
        if (a.slaStatus === 'Aguardando Inicio'
        && b.slaStatus !== 'Em Aprovação'
        && b.slaStatus !== 'Finalizado') return -1;
        if (a.slaStatus === 'Em Andamento'
        && b.slaStatus !== 'Em Aprovação'
        && b.slaStatus !== 'Finalizado'
        && b.slaStatus !== 'Aguardando Inicio') return -1;
        return 0;
      },
 */

const ServicesTable: React.FC<IServicesTable> = ({
  tableTitle,
  departmentData,
  containerStyle,
  loading,
  serverTime,
  color,
}: IServicesTable) => {
  const PatientModalRef = useRef<PatientModalHandles>(null);
  const NotificationModalRef = useRef<NotificationModalHandles>(null);
  const [departments, setDepartments] = useState<IBSDepartmentResponse[]>();
  const [validModal, setValidModal] = useState<string>('patient');

  const handlePatienteModal = useCallback((serviceRow) => {
    if (PatientModalRef.current) {
      PatientModalRef.current.dataModal(serviceRow, serverTime);
      PatientModalRef.current.openModal();
    }
  }, [serverTime]);

  const handleNotificationModal = useCallback((serviceRow) => {
    if (NotificationModalRef.current) {
      NotificationModalRef.current.dataModal(serviceRow, serverTime);
      NotificationModalRef.current.openModal();
    }
  }, [serverTime]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getDepartment();
        if (result.data && typeof result.data !== 'string') {
          setDepartments(result.data);
        }
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, []);

  /**
   * This function must wrap the new Date for the client
   * side then it will ajust the offset to the server time
   */
  const adjustTime = (time: Date): Date => {
    const timeISO = new Date(time).getTime() + (serverTime || 0);
    return new Date(timeISO);
  };

  const checkAllSLa = (row: IBSServiceResponse): string => {
    let allColor = slaColors.normal;
    if (row.slaAcceptTimeExceded === 'exceded'
      || row.slaStartTimeExceded === 'exceded'
      || row.slaEndTimeExceded === 'exceded'
      || row.slaArchiveTimeExceded === 'exceded') allColor = slaColors.exceded;
    return allColor;
  };

  const columns = [
    {
      title: '',
      render: (serviceRow: IBSServiceResponse) => (
        <EyeOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => handlePatienteModal(serviceRow)}
        />
      ),
    },
    {
      title: 'Tempo Total',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let slaAtual = 0;
        let endTime: Date;
        let statusAtual: string | undefined;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.archiveTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        switch (row.slaStatus) {
          case SLAStatus.EmAprovacao:
            slaAtual = row.sla.toAccept;
            break;
          case SLAStatus.AguardandoAceite:
            slaAtual = row.sla.toAccept;
            statusAtual = row.slaAcceptTimeExceded;
            break;
          case SLAStatus.AguardandoInicio:
            slaAtual = row.sla.toStart;
            statusAtual = row.slaStartTimeExceded;
            break;
          case SLAStatus.EmAndamento:
            slaAtual = row.sla.toEnd;
            statusAtual = row.slaEndTimeExceded;
            break;
          case SLAStatus.Finalizado:
            slaAtual = row.sla.toArchive;
            statusAtual = row.slaArchiveTimeExceded;
            break;
          default:
            break;
        }

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={slaAtual}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status={statusAtual as string}
          />
        );
      },
    },
    {
      title: 'Hospital',
      dataIndex: ['company', 'name'],
      align: 'center' as const,
      key: 'company',
      render: (company: string, row: IBSServiceResponse) => {
        const filteredDepartament = departments?.filter((department) => department.id.includes(row.department.id));
        if (filteredDepartament !== undefined) {
          return filteredDepartament[0].company.name;
        }
        return ' ';
      },
    },
    /* {
      title: 'Departamento',
      dataIndex: ['department', 'name'],
      align: 'center' as const,
      key: 'department',
    },
     {
      title: 'Categoria',
      dataIndex: ['category', 'name'],
      align: 'center' as const,
      key: 'category',
    },
     {
      title: 'Atividade',
      dataIndex: ['activity', 'name'],
      align: 'center' as const,
      key: 'activity',
    }, */
    {
      title: 'Status',
      key: 'slaStatus',
      dataIndex: 'slaStatus',
      align: 'center' as const,
      render: (status: string, row: IBSServiceResponse) => {
        let newColor = slaColors.normal;
        if (status !== SLAStatus.EmAprovacao
          && status !== SLAStatus.Rejeitado
          && status !== SLAStatus.Suspenso) {
          switch (status) {
            case SLAStatus.AguardandoAceite:
              newColor = slaColors[row.slaAcceptTimeExceded as string];
              break;
            case SLAStatus.AguardandoInicio:
              newColor = slaColors[row.slaStartTimeExceded as string];
              break;
            case SLAStatus.EmAndamento:
              newColor = slaColors[row.slaEndTimeExceded as string];
              break;
            case SLAStatus.Finalizado:
              newColor = slaColors[row.slaArchiveTimeExceded as string];
              break;
            case SLAStatus.Arquivado || SLAStatus.Cancelado:
              newColor = checkAllSLa(row);
              break;
            default:
              console.log('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
              break;
          }
        }
        return (
          <Tag
            color={newColor}
            key={status}
            style={{ borderRadius: '7px' }}
            onMouseEnter={() => setValidModal('notification')}
            onMouseLeave={() => setValidModal('patient')}
            onClick={() => handleNotificationModal(row)}
          >
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Data',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (date: string) => <span>{moment(date).format('DD/MM')}</span>,
    },
    {
      title: 'Hora',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string) => <span>{moment(time).format('LT')}</span>,
    },
    /* {
      title: 'Avaliação',
      dataIndex: 'rating',
      align: 'center' as const,
      render: (rate: number) => {
        let rating;
        if (rate < 5) {
          rating = 'Insatisfeito';
        } else if (rate <= 8) {
          rating = 'Indiferente';
        } else {
          rating = 'Satisfeito';
        }
        return rating;
      },
    },
    {
      title: 'Paciente',
      dataIndex: ['attendance', 'nm_paciente'],
      align: 'center' as const,
      key: 'attendances',
    }, */
    {
      title: 'Quarto',
      align: 'center' as const,
      key: 'ds_leito',
      render: (row: IBSServiceResponse) => {
        if (row.bed) {
          return row.bed;
        }
        return row.attendance.ds_leito;
      },
    },
  ];

  // eslint-disable-next-line no-return-assign
  return (
    <Container
      style={containerStyle = { backgroundColor: color }}
    >
      <h1>{tableTitle}</h1>
      <div style={{
        overflowX: 'auto',
        height: '230px',
      }}
      >

        <Table
          pagination={false}
          columns={columns}
          dataSource={departmentData}
          size="small"
          loading={loading || false}
          rowKey="id"
          onRow={(record: IBSServiceResponse) => ({
            style: { cursor: 'pointer' },
            onClick: () => {
              if (validModal === 'patient') {
                handlePatienteModal(record);
              }
            },
          })}
        />
      </div>
      <PatientModal ref={PatientModalRef} />
      <NotificationModal ref={NotificationModalRef} />

    </Container>
  );
};

ServicesTable.defaultProps = {
  containerStyle: undefined,
  loading: false,
  serverTime: 0,
};

export default ServicesTable;
