import React from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import LoginPage from './pages/LoginPage';
import { isAuthenticated } from './providers/auth';
import Dashboard from './pages/Dashboard';

export const PrivateRoute: React.FC<{
  path: string;
  element: React.ReactElement;
}> = (props) => {
  const { path, element } = props;
  const condition = isAuthenticated();

  return condition ? (<Route path={path} element={element} />)
    : (<Navigate to="/" />);
};

function App(): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <PrivateRoute path="v1/*" element={<Dashboard />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
      <GlobalStyles />
    </Router>
  );
}

export default App;
