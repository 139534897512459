import { notification } from 'antd';

export const NotificationSuccess = (title: string, content: string): void => {
  notification.success({
    message: title,
    description: content,
  });
};

export const NotificationWarning = (title: string, content: string): void => {
  notification.warning({
    message: title,
    description: content,
  });
};

export const NotificationInfo = (title: string, content: string): void => {
  notification.info({
    message: title,
    description: content,
  });
};

export const NotificationError = (title: string, content: string): void => {
  notification.error({
    message: title,
    description: content,
  });
};

export const APINotificationSwitch = (
  status?: string, content?: string | Record<string, unknown>,
): void => {
  switch (status) {
    case 'SUCCESS':
      NotificationSuccess('Sucesso!', '');
      break;
    case 'CLIENTERROR':
      NotificationWarning('Aviso', typeof content === 'string' ? `${content}` : '');
      break;
    case 'SERVERERROR':
      NotificationError('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
      break;
    case 'SERVICEINPROGRESS':
      NotificationError('Existe um serviço desta atividade em andamento!', 'Tente novamente após a finalização do serviço.');
      break;
    default:
      NotificationError('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
      console.log(status, content);
      break;
  }
};
