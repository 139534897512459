import axios from '../../../../providers/api';
import { getAuthCompanyId } from '../../../../providers/auth';
import { IBSDepartmentResponse } from '../../../DashBoardAgent/api';
import { getActivities, deleteActivity, ICategoryById } from '../Activities/api';

export interface ICategoryRequest {
  id: string,
  name: string,
  createAt: Date | null,
  departmentId: {
    id: string,
  }
}

interface IAxiosGetAllDepartments {
  status?: number,
  data: string | IBSDepartmentResponse[],
}

interface IAxiosNewCategoryRequest {
  status?: number,
  data: ICategoryRequest | string,
}

interface IDeleteCategoryResponse {
  id: string,
  name: string,
  createAt: Date | null,
  department: {
    id: string,
  }[];
}
interface IAxiosDeleteCategory {
  status: number,
  data: string | IDeleteCategoryResponse,
}

export const newCategoryRequest = async (
  createCategoryBody: ICategoryRequest,
): Promise<IAxiosNewCategoryRequest> => {
  const axiosResponse = await axios().post('/category', createCategoryBody);
  return {
    status: axiosResponse.status,
    data: axiosResponse.data,
  };
};

export const getAllDepartments = async (companyId?: string): Promise<IAxiosGetAllDepartments> => {
  const axiosResponse = await axios().get('/department', {
    params: {
      companyId: companyId || getAuthCompanyId(),
    },
  });
  const bsCategoryData: IBSDepartmentResponse[] = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsCategoryData,
  };
};

export const updateCategory = async (
  categoryId: string, name: string,
): Promise<IAxiosDeleteCategory> => {
  const axiosResponse = await axios().patch(`/category/${categoryId}`, name,
    {
      params: {
        companyId: getAuthCompanyId(),
      },

    });
  const bsUpdatedCategory = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsUpdatedCategory,
  };
};

export const deleteCategory = async (
  categoryId: string,
): Promise<IAxiosDeleteCategory> => {
  const response = await getActivities(categoryId);
  const categories = (response.data as ICategoryById).activities;
  if (response.data) {
    categories.forEach((x) => {
      deleteActivity(x.id);
    });
  }

  const axiosResponse = await axios().delete(`/category/${categoryId}`,
    {
      params: {
        companyId: getAuthCompanyId(),
      },
    });
  const bsDeletedCategory = axiosResponse.data;

  return {
    status: axiosResponse.status,
    data: bsDeletedCategory,
  };
};
