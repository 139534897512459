import axios from '../../providers/api';
import { ICompanyResponse } from '../BSLog/components/api';
import { IBSDepartmentResponse } from '../DashBoardAgent/api';

export interface IOneDepartmentResponse {
  id: string,
  name: string,
  createAt: Date,
  color: string,
  ordenation: number,
  company: {
    id: string,
    name: string
  }
}

export const getOneDepartmentById = async (
  departmentId: string,
): Promise<IAxiosResponse<IOneDepartmentResponse>> => {
  const axiosResponse = await axios().get(`/department/${departmentId}`);
  const bsOneDepartment: IOneDepartmentResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsOneDepartment,
  };
};

export const updateDepartmentPatch = async (
  departmentId: string,
  updateDepartmentBody: string,
): Promise<IAxiosResponse<IOneDepartmentResponse>> => {
  const axiosResponse = await axios().patch(`/department/${departmentId}`, updateDepartmentBody);
  const bsUpdatedDepartment: IOneDepartmentResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsUpdatedDepartment,
  };
};

export const deleteDepartment = async (
  departmentId: string,
): Promise<IAxiosResponse<IOneDepartmentResponse>> => {
  const axiosResponse = await axios().delete(`/department/${departmentId}`);
  const deletedDepartment: IOneDepartmentResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: deletedDepartment,
  };
};

export interface ICreateDeptBody {
  name: string,
  color: string,
  ordenation: number,
  companyIds: string[],
  companyNames: string[],
}

export const createDepartment = async (
  departmentsData: IBSDepartmentResponse[] | undefined,
  color: string,
  createDepartmentBody: ICreateDeptBody,
  companies: ICompanyResponse[],
): Promise<IAxiosResponse<IOneDepartmentResponse>> => {
  const body = createDepartmentBody;
  const departments = departmentsData as IBSDepartmentResponse[];
  const selectedCompanies: Array<string> = [];
  const ordenation = departments[departments?.length - 1].ordenation + 1;
  try {
    companies.forEach((company) => createDepartmentBody.companyIds.forEach((companyId) => {
      if (company.id === companyId) {
        selectedCompanies.push(company.name);
      }
    }));

    body.color = color;
    body.ordenation = ordenation;
    body.companyNames = selectedCompanies;
    console.log(body);
  } finally {
    const axiosResponse = await axios().post('/department/', body);
    const createdDepartment: IOneDepartmentResponse | string = axiosResponse.data;
    // eslint-disable-next-line no-unsafe-finally
    return {
      status: axiosResponse.status,
      data: createdDepartment,
    };
  }
};
