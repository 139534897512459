import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button, Card, Form,
  Input, Modal, Popconfirm,
  Select, Table,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import Tippy from '@tippyjs/react';
import { APINotificationSwitch } from '../../components/NotificationBS';
import { checkStatusUnauthorized } from '../../providers/auth';
import responseStatus from '../../providers/responseStatus';
import { getAllDepartments } from '../AdminBS/components/CategoriesTable/api';
import { getAllCompanies, ICompanyResponse } from '../BSLog/components/api';
import { IBSDepartmentResponse } from '../DashBoardAgent/api';
import {
  createDepartment, deleteDepartment,
  getOneDepartmentById, ICreateDeptBody,
  IOneDepartmentResponse, updateDepartmentPatch,
} from './api';

const DepartmentsManager: React.FC = () => {
  const [formUpdateDepartment] = useForm();
  const [formCreateDepartment] = useForm();
  const [loadingData, setloadingData] = useState<boolean>();
  const [departmentsData, setdepartmentsData] = useState<IBSDepartmentResponse[]>();
  const [loadingUpdateDepartment, setloadingUpdateDepartment] = useState<boolean>();
  const [updateDepartmentVisible, setupdateDepartmentVisible] = useState<boolean>();
  const [departmentIdOnRow, setdepartmentIdOnRow] = useState<string>();
  const [newDepartmentVisible, setnewDepartmentVisible] = useState<boolean>();
  const [loadingCreateDepartment, setloadingCreateDepartment] = useState<boolean>();
  const [allCompanies, setallCompanies] = useState<ICompanyResponse[]>();
  const [selectedColor, setSelectedColor] = useState('#7ED321');

  const fillAllDepartments = async () => {
    setloadingData(true);
    try {
      const deptData = await getAllDepartments();
      setdepartmentsData(deptData.data as IBSDepartmentResponse[]);
      setloadingData(false);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  useEffect(() => {
    fillAllDepartments();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const result = await getAllCompanies();
        setallCompanies(result.data as ICompanyResponse[]);
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [setallCompanies]);

  const fillOneDepartmentInfo = async (departmentId: string) => {
    try {
      if (departmentId) {
        const oneDepartmentInfo = await getOneDepartmentById(departmentId);
        const oneDepartmentInfoData = oneDepartmentInfo.data as IOneDepartmentResponse;
        formUpdateDepartment.setFieldsValue({ name: oneDepartmentInfoData.name });
        setdepartmentIdOnRow(departmentId);
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const handleUpdateDepartmentModal = (departmentId: string) => {
    formUpdateDepartment.resetFields();
    fillOneDepartmentInfo(departmentId);
    setupdateDepartmentVisible(true);
  };

  const handleUpdateDepartment = async (updateDepartmentBody: string) => {
    setloadingUpdateDepartment(true);
    try {
      if (departmentIdOnRow) {
        const updateDepartmentResponse = await updateDepartmentPatch(
          departmentIdOnRow,
          updateDepartmentBody,
        );
        const responseText = responseStatus(updateDepartmentResponse.status);
        APINotificationSwitch(responseText, 'Departamento atualizado com sucesso!');
        setupdateDepartmentVisible(false);
        fillAllDepartments();
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setloadingUpdateDepartment(false);
  };

  const handleUpdateCancel = () => {
    setupdateDepartmentVisible(false);
  };

  const handleDeleteDepartment = async (departmentId: string) => {
    try {
      if (departmentId) {
        const deletedUserResponse = await deleteDepartment(departmentId);
        const responseText = responseStatus(deletedUserResponse.status);
        APINotificationSwitch(responseText, 'Departamento deletado com sucesso!');
        fillAllDepartments();
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const handleCreateDepartmentModal = () => {
    formCreateDepartment.resetFields();
    setnewDepartmentVisible(true);
  };

  const handleCreateDepartment = async (createDepartmentBody: ICreateDeptBody) => {
    setloadingCreateDepartment(true);
    try {
      const createDepartmentResponse = await createDepartment(
        departmentsData, selectedColor, createDepartmentBody, allCompanies as ICompanyResponse[],
      );
      const responseText = responseStatus(createDepartmentResponse.status);
      APINotificationSwitch(responseText, 'Departamento criado com sucesso!');
      setnewDepartmentVisible(false);
      fillAllDepartments();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setloadingCreateDepartment(false);
  };

  const handleCreateCancel = () => {
    setnewDepartmentVisible(false);
  };

  const ExpandedRowRenderDepartments = (data: IBSDepartmentResponse[]) => {
    const columns = [
      {
        title: 'Nome',
        dataIndex: 'name',
        render: (text: string, row: IBSDepartmentResponse) => text.split(row.company.name),
      },
      {
        title: '',
        dataIndex: 'edit',

        render: (_text: string, row: IBSDepartmentResponse) => (
          <div>
            <Button
              style={{
                backgroundColor: '#1890ff',
                color: '#FFF',
                marginRight: 10,
              }}
              icon={<EditOutlined />}
              shape="circle"
              size="large"
              onClick={() => handleUpdateDepartmentModal(row.id)}
            />

            <Popconfirm
              title="Deseja confirmar a exclusão do departamento?"
              onConfirm={() => handleDeleteDepartment(row.id)}
              okText="Sim"
              cancelText="Não"
            >
              <Button
                style={{
                  backgroundColor: '#f44336',
                  color: '#FFF',
                }}
                icon={<DeleteOutlined />}
                shape="circle"
                size="large"
              />
            </Popconfirm>
          </div>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="id"
        bordered
      />
    );
  };

  const columns = [
    {
      title: 'Empresa',
      dataIndex: 'name',
    },
  ];

  const filterDepartmentByCompany = (
    record: ICompanyResponse,
    departments: IBSDepartmentResponse[],
  ) => departments
    .filter((department) => department.company.id === record.id);

  return (
    <>
      <h1>Departamentos</h1>
      <br />
      <Modal
        title="Criar novo departamento"
        visible={newDepartmentVisible}
        onOk={formCreateDepartment.submit}
        confirmLoading={loadingCreateDepartment}
        onCancel={handleCreateCancel}
      >
        <div>
          <Form
            form={formCreateDepartment}
            layout="vertical"
            onFinish={handleCreateDepartment}
          >
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: 'Por favor, informe um nome para o Departamento!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="companyIds"
              label="Hospital"
              rules={[
                {
                  required: true,
                  message: 'Por favor, selecione um Hospital!',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                showSearch
                placeholder="Nome do Hospital"
                optionFilterProp="children"
              >
                {allCompanies?.map((company) => (
                  <Select.Option key={company.id} value={company.id}>
                    {company.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="color"
              label="Escolha uma cor:"
              required
            >
              <Tippy
                interactive
                placement="bottom"
                content={(
                  <SketchPicker
                    color={selectedColor}
                    onChangeComplete={(color) => setSelectedColor(color.hex)}
                  />
                )}
              >
                <Button style={{
                  backgroundColor: selectedColor,
                }}
                >
                  <div
                    style={{
                      width: '100px',
                    }}
                  />
                </Button>
              </Tippy>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Atualizar departamento"
        visible={updateDepartmentVisible}
        onOk={formUpdateDepartment.submit}
        confirmLoading={loadingUpdateDepartment}
        onCancel={handleUpdateCancel}
      >
        <div>
          <Form
            form={formUpdateDepartment}
            layout="vertical"
            onFinish={handleUpdateDepartment}
          >
            <Form.Item
              name="name"
              label="Nome"
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Card>

        <Table
          dataSource={allCompanies}
          loading={loadingData}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => (
              departmentsData
                ? ExpandedRowRenderDepartments(
                  filterDepartmentByCompany(record, departmentsData),
                )
                : []),
          }}
          pagination={false}
          rowKey="id"
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{
            backgroundColor: 'var(--primary)', borderColor: 'var(--primary)', borderRadius: '25px', marginTop: '30px',
          }}
          onClick={handleCreateDepartmentModal}
        >
          Novo departamento
        </Button>
      </Card>
    </>
  );
};

export default DepartmentsManager;
