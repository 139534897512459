import styled from 'styled-components';

const DoubleCheck = styled.img`
    height: 12px;
    width: 20px;    
   -webkit-filter: brightness(0.9)contrast(3.1)hue-rotate(0deg)saturate(1.0);
    filter: brightness(0.9)contrast(3.1)hue-rotate(0deg)saturate(1.0);  
`;

export default DoubleCheck;
