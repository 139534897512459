import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Routes, Route } from 'react-router-dom';
import DashBoardAgent from './DashBoardAgent';
import RequestForm from './RequestForm';
import SupportSectors from './SupportSectors';
import BSLog from './BSLog';
import AdminBS from './AdminBS';
import {
  agentPermissions,
  brasiliaAgentPermission,
  brasiliaManagerPermission,
  managerPermissions,
  permissionChecker,
  userPermissions,
} from '../providers/rolePermissions';
import UserManager from './UserManager';
import { clientSingleton, getAuthRoles } from '../providers/auth';
import UnauthorizedModal from '../components/alertsModal/UnauthorizedModal';
import { NotificationInfo } from '../components/NotificationBS';
import DepartmentsManager from './DepartmentsManager';
import Manager from './Manager'
import Agents from './Agents';

const { Content } = Layout;

interface ISocketNotification {
  service: string,
  message: string,
  type: string,
}

const NavigationRoutes: React.FC = () => {
  const [userRoles] = useState(getAuthRoles());
  const [unauthorized, setunauthorized] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('unauthorized', () => {
      setunauthorized(true);
    });
    return () => {
      window.removeEventListener('unauthorized', () => { });
    };
  }, []);

  return (
    <>
      <Content style={{ margin: '24px 16px', display: 'table', width: '98%' }}>
        <div style={{
          padding: 24,
          background: 'white',
          minHeight: 360,
        }}
        >
          {unauthorized ? <UnauthorizedModal /> : null}
          <Routes>
            {permissionChecker(userRoles, [brasiliaManagerPermission, brasiliaAgentPermission, managerPermissions, userPermissions]) && <Route path="/home" element={<DashBoardAgent />} />}
            {permissionChecker(userRoles, brasiliaAgentPermission) && <Route path="/formagent" element={<RequestForm />} />}
            {permissionChecker(userRoles, [managerPermissions, agentPermissions]) && <Route path="/servicetable" element={<SupportSectors />} />}
            {permissionChecker(userRoles, [brasiliaManagerPermission, brasiliaAgentPermission, managerPermissions, agentPermissions]) && <Route path="/bslog" element={<BSLog />} />}
            {permissionChecker(userRoles, [brasiliaManagerPermission]) && <Route path="/manager" element={<Manager/>} />}
            {permissionChecker(userRoles, [managerPermissions, brasiliaManagerPermission]) && <Route path="/agents" element={<Agents/>} />}
          </Routes>
        </div>
      </Content>
    </>
  );
};
export default NavigationRoutes;

// TODO: CRIAR PAGINA 404 NOT FOUND
