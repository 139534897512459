import React, { useRef, useCallback } from 'react';
import { Table, Tag } from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Container from '../styles';
import PatientModal, { PatientModalHandles } from './patientModal';
import { slaColors } from '../../../styles/GlobalStyles';
import { IBSServiceResponse, SLAStatus } from './api';
import TimerDisplay from './TimerDisplay';

interface IServicesTable {
  tableTitle: string,
  departmentData: IBSServiceResponse[] | undefined,
  containerStyle?: React.CSSProperties,
  isLog?: boolean,
  loading?: boolean,
  serverTime?: number;
  color?: string;
}

const ServicesTable: React.FC<IServicesTable> = ({
  tableTitle,
  departmentData,
  containerStyle,
  isLog,
  loading,
  serverTime,
  color,
}: IServicesTable) => {
  const PatientModalRef = useRef<PatientModalHandles>(null);

  const handlePatienteModal = useCallback((serviceRow) => {
    if (PatientModalRef.current) {
      PatientModalRef.current.dataModal(serviceRow, serverTime);
      PatientModalRef.current.openModal();
    }
  }, [serverTime]);

  /**
     * This function must wrap the new Date for the client
     * side then it will ajust the offset to the server time
     */
  const adjustTime = (time: Date): Date => {
    const timeISO = new Date(time).getTime() + (serverTime || 0);
    return new Date(timeISO);
  };

  const checkAllSLa = (row: IBSServiceResponse): string => {
    let allColor = slaColors.normal;
    if (row.slaAcceptTimeExceded === 'exceded'
      || row.slaStartTimeExceded === 'exceded'
      || row.slaEndTimeExceded === 'exceded'
      || row.slaArchiveTimeExceded === 'exceded') allColor = slaColors.exceded;
    return allColor;
  };

  const columns = [
    {
      title: '',
      render: (serviceRow: IBSServiceResponse) => (
        <EyeOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => handlePatienteModal(serviceRow)}
        />
      ),
    },
    {
      title: 'Tempo Aceite',
      dataIndex: 'approveTimestamp',
      align: 'center' as const,
      key: 'approveTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let endTime: Date;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.acceptTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado' && !row.acceptTimestamp) {
          endTime = new Date(row?.approveTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado') {
          endTime = new Date(row?.acceptTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        const totalSla = row.sla.toAccept;
        const status = row.slaAcceptTimeExceded;

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={totalSla}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status={status as string}
          />
        );
      },
    },
    {
      title: 'Tempo Inicio',
      dataIndex: 'acceptTimestamp',
      align: 'center' as const,
      key: 'acceptTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let endTime: Date;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.startTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado' && !row.startTimestamp) {
          endTime = new Date(row?.acceptTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado') {
          endTime = new Date(row?.startTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        const totalSla = row.sla.toStart;
        const status = row.slaAcceptTimeExceded;

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={totalSla}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status={status as string}
          />
        );
      },
    },
    {
      title: 'Tempo Fim',
      dataIndex: 'startTimestamp',
      align: 'center' as const,
      key: 'startTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let endTime: Date;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.endTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado' && !row.endTimestamp) {
          endTime = new Date(row?.startTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado') {
          endTime = new Date(row?.endTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        const totalSla = row.sla.toEnd;
        const status = row.slaEndTimeExceded;

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={totalSla}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status={status as string}
          />
        );
      },
    },
    {
      title: 'Tempo Avaliação',
      dataIndex: 'endTimestamp',
      align: 'center' as const,
      key: 'endTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let endTime: Date;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.archiveTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado' && !row.archiveTimestamp) {
          endTime = new Date(row?.endTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado') {
          endTime = new Date(row?.archiveTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        const totalSla = row.sla.toArchive;
        const status = row.slaArchiveTimeExceded;

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={totalSla}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status={status as string}
          />
        );
      },
    },
    {
      title: 'Tempo Atendimento',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let endTime: Date;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.endTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado' && !row.endTimestamp) {
          endTime = new Date(row?.requestTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado') {
          endTime = new Date(row?.endTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        const totalSla = row.sla.toArchive + row.sla.toEnd + row.sla.toStart + row.sla.toAccept;

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={totalSla}
            rowWarningSLA={0}
            isTotal
            status="null"
            slaStatus={row.slaStatus}
          />
        );
      },
    },
    {
      title: 'Tempo Total',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let endTime: Date;
        if (row.slaStatus === 'Arquivado') {
          endTime = new Date(row?.archiveTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado' && !row.endTimestamp) {
          endTime = new Date(row?.requestTimestamp as Date);
        } else if (row.slaStatus === 'Cancelado') {
          endTime = new Date(row?.archiveTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        const totalSla = row.sla.toAccept + row.sla.toEnd + row.sla.toStart + row.sla.toArchive;

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={totalSla}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status="null"
          />
        );
      },
    },
    {
      title: 'Hospital',
      dataIndex: ['company', 'name'],
      align: 'center' as const,
      key: 'company',
      render: (company: string, row: IBSServiceResponse) => {
        const companyName = row?.department.name.split(' ').pop();
        if (companyName) {
          return companyName;
        }
        return 'HAC';
      },
    },
    {
      title: 'Departamento',
      dataIndex: ['department', 'name'],
      align: 'center' as const,
      key: 'department',
    },
    {
      title: 'Categoria',
      dataIndex: ['category', 'name'],
      align: 'center' as const,
      key: 'category',
    },
    {
      title: 'Atividade',
      dataIndex: ['activity', 'name'],
      align: 'center' as const,
      key: 'activity',
    },
    {
      title: 'Status',
      key: 'slaStatus',
      dataIndex: 'slaStatus',
      align: 'center' as const,
      render: (status: string, row: IBSServiceResponse) => {
        let newColor = slaColors.normal;
        if (status !== SLAStatus.EmAprovacao
          && status !== SLAStatus.Rejeitado
          && status !== SLAStatus.Suspenso) {
          switch (status) {
            case SLAStatus.AguardandoAceite:
              newColor = slaColors[row.slaAcceptTimeExceded as string];
              break;
            case SLAStatus.AguardandoInicio:
              newColor = slaColors[row.slaStartTimeExceded as string];
              break;
            case SLAStatus.EmAndamento:
              newColor = slaColors[row.slaEndTimeExceded as string];
              break;
            case SLAStatus.Finalizado:
              newColor = slaColors[row.slaArchiveTimeExceded as string];
              break;
            case SLAStatus.Arquivado || SLAStatus.Cancelado:
              newColor = checkAllSLa(row);
              break;
            default:
              console.log('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
              break;
          }
        }
        return (
          <Tag color={newColor} key={status} style={{ borderRadius: '7px' }}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Data',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (date: string) => <span>{moment(date).format('DD/MM')}</span>,
    },
    {
      title: 'Hora',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string) => <span>{moment(time).format('LT')}</span>,
    },
    {
      title: 'Avaliação',
      dataIndex: 'rating',
      align: 'center' as const,
      render: (rate: number) => {
        let rating;
        if (rate === 1) {
          rating = 'Insatisfeito';
        } else if (rate === 5) {
          rating = 'Indiferente';
        } else if (rate === 10) {
          rating = 'Satisfeito';
        }
        return rating;
      },
    },
    {
      title: 'Paciente',
      dataIndex: ['attendance', 'nm_paciente'],
      align: 'center' as const,
      key: 'attendances',
    },
    {
      title: 'Quarto',
      dataIndex: ['attendance', 'ds_leito'],
      align: 'center' as const,
      key: 'ds_leito',
    },
  ];

  if (!isLog) {
    let initialTime: Date;
    let endTime: Date;
    let suspendedTime: number;
    let metaSLA: number;
    let warningSLA: number;
    let status: string | undefined;

    columns.push({
      title: 'Decorrido Etapa',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        if (row.slaStatus === SLAStatus.EmAprovacao) {
          initialTime = new Date(time);
          endTime = adjustTime(new Date());
          suspendedTime = row.slaTotalSuspensionTime || 0;
          metaSLA = row.sla.toAccept;
          warningSLA = row.sla.toAcceptWarning;
          status = row.slaAcceptTimeExceded;
        } else if (row.slaStatus === SLAStatus.AguardandoAceite && row.approveTimestamp) {
          initialTime = row.approveTimestamp;
          endTime = adjustTime(new Date());
          suspendedTime = row.slaTotalSuspensionTime || 0;
          metaSLA = row.sla.toStart;
          warningSLA = row.sla.toStartWarning;
          status = row.slaAcceptTimeExceded;
        } else if (
          row.slaStatus === SLAStatus.AguardandoInicio && row.acceptTimestamp
        ) {
          initialTime = row.acceptTimestamp;
          endTime = adjustTime(new Date());
          suspendedTime = row.slaTotalSuspensionTime || 0;
          metaSLA = row.sla.toStart;
          warningSLA = row.sla.toStartWarning;
          status = row.slaStartTimeExceded;
        } else if (
          row.slaStatus === SLAStatus.Suspenso && row.acceptTimestamp
          && row.slaStartSuspensionTimestamp
        ) {
          initialTime = row.acceptTimestamp;
          endTime = row.slaStartSuspensionTimestamp;
          suspendedTime = row.slaTotalSuspensionTime || 0;
          metaSLA = row.sla.toEnd;
          warningSLA = row.sla.toEndWarning;
          status = row.slaStartTimeExceded;
        } else if (row.startTimestamp) {
          initialTime = row.startTimestamp;
          endTime = adjustTime(new Date());
          suspendedTime = row.slaTotalSuspensionTime || 0;
          metaSLA = row.sla.toEnd;
          warningSLA = row.sla.toEndWarning;
          status = row.slaStartTimeExceded;
        }
        return (
          <TimerDisplay
            initialRowTime={initialTime}
            endRowTime={endTime}
            suspendedRowTime={suspendedTime}
            rowMetaSLA={metaSLA}
            rowWarningSLA={warningSLA}
            isTotal={false}
            slaStatus={row.slaStatus}
            status={status as string}
          />
        );
      },
    });
  }

  // eslint-disable-next-line no-return-assign
  return (
    <Container
      style={containerStyle = { backgroundColor: color }}
    >
      <h1>{tableTitle}</h1>
      <div style={{
        overflowX: 'auto',
        height: '230px',
      }}
      >

        <Table
          pagination={false}
          columns={columns}
          dataSource={departmentData}
          size="small"
          loading={loading || false}
          rowKey="id"
          onRow={(record: IBSServiceResponse) => ({
            style: { cursor: 'pointer' },
            onClick: () => handlePatienteModal(record),
          })}
        />
      </div>
      <PatientModal ref={PatientModalRef} />
    </Container>
  );
};

ServicesTable.defaultProps = {
  containerStyle: undefined,
  isLog: false,
  loading: false,
  serverTime: 0,
};

export default ServicesTable;
