import axios from '../../providers/api';

export interface IRole {
  id: string,
  description: string,
  displayName: string,
  departments: {
    id: string,
    name: string,
  }[],
}

export interface IJob {
  id: string,
  job: string,
  departmentId: string,
}

interface IAxiosJobResponse {
  status?: number,
  data: string | IJob[],
}

export const getAllJobs = async (): Promise<IAxiosJobResponse> => {
  const axiosResponse = await axios().get('/jobs');
  const bsAllJobs: IJob[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsAllJobs,
  };
};

export interface ICategory {
  id: string,
  name: string,
  departmentId: string
}

interface IAxiosRoleResponse {
  status?: number,
  data: string | IRole[],
}

export const getAllRoles = async (): Promise<IAxiosRoleResponse> => {
  const axiosResponse = await axios().get('/roles');
  const bsAllRoles: IRole[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsAllRoles,
  };
};

export interface IUsersResponse {
  id: string,
  name: string,
  username: string,
  phone: string,
  createAt: Date | null,
  updateAt: Date | null,
  lastSeenAt: string,
  roles: IRole[],
  jobs: IJob[],
  password?: string,
  company: {
    id: string,
    name: string,
  },
  categories: ICategory[],
}

interface IAxiosUserResponse {
  status?: number,
  data: string | IUsersResponse
}

interface IAxiosAllUsersResponse {
  status?: number,
  data: string | IUsersResponse[]
}

export const getAllUsers = async (): Promise<IAxiosAllUsersResponse> => {
  const axiosResponse = await axios().get('/user');
  const bsAllUsers: IUsersResponse[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsAllUsers,
  };
};

export const getOneUserById = async (userId: string): Promise<IAxiosResponse<IUsersResponse>> => {
  const axiosResponse = await axios().get(`/user/${userId}`);
  const bsOneUser: IUsersResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsOneUser,
  };
};

export interface ICreateUserBody {
  username: string,
  password: string,
  name: string,
  roles: Array<number>,
  jobs: string,
  companyId: string,
}

export const postCreateUser = async (
  createUserBody: ICreateUserBody,
): Promise<IAxiosUserResponse> => {
  const axiosResponse = await axios().post('/user', createUserBody);
  const bsCreateUser: IUsersResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsCreateUser,
  };
};

export interface IUpdateUserBody {
  name: string,
  password: string,
  companyId: string,
  roles: Array<number>,
  categories: Array<string>,
}

export const patchUpdateUser = async (
  userId: string, updateUserBody: IUpdateUserBody,
): Promise<IAxiosUserResponse> => {
  const axiosResponse = await axios().patch(`/user/${userId}`, updateUserBody);
  const bsUpdatedUser: IUsersResponse | string = axiosResponse.config.data;
  return {
    status: axiosResponse.status,
    data: bsUpdatedUser,
  };
};

export const deleteUser = async (userId: string): Promise<IAxiosUserResponse> => {
  const axiosResponse = await axios().delete(`/user/${userId}`);
  const deletedUser: IUsersResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: deletedUser,
  };
};
