import {
  CompassOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Popconfirm,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import {
  APINotificationSwitch,
  NotificationWarning,
} from '../../components/NotificationBS';
import { useGeneralData } from '../../context/General';
import { checkStatusUnauthorized, COMPANY_KEY } from '../../providers/auth';
import responseStatus from '../../providers/responseStatus';
import { getAllCategories, getCategoriesByPermission, IBSCategoryResponse } from '../AdminBS/api';
import FormCreateAndUpdate from '../AdminBS/components/Activities/formCreateAndUpdate';
import { getAllCompanies, ICompanyResponse } from '../BSLog/components/api';
import {
  deleteUser,
  getAllRoles,
  getAllJobs,
  getAllUsers,
  getOneUserById,
  ICreateUserBody,
  IRole,
  IJob,
  IUpdateUserBody,
  IUsersResponse,
  patchUpdateUser,
  postCreateUser,
} from './api';

const { Search } = Input;

const UserManager: React.FC = () => {
  const [form] = useForm();
  const [formUpdate] = useForm();
  const [newUserVisible, setnewUserVisible] = useState<boolean>(false);
  const [updateUserVisible, setupdateUserVisible] = useState<boolean>(false);
  const [userIdOnRow, setuserIdOnRow] = useState<string>();
  const [allRoles, setallRoles] = useState<IRole[]>();
  const [allJobs, setallJobs] = useState<IJob[]>();
  const [allCategories, setallCategories] = useState<IBSCategoryResponse[]>();
  const [usersDataSource, setusersDataSource] = useState<IUsersResponse[]>();
  const { allCompanies, setallCompanies } = useGeneralData();
  const [brasiliaServiceRole, setBrasiliaServiceRole] = useState(false);
  const [confirmNewPassword, setconfirmNewPassword] = useState<boolean>(false);
  const [loadingCreateUser, setLoadingCreateUser] = useState<boolean>(false);
  const [loadingUpdateUser, setloadingUpdateUser] = useState<boolean>(false);
  const [lessThanOneRole, setLessThanOneRole] = useState<boolean>(false);
  const [lessThanOneCategorie, setLessThanOneCategorie] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [userDataView, setuserDataView] = useState<IUsersResponse[]>();
  const [confirmNewPasswordHidden, setconfirmNewPasswordHidden] = useState<boolean>(true);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const [categoriesByPermission, setCategoriesByPermission] = useState<IBSCategoryResponse[]>([]);

  const fillCategories = async () => {
    try {
      const result = await getAllCategories();
      setallCategories(result.data as IBSCategoryResponse[]);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillCategoriesByPermission = async (ids: number[]) => {
    // setloadingActivities(true);
    try {
      const result = await getCategoriesByPermission(ids);
      setCategoriesByPermission(result.data as IBSCategoryResponse[]);
      console.log(result.data as IBSCategoryResponse[]);
      if (result.data) {
        if (result.data.length < 1) {
          formUpdate.resetFields(['categories']);
        }
      }
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillRoles = async () => {
    try {
      const result = await getAllRoles();
      setallRoles(result.data as IRole[]);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillAllUsers = async () => {
    try {
      const result = await getAllUsers();
      setusersDataSource(result.data as IUsersResponse[]);
      setuserDataView(result.data as IUsersResponse[]);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillAllJobs = async () => {
    try {
      const result = await getAllJobs();
      setallJobs(result.data as IJob[]);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillOneUserInfo = async (userId: string) => {
    try {
      if (userId) {
        const oneUserInfo = await getOneUserById(userId);
        const oneUserInfoData = oneUserInfo.data as IUsersResponse;
        formUpdate.setFieldsValue({
          name: oneUserInfoData.name,
          phone: oneUserInfoData.phone,
          jobs: oneUserInfoData.jobs.map((job) => job.id),
          username: oneUserInfoData.username,
          companyId: oneUserInfoData.company.id,
          roles: oneUserInfoData.roles.map((role) => role.id),
          categories: oneUserInfoData.categories.map(
            (categorie) => categorie.name,
          ),
        });
        // eslint-disable-next-line radix
        const roleValues = oneUserInfoData.roles.map((role) => parseInt(role.id));
        fillCategoriesByPermission(roleValues);
        setuserIdOnRow(userId);
        if (oneUserInfoData.roles.length > 0) {
          setHasPermission(true);
        } else {
          setHasPermission(false);
        }
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  useEffect(() => {
    setLoadingData(true);
    (async () => {
      try {
        const result = await getAllCompanies();
        setallCompanies(result.data as ICompanyResponse[]);
        setLoadingData(false);
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [setallCompanies]);

  useEffect(() => {
    fillAllUsers();
  }, []);

  useEffect(() => {
    fillRoles();
  }, []);

  useEffect(() => {
    fillCategories();
  }, []);

  useEffect(() => {
    fillAllJobs();
  }, []);

  const handleRoleChange = (values: number[]) => {
    form.setFieldsValue({ roles: values });
    if (values.length > 0) {
      setHasPermission(true);
      fillCategoriesByPermission(values);
    } else {
      setHasPermission(false);
    }

    if (values.includes(1) || values.includes(2) || values.includes(3)) {
      setBrasiliaServiceRole(true);
    } else {
      setBrasiliaServiceRole(false);
    }
  };

  const handleRoleUpdate = async (values: number[]) => {
    if (values.length > 0) {
      setHasPermission(true);
      fillCategoriesByPermission(values);
    } else {
      setHasPermission(false);
    }

    if (values.length >= 1) {
      setLessThanOneRole(false);
      formUpdate.setFieldsValue({ roles: values });
      if (values.includes(1) || values.includes(2) || values.includes(3)) {
        setBrasiliaServiceRole(true);
      } else {
        setBrasiliaServiceRole(false);
      }
    } else {
      setLessThanOneRole(true);
      NotificationWarning(
        'O usuário não pode ter menos de uma permissão',
        'Por favor adicione uma ou mais permissões.',
      );
    }
  };

  const handleCategorieChange = (values: string[]) => {
    if (values.length >= 1) {
      setLessThanOneCategorie(false);
      formUpdate.setFieldsValue({ catetories: values });
    } else {
      setLessThanOneCategorie(true);
      NotificationWarning(
        'O usuário não pode ter menos de uma Categoria',
        'Por favor adicione uma ou mais Categorias.',
      );
    }
  };

  const handleCreateUser = async (createUserBody: ICreateUserBody) => {
    setLoadingCreateUser(true);
    try {
      const createUserResponse = await postCreateUser(createUserBody);
      const responseText = responseStatus(createUserResponse.status);
      APINotificationSwitch(responseText, 'Usuário criado com sucesso!');
      setnewUserVisible(false);
      fillAllUsers();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setLoadingCreateUser(false);
  };

  const handleUpdateModal = (userId: string) => {
    setupdateUserVisible(true);
    formUpdate.resetFields();
    setconfirmNewPassword(false);
    setconfirmNewPasswordHidden(true);
    fillOneUserInfo(userId);
  };

  const handleUpdateUser = async (updateUserBody: IUpdateUserBody) => {
    setloadingUpdateUser(true);
    try {
      if (userIdOnRow) {
        if (
          formUpdate.getFieldValue('newPassword')
          === formUpdate.getFieldValue('password')
        ) {
          const updateUserResponse = await patchUpdateUser(
            userIdOnRow,
            updateUserBody,
          );
          const responseText = responseStatus(updateUserResponse.status);
          APINotificationSwitch(
            responseText,
            'Usuário atualizado com sucesso!',
          );
          setupdateUserVisible(false);
          fillAllUsers();
        } else {
          NotificationWarning(
            'Falha ao atualizar cadastro.',
            'As senhas informadas não coincidem.',
          );
        }
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setloadingUpdateUser(false);
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      if (userId) {
        const updateUserResponse = await deleteUser(userId);
        const responseText = responseStatus(updateUserResponse.status);
        APINotificationSwitch(responseText, 'Usuário deletado com sucesso!');
        fillAllUsers();
      }
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const handleCreateUserVisible = () => {
    form.resetFields();
    setnewUserVisible(true);
  };

  const handleCreateCancel = () => {
    setnewUserVisible(false);
    setCategoriesByPermission([]);
  };

  const handleUpdateCancel = () => {
    setupdateUserVisible(false);
    setCategoriesByPermission([]);
  };

  const handleOnChangeNewPassword = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.currentTarget.value.length !== 0) {
      setconfirmNewPassword(true);
      setconfirmNewPasswordHidden(false);
    } else {
      setconfirmNewPassword(false);
      setconfirmNewPasswordHidden(true);
      formUpdate.setFieldsValue({
        password: undefined,
        newPassword: undefined,
      });
    }
  };

  const filteredUser = async (username: string) => {
    setLoadingData(true);
    try {
      const tempData = usersDataSource?.filter((user) => user.name.includes(username));
      setuserDataView(tempData);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
    setTimeout(() => {
      setLoadingData(false);
    }, 250);
  };

  const columns = [
    {
      title: 'Nome da conta do usuário',
      dataIndex: 'username',
    },
    {
      title: 'Nome de Usuário',
      dataIndex: 'name',
    },
    {
      title: 'Função',
      dataIndex: ['jobs', '0', 'job'],
    },
    {
      title: 'Empresa',
      dataIndex: ['company', 'name'],
    },
    {
      title: '',
      dataIndex: 'edit',

      render: (text: string, row: IUsersResponse) => (
        <div>
          <Button
            style={{
              backgroundColor: '#1890ff',
              color: '#FFF',
              marginRight: 10,
            }}
            icon={<EditOutlined />}
            shape="circle"
            size="large"
            onClick={() => handleUpdateModal(row.id)}
          />

          <Popconfirm
            title="Deseja confirmar a exclusão do usuário?"
            onConfirm={() => handleDeleteUser(row.id)}
            okText="Sim"
            cancelText="Não"
          >
            <Button
              style={{
                backgroundColor: '#f44336',
                color: '#FFF',
              }}
              icon={<DeleteOutlined />}
              shape="circle"
              size="large"
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <>
      <h1>Gerenciador de Usuários</h1>
      <Divider />
      <Modal
        title="Criar novo usuário"
        visible={newUserVisible}
        onOk={form.submit}
        confirmLoading={loadingCreateUser}
        onCancel={handleCreateCancel}
      >
        <div>
          <Form form={form} layout="vertical" onFinish={handleCreateUser}>
            <Form.Item
              name="username"
              label="Nome de usuário"
              rules={[
                {
                  required: true,
                  message: 'Favor digitar o nome de usuário!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Senha"
              rules={[
                {
                  required: true,
                  message: 'Favor digitar a senha!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="name"
              label="Nome"
              rules={[
                {
                  required: true,
                  message: 'Favor digitar o nome!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="jobs"
              label="Função"
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
              >
                {
                  typeof (allJobs) !== 'string'
                    ? allJobs?.map(
                      (job: IJob) => (
                        <Select.Option key={job.id} value={job.id}>
                          {job.job}
                        </Select.Option>
                      ),
                    ) : <></>
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="roles"
              label="Permissões"
              rules={[
                {
                  required: true,
                  message: 'Favor escolher quais permissões o usuário terá!',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                onChange={handleRoleChange}
                optionFilterProp="children"
              >
                {typeof allRoles !== 'string' ? (
                  allRoles?.map((role: IRole) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.displayName}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="companyId"
              label="Empresa"
              rules={[
                {
                  required: true,
                  message: 'Favor escolher a empresa!',
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                showSearch
              >
                {typeof allCompanies !== 'string' ? (
                  allCompanies?.map((company: ICompanyResponse) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="categories"
              label="Categorias"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                disabled={!hasPermission}
              >
                {typeof categoriesByPermission !== 'string' ? (
                  categoriesByPermission?.map((category: IBSCategoryResponse) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Atualizar cadastro"
        visible={updateUserVisible}
        onOk={formUpdate.submit}
        confirmLoading={loadingUpdateUser}
        onCancel={handleUpdateCancel}
        okButtonProps={{ disabled: lessThanOneRole || lessThanOneCategorie }}
      >
        <div>
          <Form form={formUpdate} layout="vertical" onFinish={handleUpdateUser}>
            <Form.Item name="name" label="Nome">
              <Input />
            </Form.Item>
            <Form.Item name="username" label="Nome de Usuário">
              <Input />
            </Form.Item>
            <Form.Item name="roles" label="Permissões">
              <Select mode="multiple" allowClear optionFilterProp="children" onChange={handleRoleUpdate}>
                {typeof allRoles !== 'string' ? (
                  allRoles?.map((role: IRole) => (
                    <Select.Option key={role.id} value={role.id}>
                      {role.displayName}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item name="companyId" label="Empresa">
              <Select allowClear optionFilterProp="children" showSearch>
                {typeof allCompanies !== 'string' ? (
                  allCompanies?.map((company: ICompanyResponse) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item name="categories" label="Categorias">
              <Select mode="multiple" allowClear optionFilterProp="children" onChange={handleCategorieChange} disabled={!hasPermission}>
                {typeof categoriesByPermission !== 'string' ? (
                  categoriesByPermission?.map((category: IBSCategoryResponse) => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name="jobs"
              label="Função"
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
              >
                {
                  typeof (allJobs) !== 'string'
                    ? allJobs?.map(
                      (job: IJob) => (
                        <Select.Option key={job.id} value={job.id}>
                          {job.job}
                        </Select.Option>
                      ),
                    ) : <></>
                }
              </Select>
            </Form.Item>
            <Form.Item
              name="newPassword"
              label={(
                <span>
                  Nova senha&nbsp;
                  <Tooltip
                    placement="topLeft"
                    title="Se não quiser alterar a senha, favor deixar o campo em branco."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              )}
            >
              <Input.Password
                placeholder="Deixar em branco, caso não queria alterar"
                onChange={(e) => handleOnChangeNewPassword(e)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Confirmar nova senha"
              hidden={confirmNewPasswordHidden}
              rules={[
                {
                  required: confirmNewPassword,
                  message: 'Favor confirmar nova senha.',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Row
        justify="end"
        style={{
          marginBottom: 30,
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Search
          placeholder="Nome de Usuário"
          onSearch={(user) => filteredUser(user)}
          enterButton
          style={{
            width: 400,
          }}
        />

        <div>
          <Button
            type="default"
            shape="circle"
            size="large"
            style={{ backgroundColor: '#4caf50' }}
            onClick={handleCreateUserVisible}
          >
            <PlusOutlined style={{ color: 'white' }} />
          </Button>
        </div>
      </Row>

      <Table
        dataSource={userDataView}
        loading={loadingData}
        columns={columns}
        pagination={{
          pageSize: 10,
        }}
        rowKey="id"
      />
    </>
  );
};

export default UserManager;
