/* eslint-disable max-len */
import React, {
  useEffect, useState,
  useCallback,
} from 'react';
import { Spin } from 'antd';
import { AxiosError } from 'axios';
import { ContainerTables, HeaderRow } from '../DashBoardAgent/styles';
import {
  APINotificationSwitch,
} from '../../components/NotificationBS';
import getDepartment, {
  getDepartmentByUser,
  IBSDepartmentResponse,
} from '../DashBoardAgent/api';
import { useGeneralData } from '../../context/General';
import responseStatus from '../../providers/responseStatus';
import AgentsTable from '../../components/AgentsTable';
import {
  checkStatusUnauthorized,
} from '../../providers/auth';
import { getAllUsers, IUsersResponse } from '../UserManager/api';

const Agents: React.FC = () => {
  const { setallDepartments } = useGeneralData();
  const [alldepartmentsFromLogin, setalldepartmentsFromLogin] = useState<IBSDepartmentResponse[]>();
  const [fetchData, setFetchData] = useState<boolean>(true);

  const [userDataView, setuserDataView] = useState<IUsersResponse[]>();

  const fillAllUsers = useCallback(
    () => {
      setFetchData(true);
      getAllUsers().then((allUsers) => {
        if (typeof allUsers.data !== 'string') {
          (allUsers.data as IUsersResponse[]).sort((a, b) => (new Date(a.lastSeenAt).getTime() < new Date(b.lastSeenAt).getTime() ? 1 : -1));
          const resultData = allUsers.data as IUsersResponse[];
          const resultFiltered = resultData.filter((user) => user.roles.filter((role) => role.description !== 'brasiliaServiceManager' && user.lastSeenAt !== null && ((new Date().getTime() - new Date(user.lastSeenAt).getTime()) / 1000) <= 43200.000).length > 0);
          setuserDataView(resultFiltered as IUsersResponse[]);
          setFetchData(false);
        }
      }).catch((BsErr) => {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      });
    }, [setuserDataView],
  );

  useEffect(() => {
    fillAllUsers();
    const intFillAllUsers = setInterval(() => fillAllUsers(), 20000);
    return () => {
      clearInterval(intFillAllUsers);
    };
  }, [fillAllUsers]);

  useEffect(() => {
    const userInfo = userDataView?.map((user) => {
      const userRoles = user.roles.map((roles) => {
        const userDepartaments = roles.departments.map((departament) => {
          const deptArray = departament.name.split(' ');
          deptArray.pop();
          departament.name = deptArray.join(' ');
          return departament;
        });
      });
    });
  }, [userDataView]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getDepartmentByUser();
        if (result.data && typeof result.data !== 'string') {
          const allDepartmentsFromLogin = result.data.map((dept: any) => dept);
          const departmentColors = result.data.map(
            (deptColor: { color: any }) => deptColor.color,
          );
          const duplicatedDepartments = allDepartmentsFromLogin.map((dept) => {
            const deptArray = dept.name.split(' ');
            deptArray.pop();
            dept.name = deptArray.join(' ');
            return dept;
          });

          const nonDuplicateDepartments = duplicatedDepartments.filter(
            (item: { name: any }, index: any) => index
              === duplicatedDepartments.findIndex(
                (itemDup: { name: any }) => itemDup.name === item.name,
              ),

            // duplicatedDepartments.values.name.indexOf(item) === index,
          );

          setalldepartmentsFromLogin(nonDuplicateDepartments);
          setallDepartments(result.data);
        }
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [setallDepartments]);

  return (
    <>
      <HeaderRow>
        <h1>Dashboard de Agentes</h1>
      </HeaderRow>
      <ContainerTables>
        {userDataView ? (
          alldepartmentsFromLogin?.map((dept) => (
            <AgentsTable
              tableTitle={dept.name}
              agentData={userDataView.filter(
                (user) => user.roles.filter((role) => role.description.includes('brasilia')).length === 0
                  && user.roles.filter(
                    (role) => role.departments.filter(
                      (departament) => departament.name === dept.name,
                    ).length > 0,
                  ).length > 0,
              )}
              color={dept.color}
              loading={fetchData}
              key={dept.name}
            />
          ))
        ) : (
          <Spin size="large" />
        )}
      </ContainerTables>
    </>
  );
};

export default Agents;
