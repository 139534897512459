import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Col, Form, Input, Row, Tooltip,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { INewActivityRequest } from './api';

const FormCreateAndUpdate: React.FC<{
  parentForm: FormInstance<INewActivityRequest>,
  onFinishFunction: (data: INewActivityRequest) => Promise<void>,
  isRequired: boolean
}> = ({ parentForm, onFinishFunction, isRequired }) => {
  const renderTitle = (title: string, tooltip: string) => (
    <Tooltip title={tooltip} placement="topLeft">
      <div style={{ cursor: 'pointer' }}>
        {title}
        {' '}
        <QuestionCircleOutlined />
      </div>
    </Tooltip>
  );

  return (
    <Form
      form={parentForm}
      layout="vertical"
      onFinish={onFinishFunction}
    >
      <Form.Item
        name="name"
        label="Nome: "
        rules={[{
          required: true,
          message: 'Favor digitar o nome da nova atividade',
        }]}
      >
        <Input />
      </Form.Item>
      <Row gutter={[24, 16]}>

      <Col span={24}>
        <b> SLA Aceite</b> 
      </Col>

        <Col span={12}>

          <Form.Item
            name="toAccept"
            label={renderTitle('SLA para aceitar(min): ', 'Tempo máximo para que o serviço seja aprovado pelo agente.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar o valor do SLA para aceitar!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="toAcceptWarning"
            label={renderTitle('Alerta SLA aceite(%): ', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar a porcentagem de alerta do SLA aceite!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>

      <Col span={24}>
        <b> SLA Início</b> 
      </Col>

        <Col span={12}>
          <Form.Item
            name="toStart"
            label={renderTitle('SLA para iniciar(min): ', 'Tempo máximo para que o serviço seja iniciado.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar o valor do SLA para início!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="toStartWarning"
            label={renderTitle('Alerta SLA início(%): ', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar a porcentagem de alerta do SLA para iniciar!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>

      <Col span={24}>
        <b> SLA Fim</b> 
      </Col>
        <Col span={12}>
          <Form.Item
            name="toEnd"
            label={renderTitle('SLA para finalizar(min): ', 'Tempo máximo para que o serviço seja finalizado.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar o valor do SLA para finalizar!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="toEndWarning"
            label={renderTitle('Alerta SLA para finalizar(%): ', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar a porcentagem de alerta do SLA para finalizar!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
      <Col span={24}>
        <b> SLA Avaliação</b> 
      </Col>
        <Col span={12}>
          <Form.Item
            name="toArchive"
            label={renderTitle('SLA para Avaliação(min): ', 'Tempo máximo para que o serviço seja arquivado.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar o valor do SLA para finalizar!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="toArchiveWarning"
            label={renderTitle('Alerta SLA para avaliar(%): ', 'Porcentagem, em relação ao tempo total, para alertar que o serviço está quase atingindo o tempo máximo do SLA.')}
            rules={[{
              required: isRequired,
              message: 'Favor digitar a porcentagem de alerta do SLA para avaliar!',
            }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormCreateAndUpdate;
