import { ISla } from '../../../../components/ServicesTable/components/api';
import axios from '../../../../providers/api';
import { getAuthCompanyId } from '../../../../providers/auth';

export interface IActivityResponse {
  id: string,
  name: string,
  createAt: Date | null,
  sla: ISla,
  category: {
    id: string,
  }
}

export interface IAxiosActivityResponse {
  status: number,
  data: IActivityResponse | string,
}

export interface INewActivityRequest {
  name: string,
  toAccept: number,
  toAcceptWarning: number,
  toStart: number,
  toStartWarning: number,
  toEnd: number,
  toEndWarning: number,
  toArchive: number,
  toArchiveWarning: number,
  categoryId: string
}

export const newActivity = async (
  newActivityBody: INewActivityRequest,
): Promise<IAxiosActivityResponse> => {
  const axiosResponse = await axios().post('/activity', newActivityBody);
  const bsNewActivity = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsNewActivity,
  };
};

export interface IUpdateActivityRequest {
  name: string,
  toAccept?: number,
  toAcceptWarning?: number,
  toStart?: number,
  toStartWarning?: number,
  toEnd?: number,
  toEndWarning?: number,
  toArchive?: number,
  toArchiveWarning?: number,
  categoryId?: string
}

export const updateActivityPatch = async (
  activityId: string,
  updateActivityBody: IUpdateActivityRequest,
): Promise<IAxiosActivityResponse> => {
  const axiosResponse = await axios().patch(`/activity/${activityId}`, updateActivityBody);
  const bsUpdatedActivity = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsUpdatedActivity,
  };
};

export const deleteActivity = async (
  activityId: string,
): Promise<IAxiosActivityResponse> => {
  const axiosResponse = await axios().delete(`/activity/${activityId}`,
    {
      params: {
        companyId: getAuthCompanyId(),
      },
    });
  const bsDeletedActivity: IActivityResponse = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsDeletedActivity,
  };
};

export interface ICategoryById {
  id: string,
  name: string,
  createAt: Date,
  department: {
    id: string,
    name: string,
    createAt: string,
  },
  activities: {
    id: string,
    name: string,
    toAccept: number,
    toAcceptWarning: number,
    toStart: number,
    toStartWarning: number,
    toEnd: number,
    toEndWarning: number,
    toArchive: number,
    toArchiveWarning: number,
    createAt: Date,
  }[]
}

export const getActivities = async (categoryId: string): Promise<IAxiosResponse<ICategoryById>> => {
  const axiosResponse = await axios().get(`/category/${categoryId}`);
  const bsActivity: ICategoryById = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsActivity,
  };
};

export const getActivityById = async (
  activityId: string,
): Promise<IAxiosResponse<IActivityResponse>> => {
  const axiosResponse = await axios().get(`/activity/${activityId}`);
  const bsActivity: IActivityResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsActivity,
  };
};
