import { IRoles } from './auth';

export const brasiliaAgentPermission = 'brasiliaServiceCreator';
export const brasiliaManagerPermission = 'brasiliaServiceManager';
export const managerPermissions = 'Manager';
export const agentPermissions = 'Agent';
export const userPermissions = 'User';

export const permissionChecker = (
  userRoles: IRoles[], permission: string[] | string,
): boolean => {
  let authorized = false;

  if (typeof permission === 'string') {
    userRoles.forEach((role) => {
      if (role.description.includes(permission)) {
        authorized = true;
      }
    });
  } else {
    userRoles.forEach((role) => {
      if (permission.find((perm) => role.description.includes(perm))) {
        authorized = true;
      }
    });
  }
  return authorized;
};
