import { Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { SLAStatus } from './api';
import { slaColors } from '../../../styles/GlobalStyles';

interface IProps {
  initialRowTime: Date;
  endRowTime: Date;
  suspendedRowTime: number;
  rowMetaSLA: number;
  rowWarningSLA: number;
  isTotal: boolean;
  slaStatus: string | undefined;
  staticTime?: boolean;
  status: string;
  border?: boolean;
  isNextStage?: boolean;
}

interface ITimerDisplayResponse {
  color: string,
  backgroundColor?: string,
  elapsedTime: string,
  duration: moment.Duration
}

const getTotalTime = (
  initialTime: Date,
  endTime: Date,
  suspendedTime: number,
  status: string,
): ITimerDisplayResponse => {
  let color = '#000000';

  const end = moment(endTime);
  const init = moment(initialTime);

  const elapsedTime = end.unix() - init.unix() - (suspendedTime / 1000);

  const duration = moment.duration(elapsedTime, 's');

  if (status === 'normal') color = slaColors.normal;
  else if (status === 'warning') color = slaColors.warning;
  else color = slaColors.exceded;

  return {
    color,
    elapsedTime: `${(Math.floor(duration.asMinutes() / 60)).toString().padStart(2, '0')}:${duration.get('m').toString().padStart(2, '0')}:${duration.get('s').toString().padStart(2, '0')}`,
    duration,
  };
};

const getTotalTimeByDuration = (
  elapsedTime: moment.Duration,
  status: string,
): ITimerDisplayResponse => {
  let color = '#000000';
  let backgroundColor = '#000000';

  const duration = moment.duration(elapsedTime, 's');

  if (status === 'normal') {
    color = slaColors.normal;
    backgroundColor = 'rgba(79, 190, 255, 0.05)';
  } else if (status === 'warning') {
    color = slaColors.warning;
    backgroundColor = 'rgba(255, 213, 66, 0.05)';
  } else {
    color = slaColors.exceded;
    backgroundColor = 'rgba(248, 98, 106, 0.05)';
  }

  return {
    color,
    elapsedTime: `${(Math.floor(duration.asMinutes() / 60)).toString().padStart(2, '0')}:${duration.get('m').toString().padStart(2, '0')}:${duration.get('s').toString().padStart(2, '0')}`,
    duration,
    backgroundColor,
  };
};

const getSLAColor = (
  initialTime: Date,
  endTime: Date,
  suspendedTime: number,
  status: string,
): ITimerDisplayResponse => {
  let color = '#000000';

  const end = moment(endTime);
  const init = moment(initialTime);

  const elapsedTime = end.unix() - init.unix() - (suspendedTime / 1000);

  const duration = moment.duration(elapsedTime, 's');

  if (status === 'normal') color = slaColors.normal;
  else if (status === 'warning') color = slaColors.warning;
  else { color = slaColors.exceded; }

  return {
    color,
    elapsedTime: `${(Math.floor(duration.asMinutes() / 60)).toString().padStart(2, '0')}:${duration.get('m').toString().padStart(2, '0')}:${duration.get('s').toString().padStart(2, '0')}`,
    duration,
  };
};

const getSLAColorByDuration = (
  elapsedTime: moment.Duration,
  status: string,
): ITimerDisplayResponse => {
  let color = '#000000';
  let backgroundColor = '#000000';

  const duration = moment.duration(elapsedTime, 's');

  if (status === 'normal') {
    color = slaColors.normal;
    backgroundColor = 'rgba(79, 190, 255, 0.05)';
  } else if (status === 'warning') {
    color = slaColors.warning;
    backgroundColor = 'rgba(255, 213, 66, 0.05)';
  } else {
    color = slaColors.exceded;
    backgroundColor = 'rgba(248, 98, 106, 0.05)';
  }

  return {
    color,
    elapsedTime: `${(Math.floor(duration.asMinutes() / 60)).toString().padStart(2, '0')}:${duration.get('m').toString().padStart(2, '0')}:${duration.get('s').toString().padStart(2, '0')}`,
    duration,
    backgroundColor,
  };
};

const TimerDisplay: React.FC<IProps> = ({
  initialRowTime,
  endRowTime,
  suspendedRowTime,
  rowWarningSLA,
  isTotal,
  slaStatus,
  staticTime,
  status,
  border,
  isNextStage,
}) => {
  const [displayTime, setdisplayTime] = useState<string>();
  const [updatedColor, setupdatedColor] = useState<string>();
  const [backgroundColor, setBackgroundColor] = useState<string>();
  const [timerResponse, settimerResponse] = useState<ITimerDisplayResponse>();

  useEffect(() => {
    let fillInitialTime: ITimerDisplayResponse;
    if (isTotal) {
      fillInitialTime = getTotalTime(
        initialRowTime,
        endRowTime,
        suspendedRowTime,
        status,
      );
    } else {
      fillInitialTime = getSLAColor(
        initialRowTime,
        endRowTime,
        suspendedRowTime,
        status,
      );
    }
    settimerResponse(fillInitialTime);
  }, [status, endRowTime, initialRowTime, isTotal, rowWarningSLA, suspendedRowTime]);

  useEffect(() => {
    setdisplayTime(timerResponse?.elapsedTime);
    setupdatedColor(timerResponse?.color);
    setBackgroundColor(timerResponse?.backgroundColor);
    const interval = setInterval(() => {
      if (timerResponse) {
        const durationTime = timerResponse.duration;
        if (!staticTime) {
          if (slaStatus !== SLAStatus.Suspenso && slaStatus !== SLAStatus.Arquivado && slaStatus !== SLAStatus.Cancelado) durationTime.add(1, 'second');
        }
        if (isTotal) {
          const newColorTotal = getTotalTimeByDuration(durationTime, status);
          setupdatedColor(newColorTotal.color);
          setBackgroundColor(newColorTotal.backgroundColor);
        } else {
          const newColor = getSLAColorByDuration(durationTime, status);
          setupdatedColor(newColor.color);
          setBackgroundColor(newColor.backgroundColor);
        }
        setdisplayTime(`${(Math.floor(durationTime.asMinutes() / 60)).toString().padStart(2, '0')}:${durationTime.get('m').toString().padStart(2, '0')}:${durationTime.get('s').toString().padStart(2, '0')}`);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timerResponse, rowWarningSLA, isTotal, initialRowTime,
    suspendedRowTime, endRowTime, slaStatus, status, staticTime]);

  if (border) { // etapa atual
    return (
      <div
        style={{
          marginLeft: '5px', color: updatedColor, fontSize: '14px',
        }}
      >
        {displayTime}
      </div>
    );
  } if (staticTime && !isNextStage) { // etapa passada
    return (
      <div style={{
        marginLeft: '5px', color: updatedColor, fontSize: '14px',
      }}
      >
        {displayTime}
      </div>
    );
  } if (isNextStage) { // proxima etapa
    return (
      <div style={{
        marginLeft: '5px', color: '#464646', fontSize: '14px',
      }}
      >
        {displayTime}
      </div>
    );
  }

  return (
    <Tag color={updatedColor} style={{ borderRadius: '7px' }}>
      {displayTime}
    </Tag>
  );
};

export default TimerDisplay;
