import React, { useRef, useCallback } from 'react';
import { Table, Tag } from 'antd';
import {
  EyeOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Container from './styles';
import PatientModal, { PatientModalHandles } from '../ServicesTable/components/patientModal';
import { slaColors } from '../../styles/GlobalStyles';
import { IBSServiceResponse, SLAStatus } from '../ServicesTable/components/api';
import TimerDisplay from '../ServicesTable/components/TimerDisplay';

interface IAllTable {
  containerStyle?: React.CSSProperties,
  loading?: boolean,
  serverTime?: number,
  color?: string,
  allData?: IBSServiceResponse[] | undefined,
}

const checkAllSLa = (row: IBSServiceResponse): string => {
  let allColor = slaColors.normal;
  if (row.slaAcceptTimeExceded === 'exceded'
    || row.slaStartTimeExceded === 'exceded'
    || row.slaEndTimeExceded === 'exceded'
    || row.slaArchiveTimeExceded === 'exceded') allColor = slaColors.exceded;
  return allColor;
};

const AllTable: React.FC<IAllTable> = ({
  containerStyle,
  loading,
  serverTime,
  color,
  allData,
}: IAllTable) => {
  const PatientModalRef = useRef<PatientModalHandles>(null);

  const handlePatienteModal = useCallback((serviceRow) => {
    if (PatientModalRef.current) {
      PatientModalRef.current.dataModal(serviceRow, serverTime);
      PatientModalRef.current.openModal();
    }
  }, [serverTime]);

  const adjustTime = (time: Date): Date => {
    const timeISO = new Date(time).getTime() + (serverTime || 0);
    return new Date(timeISO);
  };

  const columns = [
    {
      title: '',
      render: (serviceRow: IBSServiceResponse) => (
        <EyeOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => handlePatienteModal(serviceRow)}
        />
      ),
    },
    {
      title: 'Hospital',
      dataIndex: ['company', 'name'],
      align: 'center' as const,
      key: 'company',
      render: (name: string) => name,
    },
    {
      title: 'Status',
      key: 'slaStatus',
      dataIndex: 'slaStatus',
      align: 'center' as const,
      render: (status: string, row: IBSServiceResponse) => {
        let newColor = slaColors.normal;
        if (status !== SLAStatus.EmAprovacao
          && status !== SLAStatus.Rejeitado
          && status !== SLAStatus.Suspenso) {
          switch (status) {
            case SLAStatus.AguardandoAceite:
              newColor = slaColors[row.slaAcceptTimeExceded as string];
              break;
            case SLAStatus.AguardandoInicio:
              newColor = slaColors[row.slaStartTimeExceded as string];
              break;
            case SLAStatus.EmAndamento:
              newColor = slaColors[row.slaEndTimeExceded as string];
              break;
            case SLAStatus.Finalizado:
              newColor = slaColors[row.slaArchiveTimeExceded as string];
              break;
            case SLAStatus.Arquivado || SLAStatus.Cancelado:
              newColor = checkAllSLa(row);
              break;
            default:
              console.log('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
              break;
          }
        }
        return (
          <Tag color={newColor} key={status} style={{ borderRadius: '7px' }}>
            {status.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Data',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (date: string) => <span>{moment(date).format('DD/MM')}</span>,
    },
    {
      title: 'Hora',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string) => <span>{moment(time).format('LT')}</span>,
    },
    {
      title: 'Quarto',
      align: 'center' as const,
      key: 'ds_leito',
      render: (row: IBSServiceResponse) => {
        if (row.bed) {
          return row.bed;
        }
        return row.attendance.ds_leito;
      },
    },
    {
      title: 'Tempo Total',
      dataIndex: 'requestTimestamp',
      align: 'center' as const,
      key: 'requestTimestamp',
      render: (time: string, row: IBSServiceResponse) => {
        let slaAtual = 0;
        let endTime: Date;
        let statusAtual: string | undefined;
        if (row.slaStatus === SLAStatus.Arquivado) {
          endTime = new Date(row?.archiveTimestamp as Date);
        } else if (row?.slaStatus === SLAStatus.Suspenso) {
          endTime = new Date(row?.slaStartSuspensionTimestamp as Date);
        } else {
          endTime = adjustTime(new Date());
        }

        switch (row.slaStatus) {
          case SLAStatus.EmAprovacao:
            slaAtual = row.sla.toAccept;
            break;
          case SLAStatus.AguardandoAceite:
            slaAtual = row.sla.toAccept;
            statusAtual = row.slaAcceptTimeExceded;
            break;
          case SLAStatus.AguardandoInicio:
            slaAtual = row.sla.toStart;
            statusAtual = row.slaStartTimeExceded;
            break;
          case SLAStatus.EmAndamento:
            slaAtual = row.sla.toEnd;
            statusAtual = row.slaEndTimeExceded;
            break;
          case SLAStatus.Finalizado:
            slaAtual = row.sla.toArchive;
            statusAtual = row.slaArchiveTimeExceded;
            break;
          default:
            break;
        }
        console.log(`statusAtual = ${statusAtual}`);

        return (
          <TimerDisplay
            initialRowTime={adjustTime(new Date(time))}
            endRowTime={endTime}
            suspendedRowTime={row.slaTotalSuspensionTime || 0}
            rowMetaSLA={slaAtual}
            rowWarningSLA={0}
            isTotal
            slaStatus={row.slaStatus}
            status={statusAtual as string}
          />
        );
      },
    },
    {
      title: 'Departamento',
      dataIndex: ['department', 'name'],
      align: 'center' as const,
      key: 'departament',
    },
    {
      title: 'Categoria',
      dataIndex: ['category', 'name'],
      align: 'center' as const,
      key: 'categorie',
    },
    {
      title: 'Atividade',
      dataIndex: ['activity', 'name'],
      align: 'center' as const,
      key: 'activity',
    },
    {
      title: 'Paciente',
      dataIndex: ['attendance', 'nm_paciente'],
      align: 'center' as const,
      key: 'pacient',
    },
    {
      title: 'Aberto Por',
      dataIndex: ['requestingAgent', 'name'],
      align: 'center' as const,
      key: 'open',
    },
    {
      title: 'Realizado Por',
      dataIndex: ['alocatedAgent', 'name'],
      align: 'center' as const,
      key: 'done',
    },
  ];

  // eslint-disable-next-line no-return-assign
  return (
    <Container
      // eslint-disable-next-line no-param-reassign
      style={containerStyle = { backgroundColor: color }}
    >
      <div style={{
        overflowX: 'auto',
        height: '100%',
      }}
      >
        <Table
          pagination={false}
          columns={columns}
          dataSource={allData}
          size="small"
          loading={loading || false}
          rowKey="id"
          onRow={(record: IBSServiceResponse) => ({
            style: { cursor: 'pointer' },
            onClick: () => handlePatienteModal(record),
          })}
        />
      </div>
      <PatientModal ref={PatientModalRef} />
    </Container>
  );
};

AllTable.defaultProps = {
  containerStyle: undefined,
  loading: false,
  serverTime: 0,
};

export default AllTable;
