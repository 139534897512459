import React from 'react';
import {
  Card, Form, Input, Row, Col, Button,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  ArrowRightOutlined,
} from '@ant-design/icons';
import { AxiosError } from 'axios';
import logo from '../../assets/img/GO-HEALTH.png';
import { IAPILogin } from './types';
import {
  IRoles,
  setAuthCompanyId,
  setAuthRoles,
  setAuthToken,
  setAuthDepartments,
  setDepartments,
  checkStatusUnauthorized,
  setAuthUserId,
} from '../../providers/auth';

import axios from '../../providers/api';
import responseStatus from '../../providers/responseStatus';
import { APINotificationSwitch } from '../../components/NotificationBS';

interface ILoginResponse {
  id: string,
  username: string,
  name: string,
  departments: {
    id: string,
    name: string,
    companyId: string,
  }[],
  roles: {
    id: number,
    description: string,
    displayName: string
  }[],
  company: {
    id: string,
    name: string,
  }
}

interface IDepartmentsFromLogin {
  id: string,
  name: string,
  companyId: string,
}

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const onFinish = async (values: IAPILogin) => {
    try {
      const response = await axios().post('/login', values);
      const loginResponseData: ILoginResponse = response.data;
      setAuthCompanyId(loginResponseData.company?.id);
      const departamentIds = loginResponseData.departments.map(
        (deps: IDepartmentsFromLogin) => deps.id,
      );
      setDepartments(departamentIds);
      setAuthToken('response.data.token');
      setAuthRoles(response.data.roles);
      setAuthDepartments(response.data.departments);
      setAuthUserId(response.data.id);

      const userRoles = response.data.roles as IRoles[];
      let isBS = false;

      for (let i = 0; i < userRoles.length; i += 1) {
        if (
          parseInt(userRoles[i].id, 10) === 2
          || parseInt(userRoles[i].id, 10) === 3
        ) {
          isBS = true;
          break;
        }
      }
      //if (isBS) {
        navigate('/v1/home');
      //} else {
      //navigate('/v1/servicetable');
      //}
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  return (
    <Card>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col span={8} style={{ display: 'contents' }}>
          <Card>
            <Row style={{ marginBottom: '40px' }}>
              <img src={logo} style={{ maxWidth: '380px' }} alt="logo" />
            </Row>
            <Form
              name="normal_login"
              initialValues={{
                remember: true,
              }}
              onFinish={(values) => onFinish({
                username: values.username,
                password: values.password,
              })}
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Favor digitar o nome do usuário!',
                  },
                ]}
              >
                <Input placeholder="Usuário" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Favor digitar a senha!',
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Senha"
                />
              </Form.Item>
              <Row style={{ justifyContent: 'center', marginTop: '90px' }}>
                <Form.Item>
                  <Button
                    shape="circle"
                    size="large"
                    style={{
                      backgroundColor: '#f58220',
                    }}
                    htmlType="submit"
                  >
                    <ArrowRightOutlined style={{ color: 'white' }} />
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default LoginPage;
