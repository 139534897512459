/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Col, DatePicker, Form, Modal, Row, Select, Divider,
} from 'antd';
import MaskedInput from 'antd-mask-input';
import { AxiosError } from 'axios';
// import form from 'antd/lib/form';
import {
  getAllActivities, ICompanyResponse, getAllCompanies,
  getActivities, getCategories, IBSCategoryResponse, getDepartments, getAllCategories,
  getAllSectors, getAllBedRooms, getBedrooms, IBSSectorResponse,
} from './api';
import {
  checkStatusUnauthorized, IDepartment,
} from '../../../providers/auth';
import responseStatus from '../../../providers/responseStatus';
import { APINotificationSwitch } from '../../../components/NotificationBS';
import { IBSActivityResponse, IBSServiceResponse } from '../../RequestForm/api';
// eslint-disable-next-line max-len
// import { brasiliaAgentPermission, brasiliaManagerPermission, permissionChecker } from '../../../providers/rolePermissions';
import { getAllUsers, IUsersResponse } from '../../UserManager/api';
import { IBSDepartmentResponse } from '../../DashBoardAgent/api';

const { RangePicker } = DatePicker;

const FilterModal = ({ show = false, handleClose, children }: any) => {
  // const [form] = Form.useForm();
  const [attendance, setAttendance] = useState();
  const [allActivities, setAllActivities] = useState<IBSActivityResponse[]>([]);
  const [allCategories, setAllCategories] = useState<IBSCategoryResponse[]>([]);
  const [allSectors, setAllSectors] = useState<IBSSectorResponse[]>([]);
  const [allBed, setAllBeds] = useState<IBSSectorResponse[]>([]);
  const [allUsers, setAllUsers] = useState<IUsersResponse[]>([]);
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [requestTimestamp, setRequestTimestamp] = useState();
  const [serviceData, setServiceData] = useState<IBSServiceResponse[]>([]);
  const [isFilterModalUsed, setIsFilterModalUsed] = useState(false);
  const [allCompanies, setallCompanies] = useState<ICompanyResponse[]>([]);

  const serviceRatingOptions = [
    { id: 1, value: '10', label: 'Satisfeito' },
    { id: 2, value: '5', label: 'Indiferente' },
    { id: 3, value: '1', label: 'Insatisfeito' },
  ];

  const slaOptions = [
    { id: 1, value: 'normal', label: 'Dentro do SLA' },
    { id: 1, value: 'exceded', label: 'Acima do SLA' },
  ];

  const feedbackOptions = [
    { id: 1, value: 'com', label: 'Com' },
    { id: 2, value: 'sem', label: 'Sem' },
  ];

  const slaStatusOptions = [
    { id: 1, value: 'Arquivado', label: 'Arquivado' },
    { id: 2, value: 'Cancelado', label: 'Cancelado' },
  ];

  const filterBedRoms = async (unit: string) => {
    try {
      const result = await getBedrooms(unit);

      const data = result.data as IBSSectorResponse[];

      const newData = data.filter((bed) => bed.situacao_leito !== 'Inativo');
      // eslint-disable-next-line no-nested-ternary
      newData.sort((a, b) => (a.ds_enfermaria < b.ds_enfermaria ? -1 : a.ds_enfermaria > b.ds_enfermaria ? 1 : 0));

      setAllBeds(newData);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillSectors = async () => {
    try {
      const result = await getAllSectors();
      const data = result.data as IBSSectorResponse[];

      // eslint-disable-next-line no-nested-ternary
      data.sort((a, b) => (a.unidade_internacao < b.unidade_internacao ? -1 : a.unidade_internacao > b.unidade_internacao ? 1 : 0));

      setAllSectors(data);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillBedRooms = async () => {
    try {
      const result = await getAllBedRooms();
      setAllBeds(result.data as IBSSectorResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillActivities = async () => {
    // setloadingActivities(true);
    try {
      const result = await getAllActivities();
      setAllActivities(result.data as IBSActivityResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillAllUsers = async () => {
    try {
      const result = await getAllUsers();
      setAllUsers(result.data as IUsersResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillCompanies = async () => {
    try {
      const result = await getAllCompanies();
      setallCompanies(result.data as ICompanyResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const filterActivities = async (category: string) => {
    try {
      const response = await getActivities(category);
      setAllActivities(response.data as IBSActivityResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };
  const filterCategories = async (department: string) => {
    try {
      const response = await getCategories(department);
      setAllCategories(response.data as IBSCategoryResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };
  const filterDepartments = async (company: string) => {
    try {
      const result = await getDepartments(company);
      setDepartments(result.data as IBSDepartmentResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillCategories = async () => {
    // setloadingActivities(true);
    try {
      const result = await getAllCategories();
      setAllCategories(result.data as IBSCategoryResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const mappedCategories = allActivities?.map((activity) => (
    ({ id: activity.category.id, name: activity.category.name })
  ));
  const filteredCategories: { id: string; name: string; }[] = [];
  mappedCategories.forEach((category) => {
    if (!filteredCategories.some((c) => c.id === category.id)) {
      filteredCategories.push({ ...category });
    }
  });

  useEffect(() => {
    fillSectors();
    fillBedRooms();
    fillAllUsers();
    if (!!attendance || !!requestTimestamp) {
      setIsFilterModalUsed(true);
    } else {
      setIsFilterModalUsed(false);
    }
  }, [attendance, requestTimestamp]);

  const onReset = () => {
    // Modal.resetFields();
    setServiceData([]);
  };

  return (
    <Modal
      className="mais_filtros"
      title="+ Filtros"
      visible={show}
      footer={null}
      onCancel={handleClose}
      style={{ display: 'flex' }}
      width="810px"
    >
      <Row>
        <Col>
          <Form.Item
            name="unidade_internacao"
            label="Unidade"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              style={{ marginTop: '8px', width: '180px' }}
              onChange={filterBedRoms}
            >
              {
                allSectors.map(
                  (sector: IBSSectorResponse) => (
                    <Select.Option key={sector.id} value={sector.unidade_internacao}>
                      {sector.unidade_internacao}
                    </Select.Option>
                  ),
                )
              }
            </Select>

          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="ds_leito"
            label="Quarto"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              style={{ marginTop: '8px', width: '120px' }}
            >
              {
                allBed.map(
                  (bed: IBSSectorResponse) => (
                    <Select.Option key={bed.id} value={bed.unidade_internacao}>
                      {bed.ds_enfermaria}
                    </Select.Option>
                  ),
                )
              }
            </Select>

          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="dt_nascimento"
            label="Data de Nascimento"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <MaskedInput
              mask="11/11/1111"
              style={
                {
                  WebkitBoxSizing: 'border-box',
                  boxSizing: 'border-box',
                  margin: '0',
                  marginTop: '8px',
                  fontVariant: 'tabular-nums',
                  listStyle: 'none',
                  WebkitFontFeatureSettings: 'tnum',
                  fontFeatureSettings: 'tnum',
                  position: 'relative',
                  display: 'inline-block',
                  width: '200px',
                  minWidth: '0',
                  padding: '4px 11px',
                  color: 'rgba(0, 0, 0, 0.85)',
                  fontSize: '14px',
                  lineHeight: '1.5715',
                  backgroundColor: '#fff',
                  backgroundImage: 'none',
                  border: '1px solid #d9d9d9',
                  borderRadius: '2px',
                  WebkitTransition: '0.3s',
                  transition: '0.3s',
                }
              }
              placeholder="dd/mm/yyyy"
            />
          </Form.Item>
        </Col>
        <Divider />
        <Col>
          <Form.Item
            name="slaStatus"
            label="Status"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              allowClear
              style={{ marginTop: '8px', width: '140px' }}
              options={slaStatusOptions}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="slaAcceptTimeExceded"
            label="SLA"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              allowClear
              style={{ marginTop: '8px', width: '140px' }}
              options={slaOptions}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="feedback"
            label="Feedback"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              // mode="multiple"
              placeholder="Selecione com ou sem"
              allowClear
              style={{ marginTop: '8px', width: '140px' }}
              options={feedbackOptions}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col>
          <Form.Item
            name="requestingAgent"
            label="Aberto por"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              // mode="multiple"
              allowClear
              style={{ marginTop: '8px', width: '280px' }}
              placeholder="Selecione o usuário"
            >
              {
                allUsers?.map(
                  (users: IUsersResponse) => (
                    <Select.Option key={users.id} value={users.id}>
                      {users.name}
                    </Select.Option>
                  ),
                )
              }
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="alocatedAgent"
            label="Realizado por"
            labelAlign="left"
            labelCol={{ span: 12 }}
          >
            <Select
              // mode="multiple"
              allowClear
              style={{ marginTop: '8px', width: '280px' }}
              placeholder="Selecione o usuário"
            >
              {
                allUsers?.map(
                  (users: IUsersResponse) => (
                    <Select.Option key={users.id} value={users.id}>
                      {users.name}
                    </Select.Option>
                  ),
                )
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <Button
            // shape="round"
            style={{
              border: '1px solid',
              color: '#f58220',
            }}
            onClick={onReset}
            icon={<ReloadOutlined />}
          >
            Limpar
          </Button> */}
        </Col>
      </Row>

      {children}
      {/* </Form> */}
    </Modal>
  );
};

export default FilterModal;
