import { AxiosError } from 'axios';
import { Moment } from 'moment';
import queryString from 'query-string';
import { IBSServiceResponse, SLAStatus } from '../../../components/ServicesTable/components/api';
import axios from '../../../providers/api';
import { IDepartment } from '../../../providers/auth';
import { IBSDepartmentResponse } from '../../DashBoardAgent/api';

export enum SupportSectors {
  Atendimento = 'Atendimento',
  Hotelaria = 'Hotelaria',
  Manutencao = 'Manutenção',
  Nutricao = 'Nutrição',
}

type Range<T> = [T, T];

export interface ILogFilters {
  [key: string]: string
  | number
  | Range<Date>
  | Range<number>
  | Range<Moment>
  | string[]
  | undefined;
  attendanceId?: string,
  activityId?: string,
  requestingAgent?: string,
  supportSector?: SupportSectors,
  requestTimestamp?: Range<Moment> | string[] | undefined;
  startTimestamp?: Range<Date>;
  approveTimestamp?: Range<Date>;
  acceptTimestamp?: Range<Date>;
  endTimestamp?: Range<Date>;
  archiveTimestamp?: Range<Date>;
  cancelTimestamp?: Range<Date>;
  suspendTimestamp?: Range<Date>;
  rating?: Range<number>;
  slaStatus?: SLAStatus;
  slaStartSuspensionTimestamp?: Range<Date>;
  slaTotalSuspensionTime?: Range<number>;
  createAt?: Range<Date>;
}

// TODO: Criar interfaces globais que são partilhadas para evitar código replicado e redundânte
interface IBSActivityResponse {
  id: string;
  name: string;
  createAt: Date | null;
  sla: {
    id: string;
  };
  category: {
    id: string;
    name: string;
  };
}

export interface IBSCategoryResponse {
  id: string,
  name: string,
  createAt: Date | null,
  department: {
    id: string,
    name: string,
    createAt: Date | null,
  },
  activities: Array<{
    id: string,
    name: string,
    createAt: Date | null,
    sla: {
      id: string,
      toAccept: number,
      toStart: number,
      toEnd: number
    }
  }>;
}

interface IAxiosSendLogRequest {
  status?: number,
  data: IBSServiceResponse[] | string,
}

export interface ICompanyResponse {
  id: string,
  name: string,
  createAt: Date | null,
}
export interface IDepartmentResponse {
  id: string,
  name: string,
  createAt: Date,
  company: {
    id: string,
    name: string,
  },
}
export interface ISectorResponse{
  id: string,
  name: string
}

export const sendLogRequest = async (logValues: ILogFilters): Promise<IAxiosSendLogRequest> => {
  try {
    let params = queryString.stringify({
      ...logValues, slaStatus: [SLAStatus.Arquivado, SLAStatus.Cancelado], order: 'DESC', withDeleted: true,
    });

    if (logValues.slaStatus) {
      params = queryString.stringify({
        ...logValues, order: 'DESC', withDeleted: true,
      });
    }
    const axiosResponse = await axios().get(`bsservice/?${params}`);
    const bsServiceData: IBSServiceResponse[] | string = axiosResponse.data;

    return {
      status: axiosResponse.status,
      data: bsServiceData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
      data: error.response?.data,
    };
  }
};

interface IAxiosGetAllCompanies {
  status?: number,
  data: ICompanyResponse[] | string,
}

export const getAllCompanies = async (): Promise<IAxiosGetAllCompanies> => {
  const axiosResponse = await axios().get('company/');
  const companyData: ICompanyResponse[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: companyData,
  };
};

export const getAllCategories = async (): Promise<IAxiosResponse<
| IBSCategoryResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('category/');
    const bsCategoryData: IBSCategoryResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsCategoryData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const getCategoriesByPermission = async (): Promise<IAxiosResponse<
| IBSCategoryResponse[]
>> => {
  try {
    const params = queryString.stringify({ withDeleted: true, permissions: true });
    const axiosResponse = await axios().get(`/category/?${params}`);
    const bsCategoryData: IBSCategoryResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsCategoryData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

// eslint-disable-next-line max-len
export const filterActivity = async (categoryId:string[], allActivities: IBSActivityResponse[]) :Promise<IBSActivityResponse[]> => {
  const activityList: IBSActivityResponse[] = [];
  categoryId.forEach((category) => {
    allActivities.filter((activity) => {
      if (activity.category.id === category) {
        activityList.push(activity);
        return true;
      }
      return false;
    });
  });
  return activityList;
};

export const getAllActivities = async (): Promise<IAxiosResponse<
| IBSActivityResponse[]
>> => {
  try {
    const params = queryString.stringify({ withDeleted: true });
    const axiosResponse = await axios().get(`/activity/?${params}`);
    const bsActivityData: IBSActivityResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsActivityData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const getCategories = async (departmentId: string): Promise<IAxiosResponse<
| IBSCategoryResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('category/', { params: { department: departmentId } });
    const bsCategoryData: IBSCategoryResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsCategoryData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const getActivities = async (categoryId: string): Promise<IAxiosResponse<
| IBSActivityResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('activity/', { params: { categoryId, withDeleted: true } });
    const bsActivityData: IBSActivityResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsActivityData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const getDepartments = async (companyId: string): Promise<IAxiosResponse<
| IBSDepartmentResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('department/', { params: { company: companyId } });
    const bsDepartmentData: IBSDepartmentResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsDepartmentData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export interface IBSSectorResponse{
  id: string;
  cd_leito: number;
  ds_leito: string;
  atendimento: number;
  cd_multi_empresa: number;
  cd_unid_int: string;
  situacao_unidade_internacao: string;
  unidade_internacao: string;
  ds_enfermaria: string;
  dt_ativacao: string;
  dt_desativacao: string;
  leito_extra: string;
  nr_ramal: string;
  situacao_leito: string;
  tipo_ocupacao: string;
}

export const getAllSectors = async (): Promise<IAxiosResponse<
| IBSSectorResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('sector/');
    const bsSectorData: IBSSectorResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsSectorData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const getAllBedRooms = async (): Promise<IAxiosResponse<
| IBSSectorResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('bedroom/');
    const bsBedroomData: IBSSectorResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsBedroomData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};

export const getBedrooms = async (bedroomId: string): Promise<IAxiosResponse<
| IBSSectorResponse[]
>> => {
  try {
    const axiosResponse = await axios().get('bedroom/', { params: { unidade_internacao: bedroomId } });
    const bsbedroomData: IBSSectorResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsbedroomData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
    };
  }
};
