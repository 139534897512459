import React, { useEffect, useState, useRef, HTMLAttributes, DetailedHTMLProps } from 'react';
import {
  Form,
  Input,
  Card,
  Divider,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Modal,
  Checkbox,
  Radio,
  Table,
  Alert,
} from 'antd';
import { AxiosError } from 'axios';
import { QuestionCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import Container from './styles';
import {
  agentPostingServiceRequest,
  getActivities,
  getAllCategories,
  IBSActivityResponse,
  IBSCategoryResponse,
  IAttendanceView,
  IAgentRequest,
  getPatientInfobyAttendance,
  getPatientInfobyLeito,
  IBedSearchByNameResponse,
  getPatientInfobyName,
  getBedInfoByName,
  getPatientHistory,
  IPatientHistoryRequest,
  IPatientHistoryResponse,
} from './api';
import responseStatus from '../../providers/responseStatus';
import { APINotificationSwitch } from '../../components/NotificationBS';
import { getAllDepartments } from '../AdminBS/components/CategoriesTable/api';
import { IBSDepartmentResponse } from '../DashBoardAgent/api';
import { useGeneralData } from '../../context/General';
import { getAllCompanies, ICompanyResponse } from '../BSLog/components/api';
import { checkStatusUnauthorized } from '../../providers/auth';

type Range<T> = [T, T];

const RequestForm: React.FC = () => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { allDepartments, setallDepartments } = useGeneralData();
  const { allCompanies, setallCompanies } = useGeneralData();

  let [selectedCompany, setselectedCompany] = useState<string | undefined>(undefined);
  const [allActivities, setAllActivities] = useState<IBSActivityResponse[]>([]);
  const [loadingActivities, setloadingActivities] = useState<boolean>(false);
  const [disableActivities, setdisableActivities] = useState<boolean>(false);

  const [allCategories, setAllCategories] = useState<IBSCategoryResponse[]>([]);
  const [loadingCategories, setloadingCategories] = useState<boolean>(false);
  const [disableCategories, setdisableCategories] = useState<boolean>(false);

  const [loadingDepartments, setloadingDepartments] = useState<boolean>(false);
  const [disableDepartments, setdisableDepartments] = useState<boolean>(false);

  const lastSearch = useRef<string>('');
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [leitoData, setleitoData] = useState<IBedSearchByNameResponse[] | null>(null);
  const [hasSelectedLeito, setHasSelectedLeito] = useState<boolean>(false);
  const [loadingDestination, setloadingDestination] = useState<boolean>(false);
  const [loadingRequest, setloadingRequest] = useState<boolean>(false);
  const [patientSearchData, setpatientSearchData] = useState<IAttendanceView[] | null>(null);
  const [hastPatientInfo, setHasPatientInfo] = useState<boolean>(false);
  const [hasPatientSelected, setHasPatientSelected] = useState<boolean>(false);

  const [duplicatedServiceVisible, setduplicatedServiceVisible] = useState<boolean>(false);
  const [forceCreateValue, setforceCreateValue] = useState<boolean>(false);
  const [patientHistory, setPatientHistory] = useState<IPatientHistoryResponse[]>();
  const [alertMsg, setAlertMsg] = useState<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> | null>();
  const [department, setDepartment] = useState<string>();

  const options = [
    { label: 'Sim', value: 'Sim' },
    { label: 'Não', value: 'Não' },
  ];

  const alert = (
    <Alert
      message="Para buscar o histórico insira o nome do paciente ou informe a identificação do leito, para ambos os casos pode-se usar o departamento como filtro."
      type="info"
      showIcon
      style={{
        width: '75%',
        backgroundColor: 'white',
        marginBottom: '1%',
        borderColor: 'whitesmoke',
      }}
    />
  );

  const handleAlert = () => {
    const destination = form.getFieldValue('destination');
    const name = form.getFieldValue('name');
    if (destination || name) {
      setAlertMsg(null);
    } else setAlertMsg(alert);
  };

  useEffect(() => {
    (async () => {
      try {
        const result = await getAllCompanies();
        setallCompanies(result.data as ICompanyResponse[]);
        fillDepartments((result.data as ICompanyResponse[])[0]?.id);
        setLoadingPage(true);
        handleAlert();
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [setallCompanies]);

  useEffect(() => {
    (async () => {
      try {
        const result = await getAllDepartments();
        setallDepartments(result.data as IBSDepartmentResponse[]);
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [setallDepartments]);

  const fillDepartments = async (companyId: string) => {
    setleitoData(null);
    setselectedCompany(companyId);
    setloadingDepartments(true);
    try {
      const result = await getAllDepartments(companyId);
      form.resetFields(['department', 'category', 'activityId']);
      setdisableCategories(false);
      setdisableActivities(false);
      setallDepartments(result.data as IBSDepartmentResponse[]);
      setloadingDepartments(false);
      setdisableDepartments(true);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
      setloadingDepartments(false);
    }
  };

  const fillAllCategories = async (departmentId: string) => {
    setloadingCategories(true);
    setDepartment(departmentId);
    try {
      const result = await getAllCategories(departmentId);
      form.resetFields(['category', 'activityId']);
      setdisableActivities(false);
      setAllCategories(result.data as IBSCategoryResponse[]);
      setloadingCategories(false);
      setdisableCategories(true);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
      setloadingCategories(false);
    }
  };

  const fillActivities = async (categoryId: string) => {
    setloadingActivities(true);
    try {
      const result = await getActivities(categoryId);
      setAllActivities(result.data as IBSActivityResponse[]);
      form.resetFields(['activityId']);
      setloadingActivities(false);
      setdisableActivities(true);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
      setloadingActivities(false);
    }
  };

  const onRequest = async (values: IAgentRequest) => {
    setloadingRequest(true);
    try {
      if (values.ds_leito.length <= 20) {
        const bedInfo = await getBedInfoByName(values.ds_leito as unknown as string, selectedCompany);
        const bedData = bedInfo.data as IBedSearchByNameResponse;
        // eslint-disable-next-line no-param-reassign
        values.ds_leito = bedData.id;
      }
      const serviceResponse = await agentPostingServiceRequest(values);
      const responseText = responseStatus(serviceResponse.status);
      APINotificationSwitch(responseText, 'Solicitação realizada com sucesso!');
      setallCompanies(allCompanies);
      form.resetFields();
      setPatientHistory([]);
      setHasPatientSelected(false);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      if (error.response?.status === 409) {
        setforceCreateValue(true);
        setduplicatedServiceVisible(true);
      }
      APINotificationSwitch(responseText, error.response?.data);
    }
    setloadingRequest(false);
  };

  const onReset = () => {
    setallCompanies(allCompanies);
    setHasPatientInfo(false);
    setHasPatientSelected(false);
    setPatientHistory([]);
    form.resetFields();
    handleAlert();
  };

  const fillPatientInfo = async (e: string | number) => {
    let atendimento: string;
    if (typeof e === 'string') {
      atendimento = e;
    } else {
      atendimento = JSON.stringify(e);
    }

    if (atendimento?.length >= 4) {
      setLoading(true);
      try {
        const patientInfo = await getPatientInfobyAttendance(atendimento, selectedCompany);
        setLoading(false);
        const patientData = patientInfo.data as IAttendanceView;
        form.setFieldsValue({
          name: patientData.nm_paciente,
          attendanceId: patientData.atendimento,
          birthDate: patientData.dt_nascimento,
          destination: patientData.ds_leito,
          accompanying: patientData.accompanying,
          // ds_leito: patientData.ds_leito,
        });
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
        setLoading(false);
      }
    }
  };

  const fillDestinationInfo = async (dsLeito: string) => {
    if (dsLeito.length >= 3) {
      lastSearch.current = dsLeito;

      setTimeout(async () => {
        try {
          setloadingDestination(true);
          if (dsLeito === lastSearch.current) {
            const leitoInfo = await getPatientInfobyLeito(dsLeito, selectedCompany);
            const leitoDataResponse = leitoInfo.data as unknown as IBedSearchByNameResponse[];
            const leitosAtivos = leitoDataResponse?.filter((leito) => leito.situacao_leito.includes('Ativo'));
            setleitoData(leitosAtivos);
          }
          setloadingDestination(false);
        } catch (BsErr) {
          const error = BsErr as AxiosError;
          checkStatusUnauthorized(error.response?.status);
          const responseText = responseStatus(error.response?.status);
          APINotificationSwitch(responseText, error.response?.data);
          setloadingDestination(false);
        }
      }, 800);
    }
  };

  const fillPatientName = async (patientName: string) => {
    if (patientName.length >= 3) {
      lastSearch.current = patientName;

      if (selectedCompany === undefined) {
        const companySelected = form.getFieldValue('companies');
        const filterCompany = allCompanies.filter((companie) => companie.name.includes(companySelected));
        selectedCompany = filterCompany[0].id;
      }

      setTimeout(async () => {
        try {
          setloadingDestination(true);
          if (patientName === lastSearch.current) {
            const patientInfo = await getPatientInfobyName(patientName, selectedCompany);
            const patientDataResponse = patientInfo.data as unknown as IAttendanceView[];
            setpatientSearchData(patientDataResponse);
          }
          setloadingDestination(false);
        } catch (BsErr) {
          const error = BsErr as AxiosError;
          checkStatusUnauthorized(error.response?.status);
          const responseText = responseStatus(error.response?.status);
          APINotificationSwitch(responseText, error.response?.data);
          setloadingDestination(false);
        }
      }, 800);
    }
  };

  const fillPatientHistory = async (query: IPatientHistoryRequest) => {
    const { bed, attendanceId } = query;
    const now = new Date();
    const period = new Date('1969-12-31');
    const newQuery = {
      bed,
      attendanceId,
      department,
      requestTimestamp: [period, now] as Range<Date>,
    };
    const patientHistoryData = await getPatientHistory(newQuery);
    setPatientHistory(patientHistoryData.data as unknown as IPatientHistoryResponse[]);
    handleAlert();
  };

  const onSelectPatientName = async (attendanceId: number): Promise<void> => {
    let atendimento: string;
    if (typeof attendanceId === 'string') {
      atendimento = attendanceId;
    } else {
      atendimento = JSON.stringify(attendanceId);
    }
    const query = {
      attendanceId: attendanceId.toString(),
    };
    fillPatientHistory(query);
    if (atendimento?.length >= 4) {
      setLoading(true);
      try {
        const patientInfo = await getPatientInfobyAttendance(atendimento, selectedCompany);
        const bedSelected = form.getFieldValue('ds_leito');
        const bedFiltered = leitoData?.find((leito) => leito.id.includes(bedSelected));
        const bedInfo = await getPatientInfobyLeito(bedFiltered?.ds_leito ? bedFiltered.ds_leito : ' ', selectedCompany);
        const bedResponse = bedInfo.data as unknown as IBedSearchByNameResponse[];
        let correctBed;
        if (bedResponse.length > 1) {
          correctBed = bedResponse.filter((bed) => bed.situacao_leito.includes('Ativo'));
          if (correctBed.length > 1) {
            correctBed = [];
          }
        }
        const hasUnit = form.getFieldValue('patientUnit');
        setLoading(false);
        const patientData = patientInfo.data as IAttendanceView;
        if (!hasSelectedLeito) {
          form.setFieldsValue({
            name: patientData.nm_paciente,
            attendanceId: patientData.atendimento,
            birthDate: patientData.dt_nascimento,
            // destination: patientData.ds_leito,
            ds_leito: patientData.ds_leito ? patientData.ds_leito : bedSelected,
            accompanying: patientData.accompanying,
            patientUnit: hasUnit || patientData.unidade_internacao,
          });
        } else {
          form.setFieldsValue({
            name: patientData.nm_paciente,
            attendanceId: patientData.atendimento,
            birthDate: patientData.dt_nascimento,
            accompanying: patientData.accompanying,
            patientUnit: hasUnit || patientData.unidade_internacao,
          });
        }
        setHasPatientInfo(true);
        setHasPatientSelected(true);
      } catch (BsErr) {
        setHasPatientInfo(false);
        setHasPatientSelected(false);
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
        setLoading(false);
      }
    }
  };

  const onSelectDestination = async (val: string): Promise<void> => {
    if (val) {
      const destination = leitoData?.find((d) => d.id === val);
      if (destination) {
        if (destination.atendimento) {
          fillPatientInfo(destination?.atendimento);
          setHasPatientInfo(true);
          setHasPatientSelected(true);
        }
        setHasSelectedLeito(true);

        const bedSelected = form.getFieldValue('ds_leito');
        const bedFiltered = leitoData?.find((leito) => leito.id.includes(bedSelected));
        const bedInfo = await getPatientInfobyLeito(bedFiltered?.ds_leito ? bedFiltered.ds_leito : ' ', selectedCompany);
        const bedResponse = bedInfo.data as unknown as IBedSearchByNameResponse[];
        let correctBed;
        if (bedResponse.length > 1) {
          correctBed = bedResponse.find((bed) => bed.situacao_leito.includes('Ativo'));
        }
        form.setFieldsValue({
          patientUnit: bedResponse.length > 1 ? correctBed?.unidade_internacao : bedResponse[0].unidade_internacao,
        });
        setHasSelectedLeito(true);
        setHasPatientInfo(true);
        const query = {
          bed: destination.ds_leito,
        };
        fillPatientHistory(query);
      }
    }
  };

  const handleDuplicatedServiceCancel = () => {
    setforceCreateValue(false);
    setduplicatedServiceVisible(false);
  };

  const handleDuplicatedServiceOk = () => {
    form.submit();
    setduplicatedServiceVisible(false);
  };

  const leitoOnClear = () => {
    setHasPatientInfo(true);
    setHasPatientSelected(false);
    setHasSelectedLeito(false);
    // form.resetFields(['attendanceId', 'name', 'birthDate', 'patientUnit', 'accompanying']);
  };

  const patientHistoryColumns = [
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      width: '10%',
    },
    {
      title: 'Hora',
      dataIndex: 'hour',
      key: 'hour',
      width: '8%',
    },
    {
      title: 'Leito',
      dataIndex: 'bed',
      key: 'bed',
      width: '8%',
    },
    {
      title: 'Atividade',
      dataIndex: 'activity',
      key: 'activity',
      width: '26%',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      width: '50%',
    },
  ];

  return (
    <Container>
      <h1>Solicitações - Agente</h1>
      <Card>
        {loadingPage && (
        <Form
          form={form}
          name="agente_request"
          layout="vertical"
          onFinish={(values) => onRequest({
            activityId: values.activityId,
            attendanceId: values.attendanceId,
            description: values.description,
            ds_leito: values.ds_leito,
            name: values.name,
            patientUnit: values.patientUnit,
            birthDate: values.birthDate,
            forceCreate: forceCreateValue,
          })}
        >
          <Spin spinning={loading}>
            <div>{alertMsg}</div>
            <Row>
              <Form.Item
                name="companies"
                label="Hospital: "
                rules={[
                  {
                    required: true,
                    message: 'Por favor, selecione uma empresa!',
                  },
                ]}
                initialValue={allCompanies[0]?.name}
              >
                <Select
                  showSearch
                  placeholder="Empresa"
                  style={{ width: '175px' }}
                  onChange={fillDepartments}
                >
                  {allCompanies?.map((company) => (
                    <Select.Option key={company.id} value={company.id}>
                      {company.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Col span={1} />
              <Spin spinning={loadingDepartments}>
                <Form.Item
                  label="Departamento:"
                  name="department"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione uma departamento!',
                    },
                  ]}
                >
                  <Select
                    disabled={!disableDepartments}
                    showSearch
                    placeholder="Departamento"
                    style={{ width: '175px' }}
                    onChange={fillAllCategories}
                  >
                    {allDepartments?.map((dep) => (
                      <Select.Option key={dep.id} value={dep.id}>
                        {dep.name.split(` ${dep.company.name}`)}
                      </Select.Option>
                    ))}

                  </Select>
                </Form.Item>
              </Spin>
              <Col span={1} />
              <Spin spinning={loadingCategories}>
                <Form.Item
                  name="category"
                  label="Categoria: "
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione uma categoria!',
                    },
                  ]}
                >
                  <Select
                    disabled={!disableCategories}
                    showSearch
                    placeholder="Categoria"
                    style={{ width: '175px' }}
                    onChange={fillActivities}
                  >
                    {allCategories?.map((category) => (
                      <Select.Option key={category.id} value={category.id}>
                        {category.name}
                      </Select.Option>
                    ))}

                  </Select>
                </Form.Item>
              </Spin>

              <Col span={1} />

              <Spin spinning={loadingActivities}>
                <Form.Item
                  name="activityId"
                  label="Atividade: "
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione uma atividade!',
                    },
                  ]}
                >

                  <Select
                    disabled={!disableActivities}
                    showSearch
                    placeholder="Atividade"
                    style={{ width: '175px' }}
                  >
                    {allActivities?.map((activity) => (
                      <Select.Option key={activity.id} value={activity.id}>
                        {activity.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Spin>
            </Row>

            <Divider />
            <h2>Dados do paciente:</h2>
            <>
              <Row>
                <Form.Item
                  label="Leito: "
                  name="ds_leito"
                  tooltip={{
                    title: 'Trazer as informações do paciente a partir do leito informado',
                    icon: <QuestionCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Digite o leito a ser pesquisado!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={fillDestinationInfo}
                    onSelect={onSelectDestination}
                    onChange={leitoOnClear}
                    placeholder="Pesquisar por Leito"
                    style={{ width: '180px' }}
                    allowClear
                    onClear={leitoOnClear}
                    loading={loadingDestination}
                    optionFilterProp="children"
                  >
                    {leitoData?.map((leito) => (
                      <Select.Option key={leito.id} value={leito.id}>
                        {leito.ds_leito}
                      </Select.Option>
                    ))}

                  </Select>
                </Form.Item>

                <Col span={1} />

                <Form.Item
                  name="name"
                  label="Nome Completo do Paciente: "
                  tooltip={{
                    title: 'Trazer as informações do paciente a partir do nome',
                    icon: <QuestionCircleOutlined />,
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Digite o paciente a ser pesquisado!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={fillPatientName}
                    onSelect={onSelectPatientName}
                    onChange={leitoOnClear}
                    loading={loadingDestination}
                    placeholder="Nome completo"
                    optionFilterProp="children"
                  >
                    {patientSearchData?.map((patient) => (
                      <Select.Option key={patient.atendimento} value={patient.atendimento}>
                        {patient.nm_paciente}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Col span={12} />

                <Form.Item
                  name="attendanceId"
                  label="Número de atendimento: "
                >
                  <Input disabled={hastPatientInfo} placeholder="N° atendimento" />
                </Form.Item>

                <Col span={1} />
                <Form.Item
                  name="birthDate"
                  label="Data de nascimento: "
                >
                  <Input disabled={hastPatientInfo} placeholder="Data de nascimento" />
                </Form.Item>

                <Col span={1} />
                <Form.Item
                  name="patientUnit"
                  label="Unidade de internação: "
                >
                  <Input disabled={hastPatientInfo} placeholder="Unidade de Internação" />
                </Form.Item>

                <Col span={1} />
                <Form.Item
                  name="accompanying"
                  label="Acompanhante: "
                >
                  <Input disabled placeholder="Cobertura de acompanhante" />
                </Form.Item>
              </Row>

              <Row>
                <div
                  style={{
                    width: '90%',
                  }}
                >

                  <h3>Histórico de Pedidos do Paciente</h3>
                  <Table
                    dataSource={patientHistory}
                    columns={patientHistoryColumns}
                    rowKey="id"
                    pagination={false}
                    scroll={{
                      y: 220,
                    }}
                  />
                </div>
              </Row>

              <Form.Item
                label="Descrição: "
                name="description"
                style={{
                  marginTop: '2%',
                }}
              >
                <TextArea placeholder="Descrição do serviço a ser realizado e possíveis observações" />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{
                    backgroundColor: '#f58220',
                    color: '#FFF',
                    marginRight: '20px',
                  }}
                  loading={loadingRequest}
                  onClick={form.submit}
                >
                  Requisitar
                </Button>
                <Button
                  style={{
                    border: '1px solid',
                    color: '#f58220',
                    marginRight: '20px',
                  }}
                      // shape="round"
                  onClick={onReset}
                  icon={<ReloadOutlined />}
                >
                  Limpar
                </Button>
              </Form.Item>
            </>
          </Spin>
        </Form>
        )}
      </Card>

      <Modal
        title="Confirmar serviço"
        visible={duplicatedServiceVisible}
        onOk={handleDuplicatedServiceOk}
        onCancel={handleDuplicatedServiceCancel}
      >
        {/* eslint-disable-next-line max-len */}
        <p>Verificamos que há um serviço em andamento que apresenta os mesmos parâmetros que o serviço que está sendo requisitado.</p>
        <strong>Deseja solicitar mesmo assim?</strong>
      </Modal>
    </Container>
  );
};

export default RequestForm;
