import * as ax from 'axios';

const TOKEN_KEY = 'token';

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

const axios = (): ax.AxiosInstance => ax.default.create({
  baseURL: process.env.REACT_APP_APIROUTE,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Authorization: getToken(),
  },
  withCredentials: true,
});

export default axios;
