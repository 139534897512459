/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, {
  useEffect, useState, useCallback,
} from 'react';
import {
  Button, Card, Col, Form, Row, Select, Spin, Tabs,
} from 'antd';
import { AxiosError } from 'axios';
import { ReloadOutlined } from '@ant-design/icons';
import { ContainerTables, ContainerAllTable } from './styles';
import {
  managerPermissions, brasiliaManagerPermission, permissionChecker,
} from '../../providers/rolePermissions';
import { APINotificationSwitch, NotificationError } from '../../components/NotificationBS';
import {
  getManagingPendingServices, IBSDepartmentResponse, sendDashboardRequest, getDepartmentByUser,
} from './api';
import { useGeneralData } from '../../context/General';
import responseStatus from '../../providers/responseStatus';
import ServicesTable from '../../components/ServicesTable';
import {
  checkStatusUnauthorized, getAuthDepartments, getAuthRoles, IDepartment,
} from '../../providers/auth';
import { IBSServiceResponse, SLAStatus } from '../../components/ServicesTable/components/api';
import AllTable from '../../components/AllTables';
import Container from '../../components/ServicesTable/styles';
import { getAllActivities, ILogFilters } from '../BSLog/components/api';
import { IBSActivityResponse } from '../RequestForm/api';
import { getAllCategories, IBSCategoryResponse } from '../AdminBS/api';

const { TabPane } = Tabs;

const DashBoardAgent: React.FC = () => {
  const [form] = Form.useForm();
  const { managingPending, setmanagingPending } = useGeneralData();
  const { setallDepartments } = useGeneralData();
  const [departments, setDepartments] = useState<IDepartment[]>([]);
  const [activites, setActivites] = useState<IBSActivityResponse[]>([]);
  const [allCategories, setallCategories] = useState<IBSCategoryResponse[]>();
  const [fetchData, setFetchData] = useState<boolean>(true);
  const [serverTimeResponse, setserverTimeResponse] = useState<number | undefined>(0);
  const [alldepartmentsFromLogin, setalldepartmentsFromLogin] = useState<IBSDepartmentResponse[]>();
  const [fetchingResponse, setFetchingResponse] = useState<boolean>(false);
  const [serviceData, setServiceData] = useState<IBSServiceResponse[]>([]);

  const filterServicesBySLAStatus = (
    serviceStatus: SLAStatus,
    services: IBSServiceResponse[],
  ) => services.filter((s) => s.slaStatus === serviceStatus);

  const fillActivities = async () => {
    try {
      const result = await getAllActivities();
      setActivites(result.data as IBSActivityResponse[]);
    } catch (BSErr) {
      const error = BSErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const fillCategories = async () => {
    try {
      const result = await getAllCategories();
      setallCategories(result.data as IBSCategoryResponse[]);
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  useEffect(() => {
    try {
      const departaments = getAuthDepartments();
      setDepartments(departaments);
      fillActivities();
      fillCategories();
    } catch (err) {
      APINotificationSwitch('SERVERERROR');
      console.error(err);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const result = await getDepartmentByUser();
        if (result.data && typeof result.data !== 'string') {
          const allDepartmentsFromLogin = result.data.map((dept) => dept);
          const duplicatedDepartments = allDepartmentsFromLogin.map((dept) => {
            const deptArray = dept.name.split(' ');
            deptArray.pop();
            dept.name = deptArray.join(' ');
            return dept;
          });

          const nonDuplicateDepartments = duplicatedDepartments.filter(
            (item, index) => index === duplicatedDepartments.findIndex((itemDup) => itemDup.name === item.name),

            // duplicatedDepartments.values.name.indexOf(item) === index,
          );

          setalldepartmentsFromLogin(nonDuplicateDepartments);
          setallDepartments(result.data);
        }
      } catch (BsErr) {
        const error = BsErr as AxiosError;
        checkStatusUnauthorized(error.response?.status);
        const responseText = responseStatus(error.response?.status);
        APINotificationSwitch(responseText, error.response?.data);
      }
    })();
  }, [setallDepartments]);

  const filterServices = () => {
    setFetchingResponse(true);
    form.submit();
    setFetchingResponse(false);
  };

  const onRequest = async (values: ILogFilters) => {
    const fieldValues = values;
    try {
      const result = await sendDashboardRequest(fieldValues);
      if (result.data && typeof result.data !== 'string') {
        const pendingServices = filterServicesBySLAStatus(SLAStatus.AguardandoAceite, result.data);
        const waitingServices = filterServicesBySLAStatus(SLAStatus.AguardandoInicio, result.data);
        const runningServices = filterServicesBySLAStatus(SLAStatus.EmAndamento, result.data);
        const finishedServices = filterServicesBySLAStatus(SLAStatus.Finalizado, result.data);
        const suspendedServices = filterServicesBySLAStatus(SLAStatus.Suspenso, result.data);
        // eslint-disable-next-line max-len
        setServiceData([...pendingServices, ...runningServices, ...finishedServices, ...suspendedServices, ...waitingServices]);
      }
    } catch (RequestError) {
      NotificationError('Houve um erro inesperado.', 'Tente Novamente. Se o erro persistir, favor contatar o setor de TI.');
    }
  };

  const onReset = () => {
    form.resetFields();
    filterServices();
  };
  const fillManagingPending = useCallback(() => {
    setFetchData(true);
    getManagingPendingServices().then((pendingService) => {
      if (typeof pendingService.data !== 'string') {
        const { data, serverTime } = pendingService;
        let offset;
        if (serverTime) {
          const serverTimeMillisGMT = new Date(serverTime).getTime();
          const localMillisUTC = new Date().getTime();
          offset = serverTimeMillisGMT - localMillisUTC;
        }
        const serviceDepartment = data.map((service) => {
          const deptName = service.department.name.split(' ');
          service.company.name = deptName.pop() || '';
          service.department.name = deptName.join(' ');
          return service;
        });
        setServiceData(data);
        setmanagingPending(serviceDepartment);
        setserverTimeResponse(offset);
        setFetchData(false);
      }
    }).catch((BsErr) => {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    });
  }, [setmanagingPending]);

  useEffect(() => {
    const delayFillManaging = setTimeout(() => fillManagingPending(), 3000);

    const intFillManagin = setInterval(() => {
      fillManagingPending();
    }, 20000);
    return () => {
      clearInterval(intFillManagin);
      clearTimeout(delayFillManaging);
    };
  }, [fillManagingPending, form]);

  return (
    permissionChecker(getAuthRoles(), [managerPermissions, brasiliaManagerPermission])
      ? (
        <>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Central de Serviços" key="1">
              <ContainerTables>
                {managingPending
                  ? (
                    alldepartmentsFromLogin?.map((dept) => (
                      <ServicesTable
                        tableTitle={dept.name}
                        loading={fetchData}
                        color={dept.color}
                        departmentData={
                          managingPending.filter((service) => service.department.name === dept.name)
                        }
                        serverTime={serverTimeResponse}
                        key={dept.name}
                      />
                    ))
                  ) : (
                    <Spin size="large" />
                  )}
              </ContainerTables>
            </TabPane>
            <TabPane tab="Todos" key="2">
              <Container>
                <h3>Filtrar por:</h3>
                <Card>
                  <Form
                    layout="vertical"
                    name="filter_services"
                    form={form}
                    onFinish={onRequest}
                  >
                    <Row>
                      <Form.Item name="department" label="Departamentos:">
                        <Select
                          showSearch
                          placeholder="Departamentos"
                          style={{ width: '240px' }}
                        >
                          {departments?.map((company) => (
                            <Select.Option key={company.id} value={company.id}>
                              {company.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Col span={1} />
                      <Form.Item name="categoryId" label="Categorias:">
                        <Select
                          showSearch
                          placeholder="Categorias"
                          style={{ width: '240px' }}
                        >
                          {allCategories?.map((category) => (
                            <Select.Option key={category.id} value={category.id}>
                              {category.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Col span={1} />
                      <Form.Item name="activity" label="Atividades:">
                        <Select
                          showSearch
                          placeholder="Atividades"
                          style={{ width: '240px' }}
                        >
                          {activites?.map((activity) => (
                            <Select.Option key={activity.id} value={activity.id}>
                              {activity.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Col span={1} />
                      <Button
                        style={{
                          backgroundColor: '#f58220',
                          color: '#fff',
                          marginTop: '29px',
                        }}
                        onClick={filterServices}
                        loading={fetchingResponse}
                      >
                        Buscar
                      </Button>
                      <Button
                        style={{
                          color: '#f58220',
                          marginTop: '29px',
                        }}
                        onClick={onReset}
                        icon={<ReloadOutlined />}
                      >
                        Limpar
                      </Button>
                    </Row>
                  </Form>
                </Card>
              </Container>
              <ContainerAllTable>
                {serviceData
                  ? (
                    <AllTable
                      allData={managingPending}
                      loading={fetchingResponse}
                      color="FFFFFF"
                      serverTime={serverTimeResponse}
                      key="Todos"
                    />
                  ) : (
                    <Spin size="large" />
                  )}
              </ContainerAllTable>
            </TabPane>
          </Tabs>
        </>
      )
      : (
        <>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Central de Serviços" key="1">
              <ContainerAllTable>
                {serviceData
                  ? (
                    <AllTable
                      allData={serviceData}
                      loading={fetchData}
                      color="FFFFFF"
                      serverTime={serverTimeResponse}
                      key="Todos"
                    />
                  ) : (
                    <Spin size="large" />
                  )}
              </ContainerAllTable>
            </TabPane>
          </Tabs>
        </>
      )
  );
};

export default DashBoardAgent;
