import React from 'react';
import { Table, Tag } from 'antd';
import Container from './styles';
import { IUsersResponse } from '../../pages/UserManager/api';

interface IAgentsTable {
  tableTitle: string,
  agentData: IUsersResponse[] | undefined,
  containerStyle?: React.CSSProperties,
  isLog?: boolean,
  loading?: boolean,
  serverTime?: number;
  color?: string;
}

const AgentsTable: React.FC<IAgentsTable> = ({
  tableTitle,
  agentData,
  containerStyle,
  loading,
  color,
}: IAgentsTable) => {
  const columns = [
    {
      title: 'Hospital',
      dataIndex: ['company', 'name'],
      align: 'center' as const,
      key: 'company',
    },
    {
      title: 'Função',
      dataIndex: ['jobs', '0', 'job'],
      align: 'center' as const,
      key: 'job',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      align: 'center' as const,
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'lastSeenAt',
      align: 'center' as const,
      key: 'company',
      render: (lastSeenAt: string, row: IUsersResponse) => {
        const nowDate = new Date();
        const lastSeenAtFormmatted = new Date(lastSeenAt);

        const diffInMillisec = (nowDate.getTime() - lastSeenAtFormmatted.getTime()) / 1000;
        const diffInSec = Math.abs(diffInMillisec).toFixed(0);
        const diffInt = parseInt(diffInSec);

        if (lastSeenAt !== null && diffInt <= 43200) {
          return <span>Online</span>;
          // else { return <span>Inativo</span>; }
        }
      },

    },
  ];

  return (
    <Container
      style={containerStyle = { backgroundColor: color }}
    >
      <h1>{tableTitle}</h1>
      <div style={{
        overflowX: 'auto',
        height: '200px',
      }}
      >
        <Table
          pagination={false}
          columns={columns}
          size="small"
          loading={loading || false}
          rowKey="id"
          dataSource={agentData}
        />
      </div>
    </Container>
  );
};

export default AgentsTable;
