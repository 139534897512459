import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 15px;
`;

export const NPSContainer = styled.div`
  display: flex;
  font-size: 25px;
  text-align: center;
  line-height: normal;
  justify-content: space-around;

  > span {
    cursor: pointer;
  }
`;
