import React, { useState } from 'react';
import Activities from './components/Activities';
import CategoriesTable from './components/CategoriesTable';

const AdminBS: React.FC = () => {
  const [categoryId, setcategoryId] = useState<string | undefined>();

  const handleCategoryId = (id: string) => {
    setcategoryId(id);
  };

  return (
    <>
      <h1>Categoria e Atividades</h1>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <CategoriesTable rowClick={handleCategoryId} />
        <Activities categoryId={categoryId} />
      </div>
    </>
  );
};

export default AdminBS;
