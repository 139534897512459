import { Moment } from 'moment';
import axios from '../../providers/api';

type Range<T> = [T, T];

export interface IPatientHistoryRequest {
  bed?: string;
  attendanceId?: string;
  department?: string;
  requestTimestamp?: number
  | Moment
  | Range<Date>
  | Range<Moment>
}

export interface IPatientHistoryResponse {
  id?: string,
  data?: string,
  hour?: string,
  bed?: string | null,
  activity?: string,
  description?: string | null,
}

export interface IAgentRequest {
  activityId: number;
  attendanceId: number;
  description: string;
  forceCreate: boolean;
  ds_leito: string;
  name: string;
  patientUnit: string;
  birthDate: string;
}

export interface IBSActivityResponse {
  id: string;
  name: string;
  createAt: Date | null;
  sla: {
    id: string;
  };
  category: {
    id: string;
    name: string;
  };
}

export interface IBSCategoryResponse {
  id: string;
  name: string;
  createAt: Date | null;
  department: {
    id: string;
    name: string;
    createAt: Date | null;
  };
  activities: {
    id: string;
    name: string;
    createAt: Date | null;
  }[];
}

export interface IBSReadByPhone {
  patientId: number;
  attendanceId: number;
  cpf: string;
  fatherName: string;
  motherName: string;
  patientBirth: Date;
  patientName: string;
  createAt: Date;
  Destination: {
    id: number;
    name: string;
    phone: string;
  };
  Sector: {
    id: number;
    name: string;
  };
}

export interface IAttendanceView {
  id: string,
  atendimento: number;
  nm_paciente?: string | null;
  dt_nascimento?: string | null;
  unidade_internacao?: string | null;
  ds_leito?: string | null;
  accompanying?: string | null;
}

export interface IBSServiceResponse {
  id: string;
  description: string;
  requestTimestamp: Date | null;
  approveTimestamp: Date | null;
  rejectTimestamp: Date | null;
  acceptTimestamp: Date | null;
  startTimestamp: Date | null;
  endTimestamp: Date | null;
  archiveTimestamp: Date | null;
  cancelTimestamp: Date | null;
  cancelReason: string;
  rating: number;
  slaStatus: string;
  slaStartSuspensionTimestamp: Date | null;
  slaTotalSuspensionTime: number;
  createAt: Date | null;
  activity: {
    id: string;
  };
  attendance: {
    id: string;
  };
  sla: {
    id: string;
  };
}

export const getPatientInfobyAttendance = async (
  attendanceId: string | number,
  companyId?: string,
): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  const axiosResponse = await axios().get('attendance/',
    {
      params: { atendimento: attendanceId, companyId, discharged: false },
    });
  const bsPatientInfo: IAttendanceView | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsPatientInfo,
  };
};

export interface IBedSearchByNameResponse {
  id: string,
  ds_leito: string,
  atendimento: number,
  situacao_leito: string,
  tipo_ocupacao: string,
  unidade_internacao: string,
}

export const getPatientInfobyLeito = async (
  leito: string,
  companyId?: string,
): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  const axiosResponse = await axios().get('bed/searchByName/',
    {
      params: { ds_leito: leito, companyId },
    });

  const bsLeitoInfo: IBedSearchByNameResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsLeitoInfo,
  };
};

export const getBedInfoByName = async (
  leito: string,
  companyId?: string,
): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  const axiosResponse = await axios().get('bed/search/byPatient',
    {
      params: { ds_leito: leito, companyId },
    });
  const bsLeitoInfo: IBedSearchByNameResponse | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsLeitoInfo,
  };
};

export const getPatientInfobyName = async (
  patientName: string,
  companyId?: string,
): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  const axiosResponse = await axios().get('attendance/search/byPatient',
    {
      params: { nm_paciente: patientName, companyId },
    });
  const bsPatientInfo: IAttendanceView | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsPatientInfo,
  };
};

export const agentPostingServiceRequest = async (
  createServiceBody: IAgentRequest,
): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  let axiosResponse = null;
  const serviceBody = createServiceBody;
  console.log(serviceBody);
  try {
    if (serviceBody.description === undefined) {
      serviceBody.description = '-';
    }
    console.log(serviceBody);
  } finally {
    axiosResponse = await axios().post('bsservice/', serviceBody);
    const bsAgentPostingService: IBSServiceResponse | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsAgentPostingService,
    };
  }
};

export const getActivities = async (category: string): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  const axiosResponse = await axios().get('activity/', {
    params: {
      category,
    },
  });
  const bsActivityData: IBSActivityResponse[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsActivityData,
  };
};

export const getAllCategories = async (departmentId: string): Promise<IAxiosResponse<
IBSReadByPhone
| IBSServiceResponse
| IBSActivityResponse[]
| IBSCategoryResponse[]
| IAttendanceView
>> => {
  const axiosResponse = await axios().get('category/', {
    params: {
      department: departmentId,
    },
  });
  const bsCategoryData: IBSCategoryResponse[] | string = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsCategoryData,
  };
};

export const getPatientHistory = async (query: IPatientHistoryRequest): Promise<IAxiosResponse<
| IPatientHistoryResponse[]
>> => {
  const axiosResponse = await axios().get('patientHistory/', {
    params: {
      ...query,
    },
  });
  const bsServiceData = axiosResponse.data;
  return {
    status: axiosResponse.status,
    data: bsServiceData,
  };
};
