import { AxiosError } from 'axios';
import { IBSServiceResponse } from '../../components/ServicesTable/components/api';
import axios from '../../providers/api';
import { getAuthCompanyId } from '../../providers/auth';

interface IAxiosGetOnGoingServices {
  status?: number,
  serverTime?: string;
  data: IBSServiceResponse[] | string,
}

export const getOnGoingServices = async (): Promise<IAxiosGetOnGoingServices> => {
  const axiosResponse = await axios().get('/bsservice/ongoing', {
    params: {
      companyId: getAuthCompanyId(),
    },
  });
  const bsServiceData: IBSServiceResponse[] | string = axiosResponse.data;
  const serverTime: string = axiosResponse.headers.date;
  return {
    status: axiosResponse.status,
    serverTime,
    data: bsServiceData,
  };
};

export interface ISLAResponse {
  id: string,
  toAccept: number,
  toStart: number,
  toEnd: number,
  createAt: Date | null,
  company: {
    id: string
  }
}

interface IAxiosSLA {
  status?: number,
  data: ISLAResponse[] | string,
}

export const getAllSla = async (): Promise<IAxiosSLA> => {
  try {
    const axiosResponse = await axios().get('/sla/');
    const bsSLAData: ISLAResponse[] | string = axiosResponse.data;
    return {
      status: axiosResponse.status,
      data: bsSLAData,
    };
  } catch (BsErr) {
    const error = BsErr as AxiosError;
    return {
      status: error.response?.status,
      data: error.response?.data,
    };
  }
};

export const slaExample = [
  {
    safe: '5',
    risk: '10',
    expired: '15',
  },
];
