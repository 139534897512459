import React, { useState } from 'react';
import {
  FrownTwoTone, MehTwoTone, SmileTwoTone,
} from '@ant-design/icons';
import {
  Col, Descriptions, Input, Modal, Popconfirm, Row,
} from 'antd';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Container, NPSContainer } from './styles';
import { ratingColors } from '../../styles/GlobalStyles';

export interface IRatingProps {
  onRatingComplete: (ratingValue: number, feedback?: string) => void;
  rate?: number | null;
}

interface IRatingFace {
  component: React.ForwardRefExoticComponent<Pick<AntdIconProps, 'twoToneColor' | 'onClick'>>,
  color: string,
  rating: number,
  message: string,
}

const Rating: React.FC<IRatingProps> = (props: IRatingProps) => {
  const [feedbackVisible, setfeedbackVisible] = useState(false);
  const [ratingValue, setratingValue] = useState(-1);
  const [feedbackText, setfeedbackText] = useState<string>();

  const feedbackModal = () => {
    setfeedbackText('');
    setfeedbackVisible(true);
  };
  const ratingHandler = (rating: number) => {
    setratingValue(rating);
    feedbackModal();
  };

  const RatingFace: React.FC<IRatingFace> = (faceProps) => {
    const Face = faceProps.component;
    return (
      <Col>
        <Face twoToneColor={faceProps.color} onClick={() => ratingHandler(faceProps.rating)} />
        {` ${faceProps.message}`}
      </Col>
    );
  };

  const { TextArea } = Input;

  return (
    <Container style={{ fontWeight: 'bold' }}>

      <Row>
        <Col span={7} style={{ fontSize: '18px' }}>
          <Descriptions.Item>
            Avalie o Serviço
          </Descriptions.Item>
        </Col>
        <Col span={7} style={{ fontSize: '18px' }}>
          <Descriptions.Item>
            <RatingFace component={FrownTwoTone} color={ratingColors.detractor} message="Insatisfeito" rating={1} />
          </Descriptions.Item>
        </Col>
        <Col span={5} style={{ fontSize: '18px' }}>
          <Descriptions.Item>
            <RatingFace component={MehTwoTone} color={ratingColors.passive} message="Indiferente" rating={5} />
          </Descriptions.Item>
        </Col>
        <Col span={4} style={{ fontSize: '18px' }}>
          <Descriptions.Item>
            <RatingFace component={SmileTwoTone} color={ratingColors.promotors} message="Satisfeito" rating={10} />
          </Descriptions.Item>
        </Col>
      </Row>

      <Modal
        title="Feedback da Avaliação"
        visible={feedbackVisible}
        onOk={() => props.onRatingComplete(ratingValue, feedbackText ? feedbackText as string : '')}
        okText="Enviar"
        onCancel={() => setfeedbackVisible(false)}
        centered
      >
        <TextArea onChange={(e) => setfeedbackText(e.target.value)} placeholder="Mantenha em branco caso não haja feedback" />
      </Modal>
    </Container>
  );
};

export default Rating;
