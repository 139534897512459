import React, {
  useState, useCallback, forwardRef, useImperativeHandle, useRef,
} from 'react';
import {
  Modal, Button, Popover, Input, Descriptions, Row, Col, Tag, Card, Divider,
} from 'antd';
import moment from 'moment';
import {
  CheckOutlined, CloseOutlined, FrownTwoTone, MehTwoTone, SmileTwoTone,
} from '@ant-design/icons';
import { AxiosError } from 'axios';
import Title from 'antd/lib/typography/Title';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import styled from 'styled-components';
import Rating from '../../Rating';
import {
  IBSServiceResponse,
  handleServicePatch,
  PatchType, SLAStatus,
} from './api';
import responseStatus from '../../../providers/responseStatus';
import { APINotificationSwitch } from '../../NotificationBS';
import { brasiliaAgentPermission, permissionChecker } from '../../../providers/rolePermissions';
import { checkStatusUnauthorized, getAuthRoles } from '../../../providers/auth';
import { ratingColors, slaColors } from '../../../styles/GlobalStyles';
import TimerDisplay from './TimerDisplay';

export interface PatientModalHandles {
  openModal: () => void;
  dataModal: (serviceRow: IBSServiceResponse, serverTimeFromParent: number | undefined) => void;
}

interface IDepartmentButton {
  status: string,
}

const TotalTimeContainer = styled.div`
   span {
    font-size: 20px;
    padding: 5px 5px;
  }
`;

const DescriptionItensContainer = styled.div`
.ant-descriptions-item-content{
    font-size: 15px;
    font-weight: bold;
  };
`;

const PatientModal: React.ForwardRefRenderFunction<PatientModalHandles> = (_props, ref) => {
  const { TextArea } = Input;
  const [visible, setVisible] = useState<boolean>(false);
  const [popVisible, setPopVisible] = useState<boolean>(false);
  const [popVisibleCancelReason, setpopVisibleCancelReason] = useState<boolean>(false);
  const [popVisibleParalyzeReason, setpopVisibleParalyzeReason] = useState<boolean>();
  const [serviceData, setServiceData] = useState<IBSServiceResponse | null>();
  const rejectReasonRef = useRef<TextAreaRef>(null);
  const paralyzeReasonRef = useRef<TextAreaRef>(null);
  const cancelReasonRef = useRef<TextAreaRef>(null);
  const [serverTime, setserverTime] = useState();

  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  const dataModal = useCallback((serviceRow, serverTimeFromParent) => {
    setServiceData(serviceRow);
    setserverTime(serverTimeFromParent);
  }, []);

  useImperativeHandle(ref, () => ({
    openModal,
    dataModal,
  }));

  const handleCloseModal = useCallback(() => {
    setVisible(false);
    setPopVisible(false);
    setpopVisibleParalyzeReason(false);
  }, []);

  const handleCloseAfterRating = useCallback(() => {
    setVisible(false);
    setPopVisible(false);
  }, []);

  const closePopVisible = () => {
    setPopVisible(false);
  };

  const handleVisibleChange = () => {
    setPopVisible(true);
  };

  const handleVisibleChangeCancelReason = () => {
    setpopVisibleCancelReason(true);
  };

  const closePopVisibleCancelReason = () => {
    setpopVisibleCancelReason(false);
  };

  const handleVisibleParalyzeReason = () => {
    setpopVisibleParalyzeReason(true);
  };

  const closePopVisibleParalyzeReason = () => {
    setpopVisibleParalyzeReason(false);
  };

  const adjustTime = (time: Date): Date => {
    const timeISO = new Date(time).getTime() + (serverTime || 0);
    return new Date(timeISO);
  };

  // serviceData.bed === null ? serviceData.attendance.ds_leito : serviceData.bed

  const RenderTimeTag = () => {
    let endTime;
    let slaAtual = 0;
    let statusAtual: string | undefined;
    if (serviceData) {
      if (serviceData?.slaStatus === SLAStatus.Arquivado) {
        endTime = new Date(serviceData?.archiveTimestamp as Date);
        endTime.setTime(endTime.getTime());
      } else if (serviceData?.slaStatus === SLAStatus.Suspenso) {
        endTime = new Date(serviceData?.slaStartSuspensionTimestamp as Date);
        endTime.setTime(endTime.getTime());
      } else {
        endTime = new Date();
        endTime.setTime(endTime.getTime() + endTime.getTimezoneOffset() - 60 - 1000);
      }
    }

    if (serviceData?.slaAcceptTimeExceded === 'exceded') {
      statusAtual = serviceData?.slaAcceptTimeExceded;
    } else if (serviceData?.slaStartTimeExceded === 'exceded') {
      statusAtual = serviceData?.slaStartTimeExceded;
    } else if (serviceData?.slaEndTimeExceded === 'exceded') {
      statusAtual = serviceData?.slaEndTimeExceded;
    } else if (serviceData?.slaArchiveTimeExceded === 'exceded') {
      statusAtual = serviceData?.slaArchiveTimeExceded;
    } else {
      switch (serviceData?.slaStatus) {
        case SLAStatus.AguardandoAceite:
          statusAtual = serviceData?.slaAcceptTimeExceded;
          break;
        case SLAStatus.AguardandoInicio:
          statusAtual = serviceData?.slaStartTimeExceded;
          break;
        case SLAStatus.EmAndamento:
          statusAtual = serviceData?.slaEndTimeExceded;
          break;
        case SLAStatus.Finalizado:
          statusAtual = serviceData?.slaArchiveTimeExceded;
          break;
        default:
          break;
      }
    }

    switch (serviceData?.slaStatus) {
      case SLAStatus.EmAprovacao:
        slaAtual = serviceData?.sla.toAccept;
        break;
      case SLAStatus.AguardandoAceite:
        slaAtual = serviceData?.sla.toAccept;
        break;
      case SLAStatus.AguardandoInicio:
        slaAtual = serviceData?.sla.toStart;
        break;
      case SLAStatus.EmAndamento:
        slaAtual = serviceData?.sla.toEnd;
        break;
      case SLAStatus.Finalizado:
        slaAtual = serviceData?.sla.toArchive;
        break;
      default:
        break;
    }

    return (
      serviceData
      && (
        <TimerDisplay
          initialRowTime={serviceData.requestTimestamp as Date}
          endRowTime={serviceData?.slaStatus === SLAStatus.Cancelado
            ? serviceData.requestTimestamp as Date : endTime as Date}
          suspendedRowTime={serviceData.slaTotalSuspensionTime || 0}
          rowMetaSLA={slaAtual}
          rowWarningSLA={0}
          isTotal
          slaStatus={serviceData.slaStatus}
          status={statusAtual as string}
        />
      )
    );
  };

  const formattedDateTime = (date: Date | undefined) => {
    let formattedDateAndTime;
    let hour;
    let minute;

    if (date) {
      const newDate = new Date(date);
      if (newDate.getHours().valueOf() < 10) {
        hour = `0${newDate.getHours()}`;
      } else {
        hour = newDate.getHours();
      }
      if (newDate.getMinutes().valueOf() < 10) {
        minute = `0${newDate.getMinutes()}`;
      } else {
        minute = newDate.getMinutes();
      }
      formattedDateAndTime = `${newDate.getDate()}/${newDate.getMonth() + 1}/${newDate.getFullYear()} ${hour}:${minute}`;
    }
    return formattedDateAndTime;
  };

  const StaticStatusTime = (row: IBSServiceResponse, sla: string) => {
    let initialTime;
    let endTime;
    let suspendedTime;
    let metaSLA;
    let warningSLA;
    let status;
    let isNextStage;

    if (sla === 'Tempo de Aceite' || sla === 'Aguardando Aceite') {
      initialTime = row.approveTimestamp;
      endTime = row.acceptTimestamp ? row.acceptTimestamp : row.approveTimestamp;
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toAccept;
      warningSLA = row.sla.toAcceptWarning;
      status = row.slaAcceptTimeExceded;
    } else if (sla === 'Tempo de Início' || sla === 'Aguardando Início') {
      isNextStage = !(row.acceptTimestamp && row.startTimestamp);
      initialTime = row.acceptTimestamp ? row.acceptTimestamp : new Date();
      if (row.slaStatus === 'Cancelado') {
        endTime = row.startTimestamp ? row.startTimestamp : initialTime;
      } else {
        endTime = row.startTimestamp ? row.startTimestamp : new Date();
      }
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toAccept;
      warningSLA = row.sla.toAcceptWarning;
      status = row.slaStartTimeExceded;
    } else if (sla === 'Tempo de Fim' || sla === 'Em Andamento') {
      isNextStage = !(row.startTimestamp && row.endTimestamp);
      initialTime = row.startTimestamp ? row.startTimestamp : new Date();
      if (row.slaStatus === 'Cancelado') {
        endTime = row.endTimestamp ? row.endTimestamp : initialTime;
      } else {
        endTime = row.endTimestamp ? row.endTimestamp : new Date();
      }
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toAccept;
      warningSLA = row.sla.toAcceptWarning;
      status = row.slaEndTimeExceded;
    } else if (sla === 'Tempo de Avaliação' || sla === 'Finalizado') {
      isNextStage = !(row.endTimestamp && row.archiveTimestamp);
      initialTime = row.endTimestamp ? row.endTimestamp : new Date();
      if (row.slaStatus === 'Cancelado') {
        endTime = row.endTimestamp ? row.endTimestamp : initialTime;
      } else {
        endTime = row.archiveTimestamp ? row.archiveTimestamp : new Date();
      }
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toArchive;
      warningSLA = row.sla.toArchiveWarning;
      status = row.slaArchiveTimeExceded;
    }

    return (
      <TimerDisplay
        initialRowTime={initialTime as Date}
        endRowTime={endTime as Date}
        suspendedRowTime={suspendedTime as number}
        rowMetaSLA={metaSLA as number}
        rowWarningSLA={warningSLA as number}
        isTotal={false}
        border={false}
        slaStatus={row.slaStatus}
        staticTime
        status={status as string}
        isNextStage={isNextStage}
      />
    );
  };

  const RenderHour = (hour: Date | undefined | null, color?: string) => {
    if (hour !== null && hour !== undefined) {
      const time = moment(hour).format('LT');
      if (color) {
        return (
          <div
            key={time}
            style={{
              fontSize: '14px',
              color,
            }}
          >
            {time}
          </div>
        );
      }
      return (
        <div
          key={time}
          style={{
            fontSize: '14px',
            color: '#464646',
          }}
        >
          {time}
        </div>
      );
    }
    return (
      <div
        style={{
          fontSize: '14px',
          color,
        }}
      >
        00:00
      </div>
    );
  };

  const RenderCurrentStage = (
    initialTime: Date,
    endTime: Date,
    suspendedTime: number,
    status: string | undefined,
    text: string,
  ) => {
    let color = '#000000';
    let backgroundColor = '#FFF';

    const displayTime = (
      <TimerDisplay
        initialRowTime={initialTime as Date}
        endRowTime={endTime as Date}
        suspendedRowTime={suspendedTime as number}
        rowMetaSLA={0 as number}
        rowWarningSLA={0 as number}
        isTotal={false}
        slaStatus={status}
        status={status as string}
        border
      />
    );

    if (status === 'normal') {
      color = slaColors.normal;
      backgroundColor = 'rgb(79, 190, 255, 0.05)';
    } else if (status === 'warning') {
      color = slaColors.warning;
      backgroundColor = 'rgba(255, 213, 66, 0.05)';
    } else {
      color = slaColors.exceded;
      backgroundColor = 'rgba(248, 98, 106, 0.05)';
    }

    const initialTimestamp = RenderHour(initialTime, color);

    return (
      <Tag
        style={{
          borderRadius: '7px',
          borderColor: color,
          borderWidth: '2px',
          height: '65px',
          fontSize: '14px',
          backgroundColor,
          width: '100%',
        }}
      >
        <Row>
          <div
            style={{
              color,
              marginTop: '7px',
            }}
          >
            {text}
          </div>
        </Row>
        <Row>
          {[initialTimestamp, displayTime]}
        </Row>
      </Tag>
    );
  };

  const RenderAnotherStage = (
    service: IBSServiceResponse,
    initialTime: Date | undefined | null,
    text: string,
  ) => {
    let color = '#464646';
    let status: string | undefined = '';
    let statusAtual = false;

    if (text === 'Aguardando Aceite') {
      status = service.slaAcceptTimeExceded;
      statusAtual = !!service.approveTimestamp;
    } else if (text === 'Aguardando Início') {
      status = service.slaStartTimeExceded;
      statusAtual = !!service.acceptTimestamp;
    } else if (text === 'Em Andamento') {
      status = service.slaEndTimeExceded;
      statusAtual = !!service.startTimestamp;
    } else if (text === 'Finalizado') {
      status = service.slaArchiveTimeExceded;
      statusAtual = !!service.endTimestamp;
    }

    if (statusAtual) {
      if (status === 'normal') {
        color = slaColors.normal;
      } else if (status === 'warning') {
        color = slaColors.warning;
      } else {
        color = slaColors.exceded;
      }
    }

    const displayTime = StaticStatusTime(service, text);

    const initialTimestamp = RenderHour(initialTime, color);

    return (
      <Tag
        style={{
          borderRadius: '7px',
          borderColor: '#FFF',
          borderWidth: '2px',
          height: '65px',
          fontSize: '14px',
          color,
          backgroundColor: '#FFF',
        }}
      >
        <Row>
          <div
            style={{
              marginTop: '7px',
              fontSize: '14px',
              fontWeight: 'normal',
              color,
            }}
          >
            {text}
          </div>
        </Row>
        <Row>
          {[initialTimestamp, displayTime]}
        </Row>
      </Tag>
    );
  };

  const RenderStatusTime = (row: IBSServiceResponse, text?: string) => {
    let initialTime;
    let endTime;
    let suspendedTime;
    let metaSLA;
    let warningSLA;
    let status;

    if (row.slaStatus === SLAStatus.EmAprovacao) {
      initialTime = row.requestTimestamp as Date;
      endTime = adjustTime(new Date());
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toAccept;
      warningSLA = row.sla.toAcceptWarning;
      status = 'null';
    } else if (
      row.slaStatus === SLAStatus.AguardandoAceite && row.approveTimestamp
    ) {
      initialTime = row.approveTimestamp;
      endTime = adjustTime(new Date());
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toAccept;
      warningSLA = row.sla.toStartWarning;
      status = row.slaAcceptTimeExceded;
    } else if (
      row.slaStatus === SLAStatus.AguardandoInicio && row.acceptTimestamp
    ) {
      initialTime = row.acceptTimestamp;
      endTime = adjustTime(new Date());
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toStart;
      warningSLA = row.sla.toStartWarning;
      status = row.slaStartTimeExceded;
    } else if (
      row.slaStatus === SLAStatus.EmAndamento && row.startTimestamp
    ) {
      initialTime = row.startTimestamp;
      endTime = adjustTime(new Date());
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toEnd;
      warningSLA = row.sla.toEndWarning;
      status = row.slaEndTimeExceded;
    } else if (row.slaStatus === SLAStatus.Finalizado && row.startTimestamp
    ) {
      initialTime = row.endTimestamp;
      endTime = adjustTime(new Date());
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toArchive;
      warningSLA = row.sla.toArchiveWarning;
      status = row.slaArchiveTimeExceded;
    } else if (
      row.slaStatus === SLAStatus.Suspenso && row.acceptTimestamp
      && row.slaStartSuspensionTimestamp
    ) {
      initialTime = row.acceptTimestamp;
      endTime = row.slaStartSuspensionTimestamp;
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toEnd;
      warningSLA = row.sla.toEndWarning;
      status = 'null';
    } else if (row.startTimestamp) {
      initialTime = row.startTimestamp;
      endTime = adjustTime(new Date());
      suspendedTime = row.slaTotalSuspensionTime || 0;
      metaSLA = row.sla.toEnd;
      warningSLA = row.sla.toEndWarning;
      status = row.slaEndTimeExceded;
    }
    if (!text) {
      return (
        <TimerDisplay
          initialRowTime={initialTime as Date}
          endRowTime={endTime as Date}
          suspendedRowTime={suspendedTime as number}
          rowMetaSLA={metaSLA as number}
          rowWarningSLA={warningSLA as number}
          isTotal={false}
          slaStatus={row.slaStatus}
          status={status as string}
        />
      );
    }
    return (
      RenderCurrentStage(
        initialTime as Date,
        endTime as Date,
        suspendedTime as number,
        status,
        text,
      )
    );
  };

  /**
   * TODO: Refactor rating component for better use
   */
  const RenderSmileRating = (rating: number) => {
    if (rating <= 4) {
      return (
        <>
          <FrownTwoTone twoToneColor={ratingColors.detractor} />
          {' '}
          <span>Insatisfeito</span>
        </>
      );
    }
    if (rating > 4 && rating <= 8) {
      return (
        <>
          <MehTwoTone twoToneColor={ratingColors.passive} />
          {' '}
          <span>Indiferente</span>
        </>
      );
    }
    return (
      <>
        <SmileTwoTone twoToneColor={ratingColors.promotors} />
        {' '}
        <span>Satisfeito</span>
      </>
    );
  };

  const approveService = async () => {
    try {
      await handleServicePatch(serviceData?.id as string, PatchType.Approve);
      setVisible(false);
      window.location.reload();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      const responseText = responseStatus(error.response?.status);
      checkStatusUnauthorized(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const rateAndArchive = async (ratingValue: number, feedbackValue: string | undefined) => {
    try {
      const result = await handleServicePatch(
        serviceData?.id as string,
        PatchType.Rate, { rating: ratingValue, feedback: feedbackValue || '' },
      );
      const responseText = responseStatus(result.status);
      if (responseText === 'SUCCESS') {
        handleCloseAfterRating();
      }
      window.location.reload();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      const responseText = responseStatus(error.response?.status);
      checkStatusUnauthorized(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const paralyzeServiceSLA = async () => {
    const paralyzeReasonInput = paralyzeReasonRef.current?.resizableTextArea?.props.value;
    try {
      await handleServicePatch(
        serviceData?.id as string,
        PatchType.Paralyze,
        { reason: paralyzeReasonInput as string },
      );
      setVisible(false);
      setpopVisibleParalyzeReason(false);
      window.location.reload();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const rejectService = async () => {
    const rejectReasonInput = rejectReasonRef.current?.resizableTextArea?.props.value;
    try {
      await handleServicePatch(
        serviceData?.id as string,
        PatchType.Reject,
        { reason: rejectReasonInput as string },
      );
      setVisible(false);
      window.location.reload();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const cancelService = async () => {
    const cancelReasonInput = cancelReasonRef.current?.resizableTextArea?.props.value;
    try {
      await handleServicePatch(
        serviceData?.id as string,
        PatchType.Cancel,
        { reason: cancelReasonInput as string },
      );
      setpopVisibleCancelReason(false);
      setVisible(false);
      window.location.reload();
    } catch (BsErr) {
      const error = BsErr as AxiosError;
      checkStatusUnauthorized(error.response?.status);
      const responseText = responseStatus(error.response?.status);
      APINotificationSwitch(responseText, error.response?.data);
    }
  };

  const DepartmentButton: React.FC<IDepartmentButton> = () => (
    permissionChecker(getAuthRoles(), brasiliaAgentPermission)
      ? (
        <div
          style={{
            paddingTop: '25px',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          {
            serviceData?.slaStatus === SLAStatus.Suspenso
              ? (
                {}
              )
              : (
                <Popover
                  content={(
                    <>
                      <h4>Motivo da suspensão</h4>
                      <TextArea ref={paralyzeReasonRef} />
                      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                        <Button
                          size="small"
                          style={{ borderRadius: '5px' }}
                          onClick={closePopVisibleParalyzeReason}
                        >
                          Cancelar
                        </Button>
                        <Button
                          size="small"
                          style={{ borderRadius: '5px', color: '#FFF' }}
                          type="primary"
                          onClick={paralyzeServiceSLA}
                        >
                          Confirmar
                        </Button>
                      </div>
                    </>
                  )}
                  trigger="click"
                  visible={popVisibleParalyzeReason}
                  onVisibleChange={handleVisibleParalyzeReason}
                />
              )
          }
        </div>
      ) : <></>
  );

  if (!visible) {
    return null;
  }

  if (!serviceData) {
    return <></>;
  }

  let ages = parseInt(moment(serviceData.requestTimestamp).format('YYYY')) - parseInt(serviceData.attendance.dt_nascimento.split('/')[2]);
  const months = parseInt(serviceData.attendance.dt_nascimento.split('/')[1]) - parseInt(moment(serviceData.requestTimestamp).format('MM'));
  const days = parseInt(serviceData.attendance.dt_nascimento.split('/')[0]) - parseInt(moment(serviceData.requestTimestamp).format('DD'));
  if (months > 0 || (months === 0 && days > 0)) ages -= 1;

  return (
    <div>
      <Modal
        visible={visible}
        footer={null}
        onCancel={handleCloseModal}
        width="70vw"
        centered
      >
        <Row justify="space-between" style={{ paddingBottom: '20px' }}>
          <Col span={12}>
            <Title level={3}>
              {
                `Atendimento: ${serviceData.attendance.atendimento}`
              }
            </Title>
            <Title level={3}>
              {
                `Quarto ${serviceData.bed === undefined ? serviceData.attendance.ds_leito : serviceData.bed} 
                - ${formattedDateTime(serviceData.requestTimestamp)}`
              }
            </Title>
          </Col>
          <TotalTimeContainer>
            <Col>
              <Title level={3}>
                Tempo Total:
                {' '}
                {
                  RenderTimeTag()
                }
              </Title>
            </Col>
          </TotalTimeContainer>
        </Row>

        <Card style={{ borderColor: 'rgb(200, 200, 200)' }}>
          <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            <DescriptionItensContainer>
              <Descriptions column={4} title="Informações do Paciente" layout="vertical" style={{ textTransform: 'capitalize' }}>
                <Descriptions.Item label="Paciente" style={{ width: '35%', color: '#464646' }}>
                  {serviceData.attendance?.nm_paciente.toLowerCase()}
                </Descriptions.Item>
                <Descriptions.Item label="Data de Nascimento" style={{ width: '35%', paddingLeft: '2%', color: '#464646' }}>
                  {`${serviceData.attendance.dt_nascimento} - ${ages} Anos`}
                </Descriptions.Item>
                <Descriptions.Item label="Acompanhante" style={{ width: '35%', paddingLeft: '2%', color: '#464646' }}>
                  {serviceData.attendance.acompanhante ? serviceData.attendance.acompanhante.toLowerCase() : 'Não informado'}
                </Descriptions.Item>
              </Descriptions>
            </DescriptionItensContainer>
          </Row>
          <Divider style={{ borderColor: 'rgb(200, 200, 200)' }} />
          <Row>
            <DescriptionItensContainer>
              <Descriptions column={4} title="Solicitação" layout="vertical" style={{ textTransform: 'capitalize' }}>
                <Descriptions.Item label="Solicitação Aberta por" style={{ width: '30%', color: '#464646' }}>
                  {serviceData.requestingAgent.name.toLowerCase()}
                </Descriptions.Item>
                <Descriptions.Item label="Departamento" style={{ width: '30%', color: '#464646' }}>
                  {serviceData.department.name.toLowerCase()}
                </Descriptions.Item>
                <Descriptions.Item label="Categoria" style={{ width: '20%', color: '#464646' }}>
                  {serviceData.category.name.toLowerCase()}
                </Descriptions.Item>
                <Descriptions.Item label="Atividade" style={{ width: '186px' }}>
                  {serviceData.activity.name}
                </Descriptions.Item>
              </Descriptions>
            </DescriptionItensContainer>
          </Row>
          <Row>
            <DescriptionItensContainer>
              <Descriptions column={4} style={{ textTransform: 'capitalize' }}>
                <Descriptions.Item style={{ width: '30%' }}>
                  <Row>
                    <div
                      style={{
                        fontWeight: 'normal',
                        fontSize: '14px',
                        width: '100%',
                        color: '#464646',
                      }}
                    >
                      Descrição da Tarefa:
                    </div>
                    <Row>
                      <Descriptions.Item>
                        {serviceData.description ? serviceData.description.toLowerCase() : '-'}
                      </Descriptions.Item>
                    </Row>
                  </Row>
                </Descriptions.Item>
                <Descriptions.Item style={{ width: '30%' }}>
                  <Row>
                    <Descriptions.Item>
                      <div
                        style={{
                          fontWeight: 'normal',
                          fontSize: '14px',
                          width: '100%',
                          color: '#464646',
                        }}
                      >
                        Agente Responsável:
                      </div>
                      <Descriptions.Item>
                        {serviceData.alocatedAgent.name ? serviceData.alocatedAgent.name.toLowerCase() : ''}
                      </Descriptions.Item>
                    </Descriptions.Item>
                  </Row>
                </Descriptions.Item>
                <Descriptions>
                  <Row>
                    <Descriptions.Item>
                      <div
                        style={{
                          fontWeight: 'normal',
                          fontSize: '14px',
                          width: '100%',
                          color: '#464646',
                        }}
                      >
                        Observação do Agente:
                      </div>
                    </Descriptions.Item>
                    <Row>
                      <Descriptions.Item>
                        {typeof serviceData.agentFeedback === 'string' ? serviceData.agentFeedback.toLowerCase() : '-'}
                      </Descriptions.Item>
                    </Row>
                  </Row>
                </Descriptions>
              </Descriptions>
            </DescriptionItensContainer>
          </Row>
          <Row>
            <DescriptionItensContainer>
              <Descriptions column={4}>
                {serviceData.slaStatus === 'Aguardando Aceite' ? (
                  <Descriptions.Item style={{ width: '30%' }}>
                    {RenderStatusTime(serviceData, 'Aguardando Aceite')}
                  </Descriptions.Item>
                )
                  : (
                    <Descriptions.Item style={{ width: '30%' }}>
                      {RenderAnotherStage(serviceData, serviceData.requestTimestamp, 'Aguardando Aceite')}
                    </Descriptions.Item>
                  )}
                {serviceData.slaStatus === 'Aguardando Inicio' ? (
                  <Descriptions.Item style={{ width: '30%' }}>
                    {RenderStatusTime(serviceData, 'Aguardando Início')}
                  </Descriptions.Item>
                )
                  : (
                    <Descriptions.Item style={{ width: '30%' }}>
                      {RenderAnotherStage(serviceData, serviceData.acceptTimestamp, 'Aguardando Início')}
                    </Descriptions.Item>
                  )}
                {serviceData.slaStatus === 'Em Andamento' ? (
                  <Descriptions.Item style={{ width: '20%' }}>
                    {RenderStatusTime(serviceData, 'Em Andamento')}
                  </Descriptions.Item>
                )
                  : (
                    <Descriptions.Item style={{ width: '20%' }}>
                      {[RenderAnotherStage(serviceData, serviceData.startTimestamp, 'Em Andamento')]}
                    </Descriptions.Item>
                  )}
                {serviceData.slaStatus === 'Finalizado' ? (
                  <Descriptions.Item style={{ width: '20%' }}>
                    {RenderStatusTime(serviceData, 'Finalizado')}
                  </Descriptions.Item>
                )
                  : (
                    <Descriptions.Item style={{ width: '20%' }}>
                      {[RenderAnotherStage(serviceData, serviceData.endTimestamp, 'Finalizado')]}
                    </Descriptions.Item>
                  )}
              </Descriptions>
            </DescriptionItensContainer>
          </Row>
          {serviceData.slaStatus === 'Finalizado' ? <Divider style={{ borderColor: 'rgb(200, 200, 200)' }} /> : ''}
          <Row>
            <DescriptionItensContainer>
              <Descriptions>
                {serviceData.slaStatus === 'Arquivado' && serviceData.rating ? (
                  <Descriptions.Item label="Nota">
                    <span>{RenderSmileRating(serviceData.rating)}</span>
                  </Descriptions.Item>
                ) : <></>}
                {serviceData.slaStatus === 'Arquivado' ? (
                  <Descriptions.Item label="Feedback">
                    <span>{serviceData.feedback}</span>
                  </Descriptions.Item>
                )
                  : <></>}
              </Descriptions>
            </DescriptionItensContainer>
          </Row>

          {
            (serviceData.slaStatus === SLAStatus.Finalizado)
            && (
              <Rating onRatingComplete={
                (ratingValue, feedbackValue) => rateAndArchive(ratingValue, feedbackValue)
              }
              />
            )
          }

        </Card>

        {(serviceData.slaStatus === (SLAStatus.EmAprovacao))
          && (
            <div
              style={{
                paddingTop: '25px',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Button
                onClick={approveService}
                style={{ backgroundColor: '#32cd3287', borderRadius: '20px' }}
              >
                <CheckOutlined />
                {' '}
                Aprovar
              </Button>
              <Popover
                content={(
                  <>
                    <h4>Motivo da recusa</h4>
                    <TextArea ref={rejectReasonRef} />
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                      <Button
                        size="small"
                        style={{ borderRadius: '5px' }}
                        onClick={closePopVisible}
                      >
                        Cancelar
                      </Button>
                      <Button
                        size="small"
                        style={{ backgroundColor: '#e7f520', borderRadius: '5px', color: '#FFF' }}
                        onClick={rejectService}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </>
                )}
                trigger="click"
                visible={popVisible}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  style={{ backgroundColor: '#ff00007d', borderRadius: '20px' }}
                  onClick={() => rejectReasonRef.current?.resizableTextArea?.props.value === ''}
                >
                  <CloseOutlined />
                  {' '}
                  Recusar
                </Button>
              </Popover>
            </div>
          )}
        {
          (serviceData.slaStatus === SLAStatus.AguardandoInicio)
          && <DepartmentButton status={SLAStatus.AguardandoInicio} />
        }
        {
          (serviceData.slaStatus === SLAStatus.EmAndamento)
          && <DepartmentButton status={SLAStatus.EmAndamento} />
        }
        {
          (serviceData.slaStatus === SLAStatus.Suspenso)
          && <DepartmentButton status={SLAStatus.Suspenso} />
        }

        {permissionChecker(getAuthRoles(), brasiliaAgentPermission)
          ? (
            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '25px' }}>
              <Popover
                content={(
                  <>
                    <h4>Motivo do cancelamento</h4>
                    <TextArea ref={cancelReasonRef} />
                    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
                      <Button
                        size="small"
                        style={{ borderRadius: '5px' }}
                        onClick={closePopVisibleCancelReason}
                      >
                        Cancelar
                      </Button>
                      <Button
                        size="small"
                        style={{ borderRadius: '5px', color: '#FFF' }}
                        type="primary"
                        onClick={cancelService}
                      >
                        Confirmar
                      </Button>
                    </div>
                  </>
                )}
                trigger="click"
                visible={popVisibleCancelReason}
                onVisibleChange={handleVisibleChangeCancelReason}
              >
                <Button
                  style={{ borderRadius: '20px' }}
                  type="primary"
                  onClick={() => cancelReasonRef.current?.resizableTextArea?.props.value === ''}
                >
                  <CloseOutlined />
                  {' '}
                  Cancelar
                </Button>
              </Popover>
            </div>
          ) : <></>}
      </Modal>
    </div>
  );
};

export default forwardRef(PatientModal);
